import { type FC, MouseEvent, ReactElement, useEffect, useMemo, useState } from "react";
import { HiOutlineFolderMinus, HiOutlineFolderPlus } from "react-icons/hi2";
import { MdOutlineRefresh } from "react-icons/md";
import $ from "jquery";
import { useRagContext } from "../../contexts/RagContext";
import BpCheckbox from "../common/BPCheckbox";
import { useLayoutContext } from "../../contexts/LayoutContext";
import { getExtension } from "../../utils";

interface TreeNode {
  name: string;               // folder/file name
  isFolder: boolean;
  children: TreeNode[];
  originalFilePath?: string;
  requiresOCR?: boolean;
  isOCRProcessing?: boolean;
  path?: string;      
}

interface FolderComponentProps {
  folder: {
    structure: any; // e.g. {"Team Documents": {folder1: {__file:...}, ...}}
  };
//   handleStartOCR: (folderId: string, securedPath: string, path: string, name: string) => Promise<void>;
  handleStartOCR: (
    target_path: string,
    file_name: string
  ) => void;
}

const Folder: FC<FolderComponentProps> = ({ folder, handleStartOCR }): ReactElement => {
  const { deviceType, sidebarWidth, setChatPosition } = useLayoutContext();
  const [tree, setTree] = useState<TreeNode[]>([]);

  const {
    openedDocument,
    pageNumber,
    isSelectedSource,
    selectedFiles,
    setSelectedFiles,
    setOpenedDocument,
    setPageNumber,
  } = useRagContext();

  // Calculate extra space for styles
  const extraWidth = useMemo(() => {
    return sidebarWidth - 300;
  }, [sidebarWidth]);

  // --------------------------------------------------
  // 2) Convert the NEW structure from the backend
  //    into our local `TreeNode[]`. Here, 
  //    - val.__file == True => file node
  //    - object => folder node
  // --------------------------------------------------
  useEffect(() => {
    if (folder?.structure) {
      setTree(buildTree(folder.structure, ""));
    }
  }, [folder]);

  /**
   * Recursively build a TreeNode[].
   * If val.__file => file node
   * else => folder => keep going
   */
  const buildTree = (data: any, currentPath: string): TreeNode[]=>{
    if (!data || typeof data !== "object") return [];

    return Object.keys(data).map((key) => {
      const val = data[key];
      // Check if it's a file => we used {__file: true, originalFilePath: "..."}
      const isFile = val && val.__file === true;

      // Build the path, e.g. "/subfolder/filename"
      const newPath = currentPath ? `${currentPath}/${key}` : key;

      if (isFile) {
        // File node
        return {
          name: key,
          isFolder: false,
          children: [],
          originalFilePath: val.originalFilePath,  // from sourceList
          requiresOCR: val.requiresOCR || false,
          isOCRProcessing: false,
          path: newPath, // optional: for display
        };
      } else {
        // Folder => recurse
        return {
          name: key,
          isFolder: true,
          children: buildTree(val, newPath),
          path: newPath, // optional: for display
        };
      }
    });
  };
  const startOCR = async (e: any, node: TreeNode, folderPath: string) => {
    e.preventDefault();
    node.isOCRProcessing = true;
    setTree([...tree]);
    await handleStartOCR(folderPath, node.name);

    node.isOCRProcessing = false;
    setTree([...tree]);
  };
  // Track expanded folders
  const [expandedPaths, setExpandedPaths] = useState<Set<string>>(new Set());

  const toggleNode = (nodePath: string) => {
    const newSet = new Set(expandedPaths);
    const slashCount = (nodePath.match(/\//g) || []).length || 1;
    if (newSet.has(nodePath)) newSet.delete(nodePath);
    else newSet.add(nodePath);
    setExpandedPaths(newSet);
    let folderContainer: any = $("#folder-container");
    if (folderContainer) {
      folderContainer.animate({ scrollLeft: 50 * slashCount }, 500);
    }
  };
  function handleToggleFolder(nodePath: string) {
    // Find folder node in local `tree`
    const targetNode = findNodeByPath(tree, nodePath);
    if (!targetNode) return;

    // Gather all file paths
    const allPaths = gatherAllFilePaths(targetNode);
    console.log("All paths =>", allPaths);
    // Are they all currently selected?
    const allSelected = allPaths.every((p) => selectedFiles.includes(p));

    // Compute new selectedFiles array
    let updated = [...selectedFiles];
    if (allSelected) {
      // Unselect them
      updated = updated.filter((p) => !allPaths.includes(p));
    } else {
      // Select them
      allPaths.forEach((file) => {
        if (!updated.includes(file)) updated.push(file);
      });
    }

    setSelectedFiles(updated);
  }
  const gatherAllFilePaths = (node: TreeNode): string[]=> {
    if (!node.isFolder) {
      // single file => return [originalFilePath]
      return node.originalFilePath ? [node.originalFilePath] : [];
    }
    let all: string[] = [];
    node.children.forEach((child) => {
      all = all.concat(gatherAllFilePaths(child));
    });
    return all;
  }
  const findNodeByPath = (nodes: TreeNode[], targetPath: string): TreeNode | null=> {
    for (const node of nodes) {
      if (node.path === targetPath) {
        return node;
      }
      if (node.isFolder) {
        const found = findNodeByPath(node.children, targetPath);
        if (found) return found;
      }
    }
    return null;
  }
  // --------------------------------------------------
  // 4) Opening a file (docx, pdf, etc.)
  // --------------------------------------------------
    const handleDocumentClick = (nodePath: string) => {
    // Example of splitting path to find extension
      const extension = getExtension(nodePath);
      // Move chat panel
      if (window.innerWidth > 1350) {
        setChatPosition(424);
      } else {
        setChatPosition(300);
      }
      setOpenedDocument(nodePath);
      setPageNumber(pageNumber);
  
    // Example: if extension === 'pdf', focus PDF viewer
      if (extension === "pdf" || extension === "pptx") {
        let pdfviewerObject: any = $("#container");
        let pdfViewer = pdfviewerObject[0]?.ej2_instances?.[0];
        if (pdfViewer) {
          pdfViewer.navigation.goToPage(pageNumber);
        }
      } else if (extension === "docx") {
        let docxObject: any = $("#docx_container");
        let docxViewer = docxObject[0]?.ej2_instances?.[0]?.documentEditor;
        if (docxViewer) {
          docxViewer.scrollToPage(pageNumber);
        }
      } else if (extension === "xlsx") {
        let xlsxObject: any = $("#xlsx_container");
        let xlsxViewer = xlsxObject[0]?.ej2_instances?.[0];
        if (xlsxViewer) {
          xlsxViewer.activeSheetIndex = pageNumber > 0 ? pageNumber - 1 : 0;
        }
      }
    };
  /**
   * Toggle a single file => push/remove originalFilePath from selectedFiles
   */
  const handleToggleFile = (filePath: string) => {
    let updated = [...selectedFiles];
    const idx = updated.indexOf(filePath);
    if (idx >= 0) {
      updated.splice(idx, 1);
    } else {
      updated.push(filePath);
    }
    setSelectedFiles(updated);
  };


  // --------------------------------------------------
  // 5) Render the tree
  // --------------------------------------------------
  const renderTree = (nodes: TreeNode[]) => {
    return nodes.map((node) => {
      if (node.isFolder) {
        const isExpanded = expandedPaths.has(node.path || "");
        // determine if folder is selected => check if all children are in selectedFiles
        const allFiles = gatherAllFilePaths(node);
        const allSelected = allFiles.length > 0 && allFiles.every((f) => selectedFiles.includes(f));

        return (
          <div key={node.path}>
            <div
              style={{
                minWidth:
                242 * (deviceType === "laptop" ? 0.75 : 1) + extraWidth / 4,
            }}
              className={`w-full px-[9px] xl:px-3 py-[7px] xl:py-[10px] flex items-start gap-[9px] xl:gap-3 rounded-[10px] group sidebar-folder text-[#09090B]`}
            >
              <button
                onClick={() => node.path && toggleNode(node.path)}
                className="outline-none flex-none"
              >
                {isExpanded ? (
                  <HiOutlineFolderMinus className="text-lg xl:text-2xl" />
                ) : (
                  <HiOutlineFolderPlus className="text-lg xl:text-2xl" />
                )}
              </button>
              <p
                style={{
                  minWidth:
                  130 * (deviceType === "laptop" ? 0.75 : 1) +
                  extraWidth / 4,
                }}
                className="text-xs xl:text-base font-medium folder-name text-break"
                onClick={() => node.path && toggleNode(node.path)}
              >
                {node.name}
              </p>
              {/* Checkbox for folder => toggles entire subtree */}
              <button
                className={`outline-none flex-none ml-auto flex h-[18px] xl:h-6 items-center ${
                  allSelected ? "visible" : "invisible group-hover:visible"
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (node.path) handleToggleFolder(node.path);
                }}
              >
                <BpCheckbox
                  size="small"
                  className="w-[10px] xl:w-[14px] h-[10px] xl:h-[14px]"
                  checked={allSelected}
                />
              </button>
            </div>

            {/* Children */}
            {isExpanded && (
              <div className="relative pl-[27px] xl:pl-9 flex-col sidebar-folder-items flex">
                {renderTree(node.children)}
              </div>
            )}
          </div>
        );
      } else {
        // File node
        const pathForDisplay = node.path || node.name;
        const filePath = node.originalFilePath || "";
        const isSelected = selectedFiles.includes(filePath);
        const isOpenDoc = openedDocument === filePath;

        return (
          <div
            key={pathForDisplay}
            style={{
              minWidth:
                242 * (deviceType === "laptop" ? 0.75 : 1) + extraWidth / 4,
            }}
            className={`w-full sidebar-folder-item px-[9px] xl:px-3 py-[7px] xl:py-[10px] rounded-xl flex items-start gap-[3px] xl:gap-1 justify-between cursor-pointer group ${
              isOpenDoc ? "bg-[#E4E4E7] text-[#18181B]" : "bg-transparent text-[#52525B]"
            }`}
            onClick={() => handleDocumentClick(filePath)}
          >
            <p className={`text-xs xl:text-base document-title ${isOpenDoc ? "font-medium" : "font-normal"}`}>
              {node.name}
            </p>
            <div className="flex gap-[6px] xl:gap-2 ml-auto">
              {node.requiresOCR && (
                <button
                  onClick={(e) => startOCR(e, node, node.path || "")}
                  className="outline-none flex-none"
                  title="Start OCR Processing"
                >
                  {node.isOCRProcessing ? (
                    <svg
                      aria-hidden="true"
                      className="w-3 xl:w-4 h-[18px] xl:h-6 mr-3 xl:mr-4 text-[#3B82F6] animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  ) : (
                    <MdOutlineRefresh className="text-lg xl:text-2xl text-[#3B82F6] flex-none" />
                  )}
                </button>
              )}

              {/* File checkbox => toggles single file */}
              <button
                className={`outline-none flex-none ml-auto flex h-[18px] xl:h-6 items-center ${
                  isSelected ? "visible" : "invisible group-hover:visible"
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleToggleFile(filePath);
                }}
              >
                <BpCheckbox
                  size="small"
                  className="w-[10px] xl:w-[14px] h-[10px] xl:h-[14px]"
                  checked={isSelected}
                />
              </button>
            </div>
          </div>
        );
      }
    });
  };

  return <>{renderTree(tree)}</>;
};

export default Folder;
