/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/anchor-has-content */
import { type FC } from "react";
import Markdown from "react-markdown";

interface CommonMarkdownProps {
  children: string;
}

const CommonMarkdown: FC<CommonMarkdownProps> = ({ children }) => {
  return (
    <Markdown
      components={{
        a: ({ node, ...props }) => (
          <a
            target="_blank"
            rel="noreferrer"
            {...props}
            onClick={(e) => e.stopPropagation()}
          />
        ),
        h1: ({ node, ...props }) => (
          <h1
            className="text-[22px] xl:text-3xl font-bold my-3 xl:my-4 leading-none"
            {...props}
          />
        ),
        h2: ({ node, ...props }) => (
          <h2
            className="text-lg xl:text-2xl font-semibold my-[9px] xl:my-3"
            {...props}
          />
        ),
        h3: ({ node, ...props }) => (
          <h3
            className="text-[15px] xl:text-xl font-medium my-[6px] xl:my-2"
            {...props}
          />
        ),
        p: ({ node, ...props }) => (
          <p className="my-[6px] xl:my-2" {...props} />
        ),
        strong: ({ node, ...props }) => (
          <strong className="font-bold" {...props} />
        ),
        em: ({ node, ...props }) => <em className="italic" {...props} />,
        ul: ({ node, ...props }) => (
          <ul className="list-disc list-inside my-[6px] xl:my-2" {...props} />
        ),
        ol: ({ node, ...props }) => (
          <ol
            className="list-decimal list-inside my-[6px] xl:my-2"
            {...props}
          />
        ),
        li: ({ node, ...props }) => (
          <li className="my-[3px] xl:my-1" {...props} />
        ),
        blockquote: ({ node, ...props }) => (
          <blockquote
            className="border-l-4 border-gray-300 pl-3 xl:pl-4 italic my-3 xl:my-4"
            {...props}
          />
        ),
        code: ({ node, inline, className, children, ...props }) => {
          const language = className ? className.replace("language-", "") : "";
          return inline ? (
            <code className="bg-gray-200 rounded px-[3px] xl:px-1" {...props}>
              {children}
            </code>
          ) : (
            <pre className="bg-gray-100 p-[6px] xl:p-2 rounded my-[6px] xl:my-2 overflow-x-auto">
              <code className={`language-${language}`} {...props}>
                {children}
              </code>
            </pre>
          );
        },
        img: ({ node, alt, src, ...props }) => (
          <img
            className="my-[6px] xl:my-2 max-w-full h-auto"
            alt={alt}
            src={src}
            {...props}
          />
        ),
      }}
    >
      {children}
    </Markdown>
  );
};

export default CommonMarkdown;
