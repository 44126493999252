import { type FC, useCallback, useEffect, useRef, useState } from "react";
import "../../App.css";
import $ from "jquery";
import {
  DocumentEditorContainerComponent,
  Toolbar,
} from "@syncfusion/ej2-react-documenteditor";

import {
  Toolbar as PDFToolbar,
  PdfViewerComponent,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  Annotation,
  TextSearch,
  FormFields,
  FormDesigner,
  Inject,
} from "@syncfusion/ej2-react-pdfviewer";

import { SpreadsheetComponent } from "@syncfusion/ej2-react-spreadsheet";
import {
  showSpinner,
  hideSpinner,
  createSpinner,
} from "@syncfusion/ej2-popups";
import { getExtension } from "../../utils";
import { useUserContext } from "../../contexts/UserContext";
import { useLayoutContext } from "../../contexts/LayoutContext";
import { useRagContext } from "../../contexts/RagContext";

DocumentEditorContainerComponent.Inject(Toolbar);

export const Editor: FC = () => {
  const [editorName, setEditorName] = useState<"pdf" | "docx" | "xlsx" | "unsupported">(
    "docx"
  );
  const [pdfURL, setPDFURL] = useState("");
  const spreadsheetRef = useRef<SpreadsheetComponent>(null);
  const { user } = useUserContext();
  const {
    deviceType,
    isSidebarDragging,
    isChatDragging,
    sidebarWidth,
    chatWidth,
  } = useLayoutContext();
  const { openedDocument, documentUpdated, pageNumber } = useRagContext();

  // Recalculate sizes when sidebars move
  const handleResize = useCallback(() => {
    setTimeout(() => {
      // Resize docx viewer
      const documentObject = $("#docx_container");
      const documentContainer: any =( documentObject[0] as any)?.ej2_instances?.[0]?.documentEditor;
      if (documentContainer) documentContainer.resize();

      // Resize pdf viewer
      const pdfviewerObject = $("#container");
      const pdfViewerContainer = (pdfviewerObject[0] as any)?.ej2_instances?.[0];
      if (pdfViewerContainer?.isRendered) {
        pdfViewerContainer.updateViewerContainer();
      }
    }, 0);
  }, [sidebarWidth, chatWidth]);

  // Utility function to ensure a clean URL concatenation
  function buildDocumentUrl(baseUrl: string, docPath: string) {
    // Remove trailing slashes from baseUrl
    let finalBase = baseUrl.replace(/\/+$/, "");

    // Remove leading slashes from docPath
    let finalDocPath = docPath.replace(/^\/+/, "");

    // Now safely join them
    return `${finalBase}/api/documents/${finalDocPath}`;
  }

  useEffect(() => {
    if (!isSidebarDragging && !isChatDragging) {
      // Slight delay so the final widths are settled
      setTimeout(() => {
        handleResize();
      }, 500);
    }
  }, [isSidebarDragging, isChatDragging, sidebarWidth, chatWidth, handleResize]);

  // Listen for general window resize as well
  useEffect(() => {
    window.removeEventListener("resize", handleResize);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  // Setup spinner for docx container on mount
  useEffect(() => {
    const container = $("#docx_container");
    if (container) {
      createSpinner({
        target: container[0],
      });
    }
  }, []);

  // Listen for changes in openedDocument => load the correct viewer
  useEffect(() => {
    if (!openedDocument) return;

    const ext = getExtension(openedDocument).toLowerCase();
    if (ext === "docx") {
      setEditorName("docx");
      loadDocx();
    } else if (ext === "xlsx") {
      setEditorName("xlsx");
      loadXlsx();
    } else if (ext === "pdf" || ext === "pptx") {
      // If .pptx, backend serves .pdf with same base name. We'll treat it like "pdf"
      setEditorName("pdf");
      loadPdf();
    } else {
      setEditorName("unsupported");
    }
  }, [openedDocument, documentUpdated]);

  // ---------------------- FILE LOADERS ---------------------- //

  // 1) DOCX Loader
  const loadDocx = () => {
    if (!user) return;
    const docxContainer = $("#docx_container");
    if (docxContainer) showSpinner(docxContainer[0]);

    // We'll do a GET request to /api/documents/<openedDocument>
    // then post that blob to syncfusion docx import endpoint
    const xhr = new XMLHttpRequest();
    
    const apiUrl = buildDocumentUrl(
      process.env.REACT_APP_API_URL as string,
      openedDocument
    );
    xhr.open(
      "GET",
      apiUrl,
      true
    );
    xhr.responseType = "blob";
    xhr.onload = function () {
      if (this.status === 200) {
        const file = new File([this.response], "document.docx");

        const importXhr = new XMLHttpRequest();
        importXhr.open(
          "POST",
          "https://ej2services.syncfusion.com/production/web-services/api/documenteditor/import",
          true
        );
        importXhr.onreadystatechange = () => {
          if (importXhr.readyState === 4) {
            if (importXhr.status === 200 || importXhr.status === 304) {
              const docxObject = $("#docx_container");
              if (docxObject) {
                setTimeout(() => {
                  (docxObject[0] as any)?.ej2_instances?.[0]?.documentEditor.open(
                    importXhr.responseText
                  );
                }, 10);

                setTimeout(() => {
                  hideSpinner(docxObject[0]);
                }, 500);

                // Scroll to pageNumber
                const documentViewer =
                  (docxObject[0] as any)?.ej2_instances?.[0]?.documentEditor;
                if (documentViewer) {
                  setTimeout(() => {
                    documentViewer.scrollToPage(pageNumber);
                  }, 1000);
                }
              }
            } else {
              console.error("Docx import error:", importXhr.response);
            }
          }
        };

        const formData = new FormData();
        formData.append("files", file);
        importXhr.send(formData);
      } else {
        console.error("Failed to fetch docx from server.");
      }
    };

    xhr.onerror = function () {
      console.error("Error in docx GET request.");
    };

    xhr.send();
  };

  // 2) XLSX Loader
  const loadXlsx = async () => {
    if (!user) return;
    try {
  
      const apiUrl = buildDocumentUrl(
        process.env.REACT_APP_API_URL as string,
        openedDocument
      );
      const response = await fetch(apiUrl);
      if (!response.ok) throw new Error("XLSX fetch error");
      const fileBlob = await response.blob(); // convert the excel file to blob
      const file = new File([fileBlob], openedDocument); // convert blob into a File object
      
      // open into spreadsheet
      if (spreadsheetRef.current) {
        spreadsheetRef.current.open({ file });
      }
    } catch (error) {
      console.error("loadXlsx error:", error);
    }
  };

  // 3) PDF loader
  const loadPdf = () => {
    if (!user) return;

  
    // Set the PDF URL with the query parameters
    const pdfUrl = buildDocumentUrl(
      process.env.REACT_APP_API_URL as string,
      openedDocument
    );
    setPDFURL(pdfUrl);
  };
  

  // Once xlsx file has opened, jump to pageNumber if needed
  const openCompleteHandler = () => {
    if (spreadsheetRef.current) {
      spreadsheetRef.current.activeSheetIndex = pageNumber > 0 ? pageNumber - 1 : 0;
    }
  };

  // ---------------------- RENDER UI ---------------------- //
  return (
    <div className="bg-main_bg relative">
      {editorName === "pdf" && (
        <PdfViewerComponent
          id="container"
          documentPath={pdfURL}
          resourceUrl="https://cdn.syncfusion.com/ej2/25.1.40/dist/ej2-pdfviewer-lib"
          initialRenderPages={5}
          documentLoad={() => {
            const pdfviewerObject: any = $("#container");
            const pdfViewerContainer = pdfviewerObject[0]?.ej2_instances?.[0];
            if (pdfViewerContainer?.isRendered) {
              setTimeout(() => {
                pdfViewerContainer.navigation.goToPage(pageNumber);
              }, 500);
            }
          }}
          style={{
            height: `calc(100vh - ${
              124 * (deviceType === "laptop" ? 0.75 : 1)
            }px)`,
          }}
          toolbarSettings={{
            toolbarItems: ["PageNavigationTool"],
          }}
        >
          <Inject
            services={[
              Magnification,
              Navigation,
              Annotation,
              LinkAnnotation,
              BookmarkView,
              ThumbnailView,
              Print,
              TextSelection,
              TextSearch,
              FormFields,
              FormDesigner,
              PDFToolbar,
            ]}
          />
        </PdfViewerComponent>
      )}

      {editorName === "xlsx" && (
        <SpreadsheetComponent
          ref={spreadsheetRef}
          openUrl="https://services.syncfusion.com/react/production/api/spreadsheet/open"
          height={`calc(100vh - ${
            124 * (deviceType === "laptop" ? 0.75 : 1)
          }px)`}
          openComplete={openCompleteHandler}
          id="xlsx_container"
          showRibbon={false}
          showFormulaBar={false}
        />
      )}

      {editorName === "docx" && (
        <DocumentEditorContainerComponent
          id="docx_container"
          serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
          enableToolbar={false}
          height={`calc(100vh - ${
            124 * (deviceType === "laptop" ? 0.75 : 1)
          }px)`}
          showPropertiesPane={false}
        />
      )}

      {editorName === "unsupported" && (
        <div style={{ padding: "1rem" }}>Unsupported file type</div>
      )}
    </div>
  );
};
