import { type FC } from "react";

const ChatIcon: FC<any> = (props: any) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      stroke="#09090B"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.99865 9.5H11.9986M7.99865 13H14.9986M12.4986 20C17.1931 20 20.9986 16.1944 20.9986 11.5C20.9986 6.80558 17.1931 3 12.4986 3C7.80423 3 3.99865 6.80558 3.99865 11.5C3.99865 12.45 4.1545 13.3636 4.44202 14.2166C4.55022 14.5376 4.60432 14.6981 4.61408 14.8214C4.62371 14.9432 4.61643 15.0286 4.5863 15.1469C4.5558 15.2668 4.48844 15.3915 4.35374 15.6408L2.71808 18.6684C2.48477 19.1002 2.36812 19.3161 2.39423 19.4828C2.41697 19.6279 2.5024 19.7557 2.62782 19.8322C2.77183 19.9201 3.01595 19.8948 3.50419 19.8444L8.62521 19.315C8.78029 19.299 8.85783 19.291 8.92851 19.2937C8.99802 19.2963 9.04709 19.3029 9.11488 19.3185C9.1838 19.3344 9.27047 19.3678 9.4438 19.4345C10.3919 19.7998 11.4219 20 12.4986 20Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ChatIcon
