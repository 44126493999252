import { type FC } from "react";

const BagIcon: FC<any> = (props: any) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#09090B"
      {...props}
    >
      <path
        d="M11.9961 16.6776V14.1406"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.19 5.33203C19.88 5.33203 21.24 6.70203 21.24 8.39203V11.832C18.78 13.272 15.53 14.142 11.99 14.142C8.45 14.142 5.21 13.272 2.75 11.832V8.38203C2.75 6.69203 4.12 5.33203 5.81 5.33203H18.19Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4961 5.326V4.96C15.4961 3.74 14.5061 2.75 13.2861 2.75H10.7061C9.48609 2.75 8.49609 3.74 8.49609 4.96V5.326"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.77344 15.4844L2.96244 17.9934C3.09044 19.6844 4.49944 20.9914 6.19444 20.9914H17.7934C19.4884 20.9914 20.8974 19.6844 21.0254 17.9934L21.2144 15.4844"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BagIcon;
