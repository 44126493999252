import { type FC } from "react";
import Modal from "@mui/material/Modal";
import { IoCloseOutline } from "react-icons/io5";

interface DeleteFiveForceItemModalProps {
  open?: boolean;
  handleClose?: () => void;
  onDelete: () => void;
}

const DeleteFiveForceItemModal: FC<DeleteFiveForceItemModalProps> = ({
  open,
  handleClose,
  onDelete,
}) => {
  return (
    <Modal
      open={Boolean(open)}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[327px] xl:w-[436px] bg-white rounded-[10px] shadow-lg">
        <div className="py-3 xl:py-4 px-[18px] xl:px-6 flex items-center justify-between border-b border-solid border-[#E4E4E7] gap-3 xl:gap-4">
          <div className="">
            <div className="text-[13px] xl:text-lg leading-[16px] xl:leading-[22px] font-bold text-[#18181B] mb-[3px] xl:mb-1">
              Delete
            </div>
            <div className="text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] font-medium text-[#71717A]">
              Please confirm
            </div>
          </div>
          <button
            className="w-[30px] xl:w-10 h-[30px] xl:h-10 border border-solid border-[#D4D4D8] flex items-center justify-center flex-none rounded-full"
            onClick={() => {
              if (handleClose) handleClose();
            }}
          >
            <IoCloseOutline className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-[#09090B]" />
          </button>
        </div>
        <div className="p-[18px] xl:p-6">
          <div className="w-full text-[15px] xl:text-xl font-normal text-[#18181B] mb-6 xl:mb-8">
            Selected record will be permanently deleted. This action is
            irreversible.
          </div>
          <div className="flex gap-3 xl:gap-4">
            <button
              className="outline-none border border-solid border-[#71717A] h-9 xl:h-12 flex items-center justify-center rounded-lg w-full"
              onClick={() => {
                if (handleClose) handleClose();
              }}
            >
              <span className="text-xs xl:text-base font-bold text-[#71717A]">
                Cancel
              </span>
            </button>
            <button
              className="outline-none bg-[#FA302F] h-9 xl:h-12 flex items-center justify-center rounded-lg w-full"
              onClick={() => onDelete()}
            >
              <span className="text-xs xl:text-base font-bold text-white">
                Delete
              </span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteFiveForceItemModal;
