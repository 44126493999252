import { type FC, useEffect, useState } from "react";
import { HiOutlineFolderMinus, HiOutlineFolderPlus } from "react-icons/hi2";
import BpCheckbox from "../../common/BPCheckbox";
import { useRagContext } from "../../../contexts/RagContext";

interface TreeNode {
  name: string;
  isFolder: boolean;
  children: TreeNode[];
  originalFilePath?: string;   // for file nodes
  requiresOCR?: boolean;
}

interface BrainstormFolderProps {
  folder: {
    structure: any;  // e.g. { "Team Documents": {...}, "My Documents": {...} }
  };
}

const BrainstormFolder: FC<BrainstormFolderProps> = ({ folder }) => {
  const [tree, setTree] = useState<TreeNode[]>([]);
  const [expandedPaths, setExpandedPaths] = useState<Set<string>>(new Set());

  const {
    selectedBrainstormFiles,
    setSelectedBrainstormFiles,
  } = useRagContext();

  // Build the local tree upon mount or folder changes
  useEffect(() => {
    if (folder?.structure) {
      setTree(buildTree(folder.structure, ""));
    }
  }, [folder]);

  /**
   * If val.__file => file => store originalFilePath
   * else => folder => recurse
   */
  function buildTree(data: any, currentPath: string): TreeNode[] {
    if (!data || typeof data !== "object") return [];
    return Object.keys(data).map((key) => {
      const val = data[key];
      // Check if it's a file => val.__file
      if (val && val.__file) {
        // file node
        return {
          name: key,
          isFolder: false,
          children: [],
          originalFilePath: val.originalFilePath,
          requiresOCR: val.requiresOCR || false,
        };
      } else {
        // folder => recurse
        return {
          name: key,
          isFolder: true,
          children: buildTree(val, `${currentPath}/${key}`),
        };
      }
    });
  }

  // Expand/collapse
  const toggleExpand = (nodePath: string) => {
    const copy = new Set(expandedPaths);
    if (copy.has(nodePath)) copy.delete(nodePath);
    else copy.add(nodePath);
    setExpandedPaths(copy);
  };

  // Build a path for each node so we can store expansions
  function getNodePath(parentPath: string, name: string) {
    return parentPath ? `${parentPath}/${name}` : name;
  }

  // Gather all filePaths under a folder node
  function gatherAllFiles(node: TreeNode): string[] {
    if (!node.isFolder) {
      return node.originalFilePath ? [node.originalFilePath] : [];
    }
    let results: string[] = [];
    node.children.forEach((child) => {
      results = results.concat(gatherAllFiles(child));
    });
    return results;
  }

  // Toggle an entire folder => all child files
  const toggleFolder = (node: TreeNode) => {
    const allPaths = gatherAllFiles(node);
    const allSelected = allPaths.every((fp) => selectedBrainstormFiles.includes(fp));
    let updated = [...selectedBrainstormFiles];
    if (allSelected) {
      // Unselect them
      updated = updated.filter((p) => !allPaths.includes(p));
    } else {
      // Select them
      allPaths.forEach((fp) => {
        if (!updated.includes(fp)) updated.push(fp);
      });
    }
    setSelectedBrainstormFiles(updated);
  };

  // Toggle single file
  const toggleFile = (filePath: string) => {
    let updated = [...selectedBrainstormFiles];
    const idx = updated.indexOf(filePath);
    if (idx >= 0) {
      updated.splice(idx, 1);
    } else {
      updated.push(filePath);
    }
    setSelectedBrainstormFiles(updated);
  };

  // Check if a folder is fully selected
  const isFolderSelected = (node: TreeNode): boolean => {
    const allPaths = gatherAllFiles(node);
    return (
      allPaths.length > 0 &&
      allPaths.every((fp) => selectedBrainstormFiles.includes(fp))
    );
  };

  // Is a single file selected?
  const isFileSelected = (fp: string) => {
    return selectedBrainstormFiles.includes(fp);
  };

  // Recursively render
  const renderNodes = (nodes: TreeNode[], parentPath: string) => {
    return nodes.map((node) => {
      const nodePath = getNodePath(parentPath, node.name);

      if (node.isFolder) {
        // folder
        const expanded = expandedPaths.has(nodePath);
        const folderChecked = isFolderSelected(node);

        return (
          <div key={nodePath}>
            <div className="w-full px-3 py-2 flex items-start gap-3 rounded-[10px] group sidebar-folder text-[#09090B]">
              <button onClick={() => toggleExpand(nodePath)} className="outline-none flex-none">
                {expanded ? (
                  <HiOutlineFolderMinus className="text-lg xl:text-2xl text-[#3B82F6]" />
                ) : (
                  <HiOutlineFolderPlus className="text-lg xl:text-2xl text-[#3B82F6]" />
                )}
              </button>
              <p
                className="text-sm font-medium folder-name text-break"
                onClick={() => toggleExpand(nodePath)}
              >
                {node.name}
              </p>

              {/* Folder checkbox */}
              <button
                className={`ml-auto flex items-center ${
                  folderChecked ? "visible" : "invisible group-hover:visible"
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFolder(node);
                }}
              >
                <BpCheckbox 
                  size="small" 
                  className="w-[10px] xl:w-[14px] h-[10px] xl:h-[14px]"
                  checked={folderChecked} />
              </button>
            </div>
            {expanded && node.children.length > 0 && (
              <div className="pl-6 flex-col sidebar-folder-items">
                {renderNodes(node.children, nodePath)}
              </div>
            )}
          </div>
        );
      } else {
        // file node
        const fp = node.originalFilePath || "";
        const checked = isFileSelected(fp);

        return (
          <div
            key={nodePath}
            className={`w-full px-3 py-2 rounded-xl flex items-start gap-1 justify-between cursor-pointer group ${
              checked ? "bg-[#E4E4E7] text-[#18181B]" : "bg-transparent text-[#52525B]"
            }`}
            onClick={() => toggleFile(fp)}
          >
            <p className={`text-sm document-title ${checked ? "font-medium" : "font-normal"}`}>
              {node.name}
            </p>

            <div className="flex gap-2 ml-auto">
              <button
                className={`outline-none flex-none ml-auto flex items-center ${
                  checked ? "visible" : "invisible group-hover:visible"
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleFile(fp);
                }}
              >
                <BpCheckbox
                size="small"
                className="w-[10px] xl:w-[14px] h-[10px] xl:h-[14px]"
                checked={checked} />
              </button>
            </div>
          </div>
        );
      }
    });
  };

  return <div>{renderNodes(tree, "")}</div>;
};

export default BrainstormFolder;
