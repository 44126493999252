import { type FC, useEffect, useState } from "react";
import axios from "axios";
import { useRagContext } from "../../../contexts/RagContext";
import editPencilIcon from "../../../assets/svg/edit-pen.svg";
import ICPIcon from "../../icons/ICPIcon";
import logo from "../../../assets/auth/logo.svg";
import { useHistory } from "react-router";
import PieChartIcon from "../../icons/PieChartIcon";
import { useUserContext } from "../../../contexts/UserContext";
import { GrLinkNext } from "react-icons/gr";
import { BiLoaderAlt } from "react-icons/bi";
import BrainStormEditModal from "./BrainStormEditModal/BrainStormEditModal";
import RefreshIcon from "../../icons/RefreshIcon";
import FavoriteIcon from "../../icons/FavoriteIcon";
import SourceMaterialFolder from "../../Concept/ConceptModal/SourceMaterialFolder";
import FolderIcon from "../../icons/FolderIcon";
import { useLayoutContext } from "../../../contexts/LayoutContext";
import CommonMarkdown from "../../common/CommonMarkdown";

type BrainstormStatus = {
  fromSource: boolean;
  status: string;
};
interface SourceMaterialFolderProps {
  nestedStructure: any;            // The big object with subfolders/files
  selectedPaths: string[];         // The array of full paths that are checked
  onSelectedPathsChange: (newPaths: string[]) => void;
}
const BrainStormModal: FC = () => {
  const history = useHistory();
  const {
    brainStormModalVisible,
    selectedBrainstormId,
    socket,
    socketId,
    sourceList,
    openShareFeedbackModal,
    getConceptFolders,
  } = useRagContext();
  const { user } = useUserContext();
  const { setBreadCrumbs } = useLayoutContext();

  const [brainstormData, setBrainstormData] = useState<any>(null);
  const [selectedHMWQuestionIndex, setSelectedHMWQuestionIndex] =
    useState<number>(-1);
  const [selectedDraftConceptIndexes, setSelectedDraftConceptIndexes] =
    useState<number[]>([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [generateMoreConceptLoading, setGenerateMoreConceptLoading] =
    useState<boolean>(false);
  const [generateMoreQuestionLoading, setGenerateMoreQuestionLoading] =
    useState<boolean>(false);
  const [regenerateConceptLoading, setRegenerateConceptLoading] =
    useState<string>("false");
  const [regenerateQuestionLoading, setRegenerateQuestionLoading] =
    useState<string>("false");
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [likeIndexes, setLikeIndexes] = useState<number[]>([]);
  const [unlikeIndexes, setUnlikeIndexes] = useState<number[]>([]);
  const [brainstormStatus, setBrainstormStatus] = useState<BrainstormStatus>({
    fromSource: false,
    status: "fetching",
  });
  const [nestedStructure, setNestedStructure] = useState<any>({});
  const closeBrainstormModal = () => {
    setBreadCrumbs([
      {
        title: "Concept",
        active: false,
        onClick: () => {},
      },
    ]);
    history.push({
      pathname: "/concept",
    });
  };

  const getBrainstormDetail = async () => {
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/getBrainStormConcepts",
        {
          id: selectedBrainstormId,
        }
      );

      setBrainstormData(response.data.data);
      getConceptFolders();
    } catch (error) {
      console.log(error);
    }
  };

  const generateMoreHMWQuestions = async () => {
    try {
      setGenerateMoreQuestionLoading(true);
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/generateMoreHMWQuestions",
        {
          brainstorm_id: selectedBrainstormId,
          team_id: user?.organizationMemberships[0]?.organization.id,
        }
      );

      setBrainstormData((prev: any) => {
        return {
          ...prev,
          hmw_questions: response.data.response,
        };
      });
      setGenerateMoreQuestionLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const regenerateHMWQuestions = async () => {
    try {
      setRegenerateQuestionLoading("true");
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/regenerateHMWQuestions",
        {
          brainstorm_id: selectedBrainstormId,
          team_id: user?.organizationMemberships[0]?.organization.id,
        }
      );

      setBrainstormData((prev: any) => {
        return {
          ...prev,
          hmw_questions: response.data.response,
        };
      });
      setSelectedHMWQuestionIndex(-1);
      setRegenerateQuestionLoading("done");
    } catch (error) {
      console.log(error);
    }
  };

  const createBrainStormConcepts = async () => {
    try {
      setShowLoading(true);
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/createBrainStormConcepts",
        {
          selected_hmw_question:
            brainstormData.hmw_questions[selectedHMWQuestionIndex],
          selected_hmw_question_index: selectedHMWQuestionIndex,
          brainstorm_id: selectedBrainstormId,
          team_id: user?.organizationMemberships[0]?.organization.id,
        }
      );

      setBrainstormData((prev: any) => {
        return {
          ...prev,
          draft_concepts: response.data.response,
          selected_hmw_question_index: selectedHMWQuestionIndex,
        };
      });
      setShowLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleDraftConcept = (index: number) => {
    if (selectedDraftConceptIndexes.findIndex((e) => e === index) !== -1) {
      setSelectedDraftConceptIndexes((prev) => {
        return prev.filter((e) => e !== index);
      });
    } else {
      setSelectedDraftConceptIndexes((prev) => {
        return [...prev, index];
      });
    }
  };

  const generateMoreBrainStormConcepts = async () => {
    try {
      setGenerateMoreConceptLoading(true);
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/generateMoreBrainStormConcepts",
        {
          brainstorm_id: selectedBrainstormId,
          team_id: user?.organizationMemberships[0]?.organization.id,
        }
      );

      setBrainstormData((prev: any) => {
        return {
          ...prev,
          draft_concepts: response.data.response,
        };
      });
      setSelectedDraftConceptIndexes([]);
      setGenerateMoreConceptLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const regenerateBrainStormConcepts = async () => {
    try {
      setRegenerateConceptLoading("true");
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/regenerateBrainStormConcepts",
        {
          brainstorm_id: selectedBrainstormId,
          team_id: user?.organizationMemberships[0]?.organization.id,
        }
      );

      setBrainstormData((prev: any) => {
        return {
          ...prev,
          draft_concepts: response.data.response,
        };
      });
      setSelectedDraftConceptIndexes([]);
      setRegenerateConceptLoading("done");
    } catch (error) {
      console.log(error);
    }
  };

  const saveBrainStormConcepts = async () => {
    try {
      setShowLoading(true);
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/saveBrainStormConcepts",
        {
          brainstorm_id: selectedBrainstormId,
          team_id: user?.organizationMemberships[0]?.organization.id,
          concepts_array: selectedDraftConceptIndexes.map((e) => {
            return brainstormData.draft_concepts[e];
          }),
        }
      );

      let concept_folder_id = response.data.concept_folder_id;
      for (let index = 0; index < selectedDraftConceptIndexes.length; index++) {
        const conceptArray =
          brainstormData.draft_concepts[
            selectedDraftConceptIndexes.sort()[index]
          ];

        let params: any = {
          team_id: user?.organizationMemberships[0]?.organization.id,
          concept_folder_id: concept_folder_id,
          socketId: socketId,
          context: conceptArray.join(": "),
          type: "brainstorm",
          brainstorm_id: selectedBrainstormId,
          concept_array: conceptArray,
          pinecone_id: brainstormData.pinecone_id,
          source_list: brainstormData.source_list,
        };

        try {
          await axios.post(
            process.env.REACT_APP_API_URL + "api/createConcept",
            params
          );
        } catch (error) {
          console.log(error);
        }
      }

      getBrainstormDetail();
      getConceptFolders();
      setShowLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditConcept = (id: string) => {
    const searchParams = new URLSearchParams();
    searchParams.set("render_type", "modal");
    searchParams.set("render_modal", "concept_modal");
    searchParams.set("concept_id", id);

    history.push({
      pathname: "/concept",
      search: `?${searchParams.toString()}`,
    });
  };

  const regenerateHMWQuestion = async (question: string, index: number) => {
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/regenerateHMWQuestion",
        {
          brainstorm_id: selectedBrainstormId,
          team_id: user?.organizationMemberships[0]?.organization.id,
          selected_hmw_question: question,
          selected_hmw_question_index: index,
        }
      );

      setBrainstormData((prev: any) => {
        return {
          ...prev,
          hmw_questions: prev.hmw_questions.map((q: string, idx: number) =>
            idx === index ? response.data.response : q
          ),
        };
      });
    } catch (error) {
      console.log(error);
    }
  };

  const regenerateBrainStormConcept = async (
    concept: string[],
    index: number
  ) => {
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/regenerateBrainStormConcept",
        {
          brainstorm_id: selectedBrainstormId,
          team_id: user?.organizationMemberships[0]?.organization.id,
          concept: concept,
          selected_brainstorm_concept_index: index,
        }
      );

      setBrainstormData((prev: any) => {
        return {
          ...prev,
          draft_concepts: prev.draft_concepts.map((q: string, idx: number) =>
            idx === index ? response.data.response : q
          ),
        };
      });
    } catch (error) {
      console.log(error);
    }
  };

  const toggleLike = (index: number) => {
    if (likeIndexes.findIndex((e) => e === index) !== -1) {
      setLikeIndexes((prev) => prev.filter((e) => e !== index));
    } else {
      setLikeIndexes((prev) => [...prev, index]);
    }
  };

  const toggleUnlike = (index: number) => {
    if (unlikeIndexes.findIndex((e) => e === index) !== -1) {
      setUnlikeIndexes((prev) => prev.filter((e) => e !== index));
    } else {
      setUnlikeIndexes((prev) => [...prev, index]);
    }
  };

  const isLiked = (index: number) => {
    return likeIndexes.findIndex((e) => e === index) !== -1;
  };
  const isUnliked = (index: number) => {
    return unlikeIndexes.findIndex((e) => e === index) !== -1;
  };

  useEffect(() => {
    if (socket) {
      socket.on("hmw_questions", (data: any) => {
        if (data.socketId === socketId) {
          setBrainstormData((prev: any) => ({
            ...prev,
            hmw_questions: data.hmw_questions,
          }));
        }
      });
    }

    return () => {
      if (socket) {
        return socket.off("hmw_questions");
      }
    };
  }, [socket, socketId]);

  useEffect(() => {
    if (brainStormModalVisible) {
      getBrainstormDetail();
    } else {
      setSelectedHMWQuestionIndex(-1);
      setSelectedDraftConceptIndexes([]);
      setGenerateMoreConceptLoading(false);
      setGenerateMoreQuestionLoading(false);
      setRegenerateConceptLoading("false");
      setRegenerateQuestionLoading("false");
    }
  }, [selectedBrainstormId, brainStormModalVisible]);

  useEffect(() => {
    if (brainstormData) {
      if (brainstormData.draft_concepts?.length === 0) {
        setBrainstormStatus({
          fromSource: brainstormData.fromSource,
          status: "hmw_question",
        });
      } else if (brainstormData.generated_concepts?.length === 0) {
        setBrainstormStatus({
          fromSource: brainstormData.fromSource,
          status: "draft_concepts",
        });
      } else if (brainstormData.generated_concepts) {
        setBrainstormStatus({
          fromSource: brainstormData.fromSource,
          status: "generated_concepts",
        });
      }
    } else {
      setBrainstormStatus({
        fromSource: false,
        status: "fetching",
      });
    }
  }, [brainstormData]);

  useEffect(() => {
    setLikeIndexes([]);
    setUnlikeIndexes([]);
  }, [brainstormStatus]);

  useEffect(() => {
    if (brainStormModalVisible && brainstormData) {
      setBreadCrumbs([
        {
          title: "Concept",
          active: false,
          onClick: () => {
            closeBrainstormModal();
          },
        },
        {
          title: brainstormData?.name,
          active: true,
          onClick: () => {},
        },
      ]);
    }
  }, [brainStormModalVisible, brainstormData]);

  const handleClickProblemFraming = () => {
    if (brainstormData.draft_concepts?.length === 0) {
      setBrainstormStatus({
        fromSource: brainstormData.fromSource,
        status: "hmw_question",
      });
    } else if (brainstormData.generated_concepts?.length === 0) {
      setBrainstormStatus({
        fromSource: brainstormData.fromSource,
        status: "draft_concepts",
      });
    } else {
      setBrainstormStatus({
        fromSource: brainstormData.fromSource,
        status: "hmw_question",
      });
    }
  };

  const handleClickConceptsGenerated = () => {
    if (brainstormData.generated_concepts?.length > 0) {
      setBrainstormStatus((prev) => ({
        ...prev,
        status: "generated_concepts",
      }));
    }
  };

  const isPassedHMWQuestion = () => {
    return brainstormData.draft_concepts?.length > 0;
  };
  function buildNestedStructure(sourceList: any[]) {
    // We'll store top-level "Team Documents" and "My Documents" if needed
    // Or just build a single object. Adjust as your project requires.
    const root: any = {};
  
    sourceList.forEach((item) => {
      // Skip any "deleted" items or invalid filePaths
      if (item.isFolderDeleted === 1) return;
      if (!item.filePath) return;
  
      // Remove leading slash (if present)
      let trimmed = item.filePath;
      if (trimmed.startsWith("/")) {
        trimmed = trimmed.slice(1);
      }
      // e.g. trimmed => "development/orgs/org_123/test case 2/file.pdf"
  
      // Split on "/"
      const parts = trimmed.split("/");
      // Suppose the first segments are ["{application_name}", "orgs", "org_123", ...]
      // We'll skip those first 3 to get the meaningful subpath: ["test case 2", "file.pdf"]
      const meaningfulParts = parts.slice(3);
  
      // Insert into root
      let current = root;
      for (let i = 0; i < meaningfulParts.length; i++) {
        const segment = meaningfulParts[i];
        const isLast = i === meaningfulParts.length - 1;
  
        if (!segment) continue;
  
        if (isLast && item.type === "file") {
          // It's a file => store it with some marker
          current[segment] = {
            __file: true,
            originalPath: item.filePath, // keep the full path for toggles
            requiresOCR: item.requiresOCR,
          };
        } else {
          // It's a folder => create subobject if not present
          if (!current[segment]) {
            current[segment] = {};
          }
          current = current[segment];
        }
      }
    });
  
    return root;
  }
  const [sourceMaterialStructureReady, setSourceMaterialStructureReady] = useState<any>({});

  useEffect(() => {
    if (!sourceList || !Array.isArray(sourceList) || !brainstormData) {
      setSourceMaterialStructureReady({});
      return;
    }
    // We build a structure with top-level “Team Documents”, “My Documents”, plus “Other Files”.
    const built = buildStructureWithOtherFiles(sourceList, brainstormData.source_list || []);
    setSourceMaterialStructureReady(built);
  }, [sourceList, brainstormData]);

  // A helper that merges "Team Documents"/"My Documents" from sourceList + "Other Files" for missing paths:
  function buildStructureWithOtherFiles(allItems: any[], selectedPaths: string[]) {
    // Step A: separate into teamRoot + userRoot
    const teamRoot: any = {};
    const userRoot: any = {};
    allItems.forEach((item: any) => {
      if (!item.filePath) return;
      let path = item.filePath;
      if (path.startsWith("/")) path = path.slice(1);
      const parts = path.split("/");
      const docType = parts[1]; // "orgs" or "users"
      const meaningful = parts.slice(3);
      let currentRoot = docType === "users" ? userRoot : teamRoot;

      // Build
      let cursor = currentRoot;
      for (let i = 0; i < meaningful.length; i++) {
        const seg = meaningful[i];
        if (!seg) continue;
        const isLast = i === meaningful.length - 1;
        if (isLast && item.type === "file") {
          cursor[seg] = {
            __file: true,
            originalFilePath: item.filePath,
            requiresOCR: item.requiresOCR || false,
          };
        } else {
          if (!cursor[seg]) cursor[seg] = {};
          cursor = cursor[seg];
        }
      }
    });

    // Step B: find missing paths => place them in “Other Files”
    const missingPaths = findMissingPaths(selectedPaths, allItems);
    const otherRoot: any = {};
    missingPaths.forEach((mp) => {
      // parse just the filename
      const slashIdx = mp.lastIndexOf("/");
      let fileName = mp;
      if (slashIdx >= 0 && slashIdx < mp.length - 1) {
        fileName = mp.substring(slashIdx + 1);
      }
      otherRoot[fileName] = {
        __file: true,
        originalFilePath: mp,
        requiresOCR: false,
      };
    });

    // Build the final object
    const finalObj: any = {
      "Team Documents": teamRoot,
      "My Documents": userRoot,
    };
    if (Object.keys(otherRoot).length > 0) {
      finalObj["Other Files"] = otherRoot;
    }
    console.log("Final object:", finalObj);
    return finalObj;
  }

  // Helper to find paths that are in selectedPaths but not in sourceList
  function findMissingPaths(selectedPaths: string[], allItems: any[]) {
    console.log("Selected paths:", selectedPaths);
    const missing: string[] = [];
    selectedPaths.forEach((p) => {
      const found = allItems.some((item: any) => item.filePath === p);
      if (!found) missing.push(p);
    });
    return missing;
  }
  return (
    <div
      className="w-full h-full absolute top-0 left-0 z-20 bg-[#F4F4F5]"
      hidden={!brainStormModalVisible}
    >
      {brainstormData && (
        <>
          <div className="px-[30px] xl:px-10 pt-3 xl:pt-4 pb-[18px] xl:pb-6 flex justify-between border-t border-solid border-[#D4D4D8]">
            <div className="">
              <div className="flex gap-[6px] xl:gap-2 items-center">
                <div className="text-[15px] xl:text-xl font-bold text-[#27272A]">
                  {brainstormData.name}
                </div>
                <button
                  className="outline-none flex items-center justify-center w-6 xl:w-8 h-6 xl:h-8 border border-solid border-[#D4D4D8] rounded-full"
                  onClick={() => setEditModalOpen(true)}
                >
                  <img
                    src={editPencilIcon}
                    alt=""
                    className="w-[15px] xl:w-5 h-[15px] xl:h-5"
                  />
                </button>
              </div>
              <div className="text-xs xl:text-base font-normal text-[#71717A]">
                {brainstormData.description}
              </div>
            </div>
          </div>
          <div className="px-[30px] xl:px-10 flex gap-[6px] xl:gap-2">
            <div className="flex flex-col w-[252px] xl:w-[336px] gap-3 xl:gap-4 h-[calc(100vh-180px)] xl:h-[calc(100vh-241px)] overflow-auto">
              <div className="bg-white rounded-2xl w-full border border-solid border-[#D4D4D8] flex-1">
                <div className="flex flex-col gap-3 xl:gap-4 p-4">
                  <div className="text-[15px] xl:text-xl font-bold text-[rgb(39,39,42)]">
                    New Ideation
                  </div>
                  <div className="flex flex-col gap-3 xl:gap-4">
                    <div
                      className={`p-3 xl:p-4 flex gap-[6px] xl:gap-2 cursor-pointer ${
                        brainstormStatus.status === "source_material"
                          ? "stroke-[#3B82F6] fill-[#3B82F6]"
                          : "stroke-[#09090B] fill-[#09090B]"
                      }`}
                      onClick={() => {
                        setBrainstormStatus((prev) => ({
                          ...prev,
                          status: "source_material",
                        }));
                      }}
                    >
                      <FolderIcon
                        className="flex-none w-[18px] xl:w-6 h-[18px] xl:h-6"
                        stroke={"inherit"}
                      />
                      <div
                        className={`text-xs xl:text-base font-medium whitespace-nowrap overflow-hidden text-ellipsis flex-1 ${
                          brainstormStatus.status === "source_material"
                            ? "text-[#3B82F6]"
                            : "text-[#71717A]"
                        }`}
                      >
                        Source Material
                      </div>
                    </div>
                    <div
                      className={`p-3 xl:p-4 flex gap-[6px] xl:gap-2 cursor-pointer ${
                        brainstormStatus.status === "hmw_question" ||
                        brainstormStatus.status === "draft_concepts"
                          ? "stroke-[#3B82F6] fill-[#3B82F6]"
                          : "stroke-[#09090B] fill-[#09090B]"
                      }`}
                      onClick={() => handleClickProblemFraming()}
                    >
                      <ICPIcon
                        className="flex-none w-[18px] xl:w-6 h-[18px] xl:h-6"
                        stroke={"inherit"}
                      />
                      <div
                        className={`text-xs xl:text-base font-medium whitespace-nowrap overflow-hidden text-ellipsis flex-1 ${
                          brainstormStatus.status === "hmw_question" ||
                          brainstormStatus.status === "draft_concepts"
                            ? "text-[#3B82F6]"
                            : "text-[#71717A]"
                        }`}
                      >
                        Problem-framing
                      </div>
                    </div>
                    <div
                      className={`p-3 xl:p-4 flex gap-[6px] xl:gap-2 cursor-pointer ${
                        brainstormStatus.status === "generated_concepts"
                          ? "stroke-[#3B82F6] fill-[#3B82F6]"
                          : "stroke-[#09090B] fill-[#09090B]"
                      }`}
                      onClick={() => handleClickConceptsGenerated()}
                    >
                      <PieChartIcon
                        className="flex-none w-[18px] xl:w-6 h-[18px] xl:h-6"
                        stroke={"inherit"}
                      />
                      <div
                        className={`text-xs xl:text-base font-medium whitespace-nowrap overflow-hidden text-ellipsis flex-1 ${
                          brainstormStatus.status === "generated_concepts"
                            ? "text-[#3B82F6]"
                            : "text-[#71717A]"
                        }`}
                      >
                        Concepts Generated
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1 p-[15px] xl:p-5 bg-white h-[calc(100vh-180px)] xl:h-[calc(100vh-241px)] overflow-auto">
              {brainstormStatus.status === "hmw_question" && (
                <div className="">
                  <div className="flex flex-col gap-[18px] xl:gap-6">
                    <div className="w-fit ml-auto bg-[#EDEDED] rounded-lg px-3 xl:px-4 py-3 text-xs xl:text-base text-[#18181B] font-normal">
                      Please generate a list of 5 "how might we" questions from
                      this reference.
                    </div>
                    {brainstormData.hmw_questions.length > 0 ? (
                      <>
                        <div className="flex gap-[7px] xl:gap-[10px]">
                          <img
                            src={logo}
                            alt="Logo"
                            className="flex-none w-[15px] xl:w-5 h-[15px] xl:h-5 mt-1"
                          />
                          <div className="flex-1 text-[#000000cb] text-xs xl:text-base font-medium">
                            {`Based on the provided context, here are ${
                              brainstormData.hmw_questions.length > 5
                                ? "ten"
                                : "five"
                            } "How Might We" (HMW) questions designed to stimulate innovation and improve processes:`}
                          </div>
                        </div>
                        <div className="flex flex-col gap-3 xl:gap-4 relative">
                          {brainstormData.hmw_questions.map(
                            (question: string, index: number) => (
                              <div className="relative group" key={index}>
                                <div
                                  className="flex gap-[7px] xl:gap-[10px] cursor-pointer"
                                  key={index}
                                  onClick={() =>
                                    setSelectedHMWQuestionIndex(index)
                                  }
                                >
                                  <input
                                    type="radio"
                                    className="w-[15px] xl:w-5 h-[15px] xl:h-5 flex-none mt-[1px] xl:mt-[2px]"
                                    checked={selectedHMWQuestionIndex === index}
                                    readOnly
                                    data-event-name="Brainstorm: Question Selected"
                                  />
                                  <div
                                    className="flex-1 text-[#000000cb] text-xs xl:text-base font-normal"
                                    data-event-name="Brainstorm: Question Selected"
                                  >
                                    {question}
                                  </div>
                                </div>
                                <div className="absolute right-0 top-0 hidden group-hover:flex px-2 gap-[7px] xl:gap-[10px] bg-white">
                                  <RefreshIcon
                                    stroke="#3B82F6"
                                    strokeWidth="1"
                                    className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none cursor-pointer"
                                    onClick={() =>
                                      regenerateHMWQuestion(question, index)
                                    }
                                  />
                                  <FavoriteIcon
                                    className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none cursor-pointer"
                                    fill={
                                      isLiked(index) ? "#7cfc00" : "#3B82F6"
                                    }
                                    onClick={() => toggleLike(index)}
                                  />
                                  <FavoriteIcon
                                    className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none cursor-pointer rotate-180"
                                    fill={
                                      isUnliked(index) ? "#7cfc00" : "#3B82F6"
                                    }
                                    onClick={() => toggleUnlike(index)}
                                  />
                                </div>
                              </div>
                            )
                          )}
                        </div>
                        {!isPassedHMWQuestion() && (
                          <>
                            <div className="flex gap-[6px] xl:gap-2 pl-[25px] xl:pl-[34px]">
                              <button
                                className={`py-[6px] xl:py-2 px-[18px] xl:px-6 h-[30px] xl:h-10 rounded-full border border-solid border-[#71717A] items-center gap-[6px] xl:gap-2 ${
                                  brainstormData.hmw_questions.length > 5
                                    ? "hidden"
                                    : "flex"
                                }`}
                                onClick={() => generateMoreHMWQuestions()}
                                disabled={
                                  brainstormData.hmw_questions.length > 5 ||
                                  generateMoreQuestionLoading
                                }
                                data-event-name="Brainstorm: Five More Question Generated"
                              >
                                <div
                                  className={`text-xs xl:text-base font-medium ${
                                    generateMoreQuestionLoading
                                      ? "text-[#A1A1AA]"
                                      : "text-[#71717A]"
                                  }`}
                                  data-event-name="Brainstorm: Five More Question Generated"
                                >
                                  Generate 5 more questions
                                </div>
                                {generateMoreQuestionLoading && (
                                  <BiLoaderAlt className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-[#A1A1AA] animate-spin" />
                                )}
                              </button>
                              <button
                                className={`py-[6px] xl:py-2 px-[18px] xl:px-6 h-[30px] xl:h-10 rounded-full border border-solid border-[#71717A] items-center gap-[6px] xl:gap-2 ${
                                  regenerateQuestionLoading === "done"
                                    ? "hidden"
                                    : "flex"
                                }`}
                                onClick={() => regenerateHMWQuestions()}
                                disabled={regenerateQuestionLoading === "true"}
                                data-event-name="Brainstorm: More Detail Regenerated"
                              >
                                <div
                                  className={`text-xs xl:text-base font-medium ${
                                    regenerateQuestionLoading === "true"
                                      ? "text-[#A1A1AA]"
                                      : "text-[#71717A]"
                                  }`}
                                  data-event-name="Brainstorm: More Detail Regenerated"
                                >
                                  Regenerate with more detail
                                </div>
                                {regenerateQuestionLoading === "true" && (
                                  <BiLoaderAlt className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-[#A1A1AA] animate-spin" />
                                )}
                              </button>
                            </div>
                            <div className="flex items-center justify-between pl-[25px] xl:pl-[34px]">
                              <div className="text-xs xl:text-base font-medium text-[#A1A1AA]">
                                *Select one to brainstorm
                              </div>
                              <button
                                className={`py-3 px-[18px] xl:px-6 h-9 xl:h-12 rounded-full flex items-center gap-[6px] xl:gap-2 ${
                                  selectedHMWQuestionIndex === -1 || showLoading
                                    ? "bg-[#E4E4E7]"
                                    : "bg-[#3B82F6]"
                                }`}
                                disabled={
                                  selectedHMWQuestionIndex === -1 || showLoading
                                }
                                onClick={() => createBrainStormConcepts()}
                                data-event-name="Brainstorm: 'Next To Brainstorm' Clicked"
                              >
                                <div
                                  className={`text-xs xl:text-base font-bold ${
                                    selectedHMWQuestionIndex === -1
                                      ? "text-[#A1A1AA]"
                                      : "text-white"
                                  }`}
                                  data-event-name="Brainstorm: 'Next To Brainstorm' Clicked"
                                >
                                  Next To Brainstorm
                                </div>
                                {showLoading && (
                                  <BiLoaderAlt className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-white animate-spin" />
                                )}
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <div className="w-full flex pl-[22px] xl:pl-[30px]">
                        <BiLoaderAlt className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-[#3B82F6] animate-spin" />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {brainstormStatus.status === "draft_concepts" && (
                <div className="">
                  <div className="flex flex-col gap-[18px] xl:gap-6">
                    <div className="w-fit ml-auto bg-[#EDEDED] rounded-lg px-3 xl:px-4 py-3 text-xs xl:text-base text-[#18181B] font-normal">
                      {
                        brainstormData.hmw_questions[
                          brainstormData.selected_hmw_question_index
                        ]
                      }
                    </div>
                    <div className="flex flex-col gap-3 xl:gap-4">
                      {brainstormData.draft_concepts.map(
                        (draftConcept: string[], index: number) => (
                          <div className="relative group" key={index}>
                            <div
                              className="flex gap-[7px] xl:gap-[10px] cursor-pointer"
                              key={index}
                              onClick={() => toggleDraftConcept(index)}
                            >
                              <input
                                type="checkbox"
                                className="w-[15px] xl:w-5 h-[15px] xl:h-5 flex-none mt-[1px] xl:mt-[2px]"
                                checked={
                                  selectedDraftConceptIndexes.findIndex(
                                    (e) => e === index
                                  ) !== -1
                                }
                                readOnly
                                data-event-name="Brainstorm: Concept Selected"
                              />
                              <div className="flex-1 text-[#000000cb] text-xs xl:text-base font-normal">
                                <span
                                  className="font-bold"
                                  data-event-name="Brainstorm: Concept Selected"
                                >
                                  {draftConcept[0]}
                                  {`: `}
                                </span>
                                <span
                                  className="font-normal"
                                  data-event-name="Brainstorm: Concept Selected"
                                >
                                  {draftConcept[1]}
                                </span>
                              </div>
                            </div>
                            <div className="absolute right-0 top-0 hidden group-hover:flex px-2 gap-[7px] xl:gap-[10px] bg-white">
                              <RefreshIcon
                                stroke="#3B82F6"
                                strokeWidth="1"
                                className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none cursor-pointer"
                                onClick={() =>
                                  regenerateBrainStormConcept(
                                    draftConcept,
                                    index
                                  )
                                }
                              />
                              <FavoriteIcon
                                className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none cursor-pointer"
                                fill={isLiked(index) ? "#7cfc00" : "#3B82F6"}
                                onClick={() => toggleLike(index)}
                              />
                              <FavoriteIcon
                                className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none cursor-pointer rotate-180"
                                fill={isUnliked(index) ? "#7cfc00" : "#3B82F6"}
                                onClick={() => toggleUnlike(index)}
                              />
                            </div>
                          </div>
                        )
                      )}
                    </div>
                    <div className="flex gap-[6px] xl:gap-2 pl-[25px] xl:pl-[34px]">
                      <button
                        className={`py-[6px] xl:py-2 px-[18px] xl:px-6 h-[30px] xl:h-10 rounded-full border border-solid border-[#71717A] items-center gap-[6px] xl:gap-2 ${
                          brainstormData.draft_concepts?.length > 5
                            ? "hidden"
                            : "flex"
                        }`}
                        onClick={() => generateMoreBrainStormConcepts()}
                        disabled={generateMoreConceptLoading}
                      >
                        <div
                          className={`text-xs xl:text-base font-medium ${
                            generateMoreConceptLoading
                              ? "text-[#A1A1AA]"
                              : "text-[#71717A]"
                          }`}
                          data-event-name="Brainstorm: More Detail Regenerated"
                        >
                          Generate 5 more concepts
                        </div>
                        {generateMoreConceptLoading && (
                          <BiLoaderAlt className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-[#A1A1AA] animate-spin" />
                        )}
                      </button>
                      <button
                        className={`py-[6px] xl:py-2 px-[18px] xl:px-6 h-[30px] xl:h-10 rounded-full border border-solid border-[#71717A] items-center gap-[6px] xl:gap-2 ${
                          regenerateConceptLoading === "done"
                            ? "hidden"
                            : "flex"
                        }`}
                        onClick={() => regenerateBrainStormConcepts()}
                        disabled={regenerateConceptLoading === "true"}
                      >
                        <div
                          className={`text-xs xl:text-base font-medium ${
                            regenerateConceptLoading === "true"
                              ? "text-[#A1A1AA]"
                              : "text-[#71717A]"
                          }`}
                          data-event-name="Brainstorm: More Detail Regenerated"
                        >
                          Regenerate with more detail
                        </div>
                        {regenerateConceptLoading === "true" && (
                          <BiLoaderAlt className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-[#A1A1AA] animate-spin" />
                        )}
                      </button>
                    </div>
                    <div className="flex items-center justify-between pl-[25px] xl:pl-[34px]">
                      <div className="text-xs xl:text-base font-medium text-[#A1A1AA]">
                        *Select responses to refine into new concepts
                      </div>
                      <button
                        className={`py-3 px-[18px] xl:px-6 h-9 xl:h-12 rounded-full flex items-center gap-[6px] xl:gap-2 ${
                          selectedDraftConceptIndexes.length === 0 ||
                          showLoading
                            ? "bg-[#E4E4E7]"
                            : "bg-[#3B82F6]"
                        }`}
                        disabled={
                          selectedDraftConceptIndexes.length === 0 ||
                          showLoading
                        }
                        onClick={() => saveBrainStormConcepts()}
                        data-event-name="Brainstorm: Generate Concept Clicked"
                      >
                        <div
                          className={`text-xs xl:text-base font-bold ${
                            selectedDraftConceptIndexes.length === 0 ||
                            showLoading
                              ? "text-[#A1A1AA]"
                              : "text-white"
                          }`}
                          data-event-name="Brainstorm: Generate Concept Clicked"
                        >
                          Generate Concept
                        </div>
                        {showLoading && (
                          <BiLoaderAlt className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-[#A1A1AA] animate-spin" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {brainstormStatus.status === "generated_concepts" && (
                <div className="">
                  <div className="flex flex-col gap-[18px] xl:gap-6">
                    <div className="">
                      <div className="text-[15px] xl:text-xl font-bold text-[#3B82F6]">
                        Hey <>{user?.unsafeMetadata?.user_name ?? ''}</>
                      </div>
                      <div className="text-2xl font-medium text-[#71717A]">
                        Here are some of your concepts
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-3 xl:gap-4">
                      {brainstormData.generated_concepts.map(
                        (generatedConcept: any, index: number) => (
                          <div
                            className="border border-solid border-[#D4D4D8] rounded-lg overflow-hidden p-[10px] xl:p-[14px]"
                            key={index}
                          >
                            <div className="flex flex-col gap-3 xl:gap-4 h-full">
                              <div className="w-9 xl:w-12 h-9 xl:h-12 flex items-center justify-center bg-[#F4F4F5] rounded-[4px]">
                                <span className="text-[15px] xl:text-xl text-[#3B82F6] font-medium">
                                  #{index + 1}
                                </span>
                              </div>
                              <div className="flex-1">
                                <div className="text-[13px] xl:text-lg font-bold text-[#18181B] mb-2">
                                  {generatedConcept.concept_title}
                                </div>
                                <div className="text-[10px] xl:text-sm font-medium text-[#71717A]">
                                  {generatedConcept.concept_description}
                                </div>
                              </div>
                              <div className="flex justify-end">
                                <button
                                  className="p-0 bg-transparent border-none outline-none flex items-center gap-[6px] xl:gap-2"
                                  onClick={() =>
                                    handleEditConcept(generatedConcept.id)
                                  }
                                >
                                  <span
                                    className="text-[10px] xl:text-sm font-bold text-[#3B82F6]"
                                    data-event-name="Brainstorm: Edit Concept Clicked"
                                  >
                                    Edit Concept
                                  </span>
                                  <GrLinkNext className="text-[10px] xl:text-sm font-bold text-[#3B82F6]" />
                                </button>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              )}
              {brainstormStatus.status === "source_material" && (
                <div className="w-full">
                    {brainstormStatus.fromSource ? (
                      <div
                        className="w-full overflow-x-auto overflow-y-auto mb-3 xl:mb-4"
                        id="brainstorm-source-material-container"
                      >
                        <SourceMaterialFolder
                          nestedStructure={sourceMaterialStructureReady}
                          selectedPaths={brainstormData.source_list || []}
                          onSelectedPathsChange={()=>{}}
                        />
                      </div>
                    ) : (
                      <div className="text-[15px] xl:text-xl font-medium text-[#71717A]">
                        <CommonMarkdown>{brainstormData.context}</CommonMarkdown>
                      </div>
                    )}
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {brainstormData && (
        <BrainStormEditModal
          open={editModalOpen}
          brainstorm={brainstormData}
          handleClose={() => setEditModalOpen(false)}
          onEdit={() => getBrainstormDetail()}
        />
      )}
    </div>
  );
};

export default BrainStormModal;
