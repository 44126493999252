// src/LoadingBar.tsx
import { type FC } from "react";
import { LinearProgress, Box } from '@mui/material';

const PageLoading: FC = () => {
    return (
        <Box sx={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 1300 }}>
            <LinearProgress />
        </Box>
    );
};

export default PageLoading;
