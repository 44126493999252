import type {Dispatch, FC, ReactNode, SetStateAction} from "react";
import {createContext, useContext, useMemo, useState} from "react";
import axios from "axios";
import {useUserContext} from "./UserContext";
import {useLocation} from "react-router";
import { OrganizationMembershipResource, UserResource } from "@clerk/types";

interface RagContextType {
  sourceList: Source[];
  selectedFiles: any;
  selectedBrainstormFiles: any;
  selectedCreateConceptFiles: any;
  conceptModalVisible: boolean | undefined;
  selectedConceptId: string | undefined | null;
  selectedBrainstormId: string | undefined | null;
  fileManagerModalVisible: boolean;
  conceptManagerModalVisible: boolean;
  setting: any;
  conceptAddFolderVisible: boolean;
  conceptAddFolderSelection: string;
  conceptFolders: any[];
  brainstormLists: any[];
  socket: any;
  socketId: any;
  chatTopicId: any;
  pageName: any;
  retriever: any;
  subRetriever: any;
  selectedUploadFiles: any;
  activityLogs: any;
  activityLogsModalVisible: any;
  shareFeedbackVisible: boolean;
  openSettingsModal: boolean;
  conceptAddType: string;
  brainStormModalVisible: boolean | undefined;
  startBrainstormDrawerVisible: boolean | undefined;
  createConceptDrawerVisible: boolean | undefined;
  openedDocument: string;
  selectedFolderID: string;
  selectedSubFolderID: string;
  pageNumber: number;
  documentUpdated: boolean;
  folderStructure: any | null;
  setFolderStructure: Dispatch<SetStateAction<any | null>>;
  setOpenedDocument: (arg: any) => void;
  setSelectedFolderID: (arg: any) => void;
  setSelectedSubFolderID: (arg: any) => void;
  setPageNumber: (arg: any) => void;
  setDocumentUpdated: (arg: any) => void;
  setChatTopicId: (arg: any) => void;
  setRetriever: (arg: any) => void;
  setSubRetriever: (arg: any) => void;
  setSourceList: (arg: Source[]) => void;
  setSelectedFiles: (arg: any) => void;
  toggleSelectFile: (filePath: string, topFolderId: string) => void;
  toggleSelectSource: (filePath: string) => void;
  isSelectedSource: (filePath: string) => boolean;
  isSelectedFile: (filePath: string) => boolean;
  setSelectedBrainstormFiles: (arg: any) => void;
  isSelectedBrainstormFile: (filePath: any) => boolean;
  setSelectedCreateConceptFiles: (arg: any) => void;
  openFileManagerModal: () => void;
  closeFileManagerModal: () => void;
  openConceptManagerModal: () => void;
  closeConceptManagerModal: () => void;
  getSettingData: () => void;
  openConceptAddFolderModal: (type: string, selection?: string) => void;
  closeConceptAddFolderModal: () => void;
  getConceptFolders: () => void;
  getBrainstormList: () => void;
  setSocketInterface: (socket: any, socketId: any) => void;
  fetchSources: () => void;
  setSelectedUploadFiles: (arg: any) => void;
  openActivityLogsModal: (log: any) => void;
  closeActivityLogsModal: () => void;
  openShareFeedbackModal: (arg: any) => void;
  closeShareFeedbackModal: () => void;
  handleSettingModalOpen: () => void;
  handleCloseSettingsModal: () => void;
  openStartBrainstormDrawer: () => void;
  closeStartBrainstormDrawer: () => void;
  openCreateConceptDrawer: () => void;
  closeCreateConceptDrawer: () => void;
  buildNestedStructureFromSourceList: (items: any[]) => any;
  createFolderArray: (teamRoot: any, userRoot: any) => any;
  traverseAndFilter: (obj: any, searchTerm: string) => void;
}
interface Source {
  filePath: string;
  uri: string;
  type: string;
  requiresOCR: boolean;
}

interface Sources {
  orgs?: {
    sources: any[];
  };
  user?: {
    sources: any[];
  };
}

const RagContext = createContext<RagContextType | undefined>(undefined);

export const RagContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const location = useLocation();

  // Get search params from the URL

  const [sourceList, setSourceList] =  useState<Source[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [folderStructure, setFolderStructure] = useState<any | null>(null);
  const [selectedBrainstormFiles, setSelectedBrainstormFiles] = useState<any>(
    []
  );
  const [selectedCreateConceptFiles, setSelectedCreateConceptFiles] =
    useState<any>([]);
  const [lastSelectedTopFolderId, setLastSelectedTopFolderId] =
    useState<any>("");
  // FIXME: Unused
  // const [
  //   lastSelectedBrainstormTopFolderId,
  //   setLastSelectedBrainstormTopFolderId,
  // ] = useState<any>("");
  // const [
  //   lastSelectedCreateConceptTopFolderId,
  //   setLastSelectedCreateConceptTopFolderId,
  // ] = useState<any>("");
  const [socket, setSocket] = useState(null);
  const [pageName, setPageName] = useState<string>("Chat");
  const [socketId, setSocketId] = useState("");
  const [chatTopicId, setChatTopicId] = useState("");
  const [retriever, setRetriever] = useState<
    "selected_source" | "tavily" | "google_patents" | "serpapi" | "chat_with_llm"
  >("chat_with_llm");
  const [subRetriever, setSubRetriever] = useState<
    "google_scholar" | "arxiv" | "sciencedirect" | ""
  >("");
  const conceptModalVisible = useMemo(() => {
    if (location) {
      const searchParams = new URLSearchParams(location?.search);
      return !!(searchParams.get("render_type") === "modal" &&
        searchParams.get("render_modal") === "concept_modal" &&
        searchParams.get("concept_id"));
    }
  }, [location]);
  const brainStormModalVisible = useMemo(() => {
    if (location) {
      const searchParams = new URLSearchParams(location?.search);
      return !!(searchParams.get("render_type") === "modal" &&
        searchParams.get("render_modal") === "brainstorm_modal" &&
        searchParams.get("brainstorm_id"));
    }
  }, [location]);

  const selectedConceptId = useMemo(() => {
    if (location) {
      const searchParams = new URLSearchParams(location?.search);
      if (
        searchParams.get("render_type") === "modal" &&
        searchParams.get("render_modal") === "concept_modal" &&
        searchParams.get("concept_id")
      ) {
        return searchParams.get("concept_id");
      } else {
        return "";
      }
    }
  }, [location]);

  const selectedBrainstormId = useMemo(() => {
    if (location) {
      const searchParams = new URLSearchParams(location?.search);
      if (
        searchParams.get("render_type") === "modal" &&
        searchParams.get("render_modal") === "brainstorm_modal" &&
        searchParams.get("brainstorm_id")
      ) {
        return searchParams.get("brainstorm_id");
      } else {
        return "";
      }
    }
  }, [location]);

  const [setting, setSetting] = useState<any>({});
  const { user } = useUserContext();
  // FIXME: Unused
  // const userOrgId = useMemo(() => {
  //   return user?.organizationMemberships[0]?.organization.id;
  // }, [user]);
  const [fileManagerModalVisible, setFileManagerModalVisible] =
    useState<any>(false);
  const [conceptManagerModalVisible, setConceptManagerModalVisible] =
    useState<any>(false);
  const [conceptAddFolderVisible, setConceptAddFolderVisible] =
    useState<any>(false);
  const [conceptAddFolderSelection, setConceptAddFolderSelection] =
    useState<any>("");
  const [conceptFolders, setConceptFolders] = useState<any[]>([]);
  const [brainstormLists, setBrainstormLists] = useState<any[]>([]);
  const [selectedUploadFiles, setSelectedUploadFiles] = useState<any>([]);

  const [activityLogsModalVisible, setActivityLogsModalVisible] =
    useState(false);
  const [activityLogs, setActivityLogs] = useState<any>([]);
  const [shareFeedbackVisible, setShareFeedbackVisible] = useState<any>(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [conceptAddType, setConceptAddType] = useState<string>("");
  const [startBrainstormDrawerVisible, setStartBrainstormDrawerVisible] =
    useState<any>(false);
  const [openedDocument, setOpenedDocument] = useState("");
  const [selectedFolderID, setSelectedFolderID] = useState("");
  const [selectedSubFolderID, setSelectedSubFolderID] = useState("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [documentUpdated, setDocumentUpdated] = useState(false);

  const [createConceptDrawerVisible, setCreateConceptDrawerVisible] =
    useState<any>(false);

  function mergeSources(sources: Sources): Source[] {
    if (!sources) return [];
    // Safely extract org sources
    const orgFiles: Source[] =
      sources.orgs?.sources?.map((file) => ({
        filePath: file.file_path,
        uri: file.uri,
        type: file.type,
        requiresOCR: file.requiresOCR,
      })) || [];

    // Safely extract user sources
    const userFiles: Source[] =
      sources.user?.sources?.map((file) => ({
        filePath: file.file_path,
        uri: file.uri,
        type: file.type,
        requiresOCR: file.requiresOCR,
      })) || [];

    // Merge and return
    return [...orgFiles, ...userFiles];
  }

  const handleSettingModalOpen = () => {
    setOpenSettingsModal(true);
  };

  const handleCloseSettingsModal = () => {
    setOpenSettingsModal(false);
  };
  const setSocketInterface = (socket: any, socketId: any) => {
    setSocket(socket);
    setSocketId(socketId);
  };

  const toggleSelectSubFolder = (
    folder: any,
    folder_id: any,
    selected: boolean,
    selectedFiles: any
  ) => {
    if (folder.id === folder_id) {
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key];
        if (item.isFolder) {
          toggleSelectSubFolder(item, item.id, selected, selectedFiles);
        } else {
          let index = selectedFiles.findIndex((e: any) => e === item.file_path);
          if (selected) {
            selectedFiles.splice(index, 1);
          } else {
            if (index === -1) selectedFiles.push(item.file_path);
          }
        }
      });
    } else {
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key];
        if (item.isFolder) {
          toggleSelectSubFolder(item, folder_id, selected, selectedFiles);
        }
      });
    }
  };

  const toggleSelectFile = (filePath: string, topFolderId: string) => {
    let tempSelectedFiles: any;
    if (lastSelectedTopFolderId !== topFolderId) {
      tempSelectedFiles = [];
    } else {
      tempSelectedFiles = JSON.parse(JSON.stringify(selectedFiles));
    }
    let index = tempSelectedFiles.findIndex((e: any) => e === filePath);
    if (index !== -1) {
      tempSelectedFiles.splice(index, 1);
    } else {
      tempSelectedFiles.push(filePath);
    }
    setLastSelectedTopFolderId(topFolderId);
    setSelectedFiles(tempSelectedFiles);
  };
  const toggleSelectSource = (filePath: string) => {
    // Check if the file is already selected
    const isAlreadySelected = selectedFiles.includes(filePath);

    let updatedSelectedFiles;
    if (isAlreadySelected) {
      // Remove the file if it's already selected
      updatedSelectedFiles = selectedFiles.filter((file: string) => file !== filePath);
    } else {
      // Add the file if it's not selected
      updatedSelectedFiles = [...selectedFiles, filePath];
    }

    // Update state
    setSelectedFiles(updatedSelectedFiles);
  };
  const isSelectedSource = (filePath: string) => {
    return selectedFiles.includes(filePath);

  }

  const isSelectedSubFolder = (folder: any, folder_id: any): boolean => {
    if (folder.id === folder_id) {
      let isSelected = true;
      if (Object.keys(folder.children).length === 0) return false;
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key];
        if (
          !(
            (item.isFolder && isSelectedSubFolder(item, item.id)) ||
            (!item.isFolder && isSelectedFile(item.file_path))
          )
        ) {
          isSelected = false;
        }
      });
      return isSelected;
    } else {
      let isSelected = false;
      if (Object.keys(folder.children).length === 0) return false;
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key];
        if (item.isFolder && isSelectedSubFolder(item, folder_id)) {
          isSelected = true;
        }
      });
      return isSelected;
    }
  };

  const isSelectedFile = (filePath: string): boolean => {
    return !!selectedFiles.find((e: any) => e === filePath);
  };

  const getTeamIds = async (user: UserResource): Promise<string[]> => {
    // Collect all teams where the user is an admin
    const adminTeams = user.organizationMemberships.filter((org: OrganizationMembershipResource) =>
      org.organization.name.includes("admins")
    );

    if (adminTeams.length > 0) {
      try {
        // If the user is an admin of any team, fetch all organizations
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}api/organizations`);
        return data.data.map((org: OrganizationMembershipResource) => org.id);
      } catch (error) {
        console.error("Error fetching organizations:", error);
        throw new Error("Failed to fetch team IDs for admin.");
      }
    } else {
      // If not an admin, return the IDs of all their teams
      return user.organizationMemberships.map((org) => org.organization.id);
    }
  };


  const getBrainstormList = async () => {
    if (!user) return;
    try {
      const team_ids = await getTeamIds(user);

      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/getBrainStormList",
        {
          team_ids: team_ids,
        }
      );
      if (response?.status === 200) {
        if (response?.data) {
          setBrainstormLists(response.data.data);
        } else {
          setBrainstormLists([]);
        }
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const isSelectedBrainstormFile = (filePath: string): boolean => {
    return !!selectedBrainstormFiles.find((e: any) => e === filePath);
  };

  const getSettingData = async () => {
    try {
      const result = await axios.post(
        process.env.REACT_APP_API_URL + "api/getSetting",
        {
          team_id: user?.organizationMemberships[0]?.organization.id,
        }
      );
      setSetting(result.data.data);
    } catch (error) {}
  };

  const openFileManagerModal = () => {
    setFileManagerModalVisible(true);
  };

  const closeFileManagerModal = () => {
    setFileManagerModalVisible(false);
  };

  const openConceptManagerModal = () => {
    setConceptManagerModalVisible(true);
  };

  const closeConceptManagerModal = () => {
    setConceptManagerModalVisible(false);
  };

  const openConceptAddFolderModal = (type: string, selection?: string) => {
    setConceptAddFolderVisible(true);
    setConceptAddType(type);
    if (type === "selected_source") {
      setConceptAddFolderSelection("");
    } else if (type === "create_concept_drawer") {
      setConceptAddFolderSelection("");
    } else if (type === "window_selection") {
      setConceptAddFolderSelection(selection);
    }
  };

  const closeConceptAddFolderModal = () => {
    setConceptAddFolderVisible(false);
    setConceptAddFolderSelection("");
  };

  const openStartBrainstormDrawer = () => {
    setStartBrainstormDrawerVisible(true);
  };

  const closeStartBrainstormDrawer = () => {
    setStartBrainstormDrawerVisible(false);
  };

  const openCreateConceptDrawer = () => {
    setCreateConceptDrawerVisible(true);
  };

  const closeCreateConceptDrawer = () => {
    setCreateConceptDrawerVisible(false);
  };

  const getConceptFolders = async () => {
    if (!user) return;
    try {
      const team_ids = await getTeamIds(user);

      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/getConceptFolders",
        {
          team_ids: team_ids
        }
      );
      if (response?.status === 200) {
        if (response?.data) {
          setConceptFolders(response.data.data);
        } else {
          setConceptFolders([]);
        }
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSources = async () => {
    if (!user) return;
    try {
      const team_ids = await getTeamIds(user);

      const queryParams = new URLSearchParams({
        team_ids: team_ids.join(','),
        // FIXME: Verify this is correct
        user_id: user.id,
      });

      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}api/folders?${queryParams.toString()}`
      );

      const { data } = result.data;

      const mergedFiles = mergeSources(data);
      setSourceList(mergedFiles);
      setFolderStructure({
        orgs: data.orgs?.structure || {},
        user: data.user?.structure || {},
      });
    } catch (error) {
      console.error(error);
    }
  };

  const openActivityLogsModal = (logs: any) => {
    setActivityLogs(logs);
    setActivityLogsModalVisible(true);
  };
  const closeActivityLogsModal = () => {
    setActivityLogsModalVisible(false);
  };

  const openShareFeedbackModal = (page: string) => {
    setPageName(page);
    setShareFeedbackVisible(true);
  };
  const closeShareFeedbackModal = () => {
    setShareFeedbackVisible(false);
  };

/**
 * buildNestedStructureFromSourceList:
 * Takes an array of items (each containing a .filePath and possibly .requiresOCR, etc.),
 * and converts them into two nested objects: `teamRoot` (for orgs) and `userRoot` (for users).
 * 
 * Usage: 
 *   const { teamRoot, userRoot } = buildNestedStructureFromSourceList(sourceList);
 *   // Then you might place these under "Team Documents" and "My Documents" 
 *   // in a top-level object for folder/file rendering.
 */
  const buildNestedStructureFromSourceList = (items: any[])=> {
  // We'll maintain two separate top-level objects:
  //   teamRoot => for file paths under "/.../orgs/{org_id}/..."
  //   userRoot => for file paths under "/.../users/{user_id}/..."
    const teamRoot: any = {};
    const userRoot: any = {};
  // Iterate over each item in the sourceList
    items.forEach((item: any) => {
    // If item is invalid or has no filePath, skip it
    if (!item?.filePath) return;

    // Remove any leading slash if present (e.g. "/development/..." becomes "development/...")
      let path = item.filePath;
      if (path.startsWith("/")) {
        path = path.slice(1);
      }

    // Split the path by "/" to get segments
    // e.g. "development/orgs/org_ABC/folder1/file.pdf" => ["development", "orgs", "org_ABC", "folder1", "file.pdf"]
      const parts = path.split("/");

    // The second segment (parts[1]) should be either "orgs" or "users"
    // We'll use that to decide if this file/folder belongs to "teamRoot" or "userRoot".
      const docType = parts[1]; // "orgs" or "users"

    // We skip the first three parts (applicationName, "orgs"/"users", orgOrUserId).
    // "meaningfulParts" are what's left => the real folders and filename
    // e.g. ["folder1", "file.pdf"]
      const meaningfulParts = parts.slice(3);

      // If docType is "users", we store into userRoot; otherwise store into teamRoot
      let currentRoot = docType === "users" ? userRoot : teamRoot;
  
    // We'll walk through `meaningfulParts`, creating nested objects for each subfolder,
    // until we reach the last part (which might be a file).
      let cursor = currentRoot;
      for (let i = 0; i < meaningfulParts.length; i++) {
        const seg = meaningfulParts[i];
        if (!seg) continue;
        const isLast = i === meaningfulParts.length - 1;
        // Mark as a file => set .__file to true so we know it's a file
        // and store the originalFilePath plus any other metadata.
        if (item.type === "file" && isLast) {
          cursor[seg] = {
            __file: true,
            originalFilePath: item.filePath,
            requiresOCR: item.requiresOCR || false,
          };
        } else {
          // It's a folder => create sub-object if it doesn't exist yet
          if (!cursor[seg]) {
            cursor[seg] = {};
          }
          // Move deeper into the nested object
          cursor = cursor[seg];
        }
      }
    });
    // Return both objects so the caller can assign them to e.g. "Team Documents" and "My Documents"
    return { teamRoot, userRoot };
  }

  const createFolderArray = (teamRoot: any, userRoot: any)=> {
    return [
      {
        structure: {
          "Team Documents": teamRoot,
        },
      },
      {
        structure: {
          "My Documents": userRoot,
        },
      },
    ];
  }

  const traverseAndFilter = (obj: any, searchTerm: string)=>{
    const keysToRemove: string[] = [];
    const lowerTerm = searchTerm.toLowerCase();
  
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      const keyMatches = key.toLowerCase().includes(lowerTerm);
  
      // If it’s a file node
      if (val && val.__file) {
        if (!keyMatches) {
          keysToRemove.push(key);
        }
      } else if (typeof val === "object") {
        // folder => recurse
        traverseAndFilter(val, searchTerm);
        const hasChildren = Object.keys(val).length > 0;
        if (!keyMatches && !hasChildren) {
          keysToRemove.push(key);
        }
      }
    });
  
    keysToRemove.forEach((k) => delete obj[k]);
  }

  const contextValue = {
    sourceList,
    selectedFiles,
    selectedBrainstormFiles,
    selectedCreateConceptFiles,
    conceptModalVisible,
    brainStormModalVisible,
    startBrainstormDrawerVisible,
    createConceptDrawerVisible,
    selectedConceptId,
    selectedBrainstormId,
    fileManagerModalVisible,
    conceptManagerModalVisible,
    setting,
    conceptAddFolderVisible,
    conceptAddFolderSelection,
    conceptFolders,
    brainstormLists,
    socket,
    socketId,
    chatTopicId,
    pageName,
    retriever,
    subRetriever,
    selectedUploadFiles,
    activityLogs,
    activityLogsModalVisible,
    shareFeedbackVisible,
    openSettingsModal,
    conceptAddType,
    openedDocument,
    selectedFolderID,
    selectedSubFolderID,
    pageNumber,
    documentUpdated,
    folderStructure,
    setFolderStructure,
    setOpenedDocument,
    setSelectedFolderID,
    setSelectedSubFolderID,
    setPageNumber,
    setDocumentUpdated,
    setChatTopicId,
    setRetriever,
    setSubRetriever,
    setSourceList,
    setSelectedFiles,
    toggleSelectFile,
    toggleSelectSource,
    isSelectedSource,
    isSelectedFile,
    setSelectedBrainstormFiles,
    isSelectedBrainstormFile,
    setSelectedCreateConceptFiles,
    getSettingData,
    openFileManagerModal,
    closeFileManagerModal,
    openConceptManagerModal,
    closeConceptManagerModal,
    openConceptAddFolderModal,
    closeConceptAddFolderModal,
    getConceptFolders,
    getBrainstormList,
    setSocketInterface,
    fetchSources,
    setSelectedUploadFiles,
    openActivityLogsModal,
    closeActivityLogsModal,
    openShareFeedbackModal,
    closeShareFeedbackModal,
    handleSettingModalOpen,
    handleCloseSettingsModal,
    openStartBrainstormDrawer,
    closeStartBrainstormDrawer,
    openCreateConceptDrawer,
    closeCreateConceptDrawer,
    buildNestedStructureFromSourceList,
    createFolderArray,
    traverseAndFilter,
  };

  return (
    <RagContext.Provider value={contextValue}>{children}</RagContext.Provider>
  );
};

export const useRagContext = () => {
  const context = useContext(RagContext);
  if (!context) {
    throw new Error("useUserContext must be used within a RagContextProvider");
  }
  return context;
};