import { type FC, useEffect, useState } from "react";
import { Drawer } from "@mui/material";
import { BiLoaderAlt } from "react-icons/bi";
import axios from "axios";
import moment from "moment";
import { useHistory } from "react-router";

import { useRagContext } from "../../../contexts/RagContext";
import { useUserContext } from "../../../contexts/UserContext";
import { useLayoutContext } from "../../../contexts/LayoutContext";

import BrainstormFolder from "./BrainstormFolder";
import searchIcon from "../../../assets/svg/search.svg";

const StartBrainstormDrawer: FC = () => {
  const [searchText, setSearchText] = useState("");
  const [unfilteredFolders, setUnfilteredFolders] = useState<any[]>([]);
  const [shownFolders, setShownFolders] = useState<any[]>([]);
  const [showLoading, setShowLoading] = useState(false);

  const {
    sourceList,
    selectedBrainstormFiles,
    setSelectedBrainstormFiles,
    socketId,
    startBrainstormDrawerVisible,
    closeStartBrainstormDrawer,
    getBrainstormList,
    buildNestedStructureFromSourceList,
    createFolderArray,
    traverseAndFilter,
  } = useRagContext();

  const { user } = useUserContext();
  const { deviceType } = useLayoutContext();
  const history = useHistory();

  /**
   * 1) Build the nested structure from sourceList
   */
  useEffect(() => {
    if (!sourceList || !Array.isArray(sourceList) || sourceList.length === 0) {
      setUnfilteredFolders([]);
      setShownFolders([]);
      return;
    }

    const { teamRoot, userRoot } = buildNestedStructureFromSourceList(sourceList);
    const newFolders = createFolderArray(teamRoot, userRoot);
    setUnfilteredFolders(newFolders);
    setShownFolders(newFolders);
  }, [sourceList]);

  /**
   * 2) Searching => filter the nested structure
   */
  useEffect(() => {
    if (!searchText.trim()) {
      setShownFolders(unfilteredFolders);
      return;
    }
    const term = searchText.toLowerCase();
    const tmp = JSON.parse(JSON.stringify(unfilteredFolders));


    tmp.forEach((f: any) => {
      if (f.structure) {
        traverseAndFilter(f.structure, term);
      }
    });

    const filtered = tmp.filter((f: any) => Object.keys(f.structure).length > 0);
    setShownFolders(filtered);
  }, [searchText, unfilteredFolders]);

  /**
   * 3) Start Brainstorm => call “getHMWQuestions”
   */
  const handleGetHMWQuestions = async () => {
    if (!user) return;
    const user_id = user.id;
    const team_id = user.organizationMemberships?.[0]?.organization.id;

    try {
      setShowLoading(true);
      const payload = {
        team_id,
        source_list: selectedBrainstormFiles, // we now store filePaths in selectedBrainstormFiles
        name: "Brainstorm " + moment().format("YYYY-MM-DD h:mm a"),
        context: "",
        fromSource: true,
        socketId,
      };
      const res = await axios.post(
        process.env.REACT_APP_API_URL + "api/getHMWQuestions",
        payload
      );
      setShowLoading(false);

      // Navigate to the Brainstorm modal
      const searchParams = new URLSearchParams();
      searchParams.set("render_type", "modal");
      searchParams.set("render_modal", "brainstorm_modal");
      searchParams.set("brainstorm_id", res.data.brainstorm_id);

      history.push({
        pathname: "/concept",
        search: `?${searchParams.toString()}`,
      });
      getBrainstormList();
      closeStartBrainstormDrawer();
    } catch (err) {
      console.error(err);
      setShowLoading(false);
    }
  };

  /**
   * 4) If drawer closes => reset selected
   */
  useEffect(() => {
    if (!startBrainstormDrawerVisible) {
      setSelectedBrainstormFiles([]);
    }
  }, [startBrainstormDrawerVisible, setSelectedBrainstormFiles]);

  return (
    <Drawer
      open={startBrainstormDrawerVisible}
      onClose={() => closeStartBrainstormDrawer()}
      anchor="right"
    >
      <div className="w-[352px] xl:w-[470px]">
        {/* Header */}
        <div className="w-full h-[34px] xl:h-[46px] px-[15px] xl:px-5 py-[7px] xl:py-[10px] border-b border-solid border-[#D4D4D8]">
          <span className="text-[13px] xl:text-lg leading-[19px] xl:leading-[26px] font-medium text-[#09090B]">
            Select Source
          </span>
        </div>

        {/* Search Box */}
        <div className="px-[15px] xl:px-5 py-3 xl:py-4">
          <div className="relative w-full h-[28px] xl:h-[38px] rounded-[4px] border border-solid border-[#D4D4D8] bg-white px-[7px] xl:px-[10px] py-[6px] xl:py-2 flex gap-[9px] xl:gap-3 items-center mb-3 xl:mb-4">
            <img
              src={searchIcon}
              alt="Search"
              className="w-[13px] xl:w-[18px] h-[13px] xl:h-[18px] flex-none"
            />
            <input
              type="text"
              className="flex-1 text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-[#A1A1AA] font-medium outline-none"
              placeholder="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>

          {/* Body: map over shownFolders => each folder object has .structure */}
          <div
            className="w-full overflow-x-auto overflow-y-auto mb-3 xl:mb-4"
            style={{
              height: selectedBrainstormFiles.length
                ? `calc(100vh - ${199 * (deviceType === "laptop" ? 0.75 : 1)}px)`
                : `calc(100vh - ${150 * (deviceType === "laptop" ? 0.75 : 1)}px)`,
            }}
            id="start-brainstorm-folder-container"
          >
            <div className="w-fit min-w-full">
              {shownFolders.map((folderObj: any, idx: number) => (
                <BrainstormFolder key={idx} folder={folderObj} />
              ))}
            </div>
          </div>

          {/* Footer Button => Start Brainstorm */}
          {selectedBrainstormFiles.length > 0 && (
            <button
              className={`w-full h-9 xl:h-12 rounded-xl bg-[#3B82F6] outline-none flex items-center justify-center gap-[6px] xl:gap-2 ${
                showLoading ? "opacity-60" : ""
              }`}
              onClick={handleGetHMWQuestions}
              disabled={showLoading}
            >
              <span className="text-white text-xs xl:text-base font-bold font-Satoshi">
                Start Brainstorm
              </span>
              {showLoading && (
                <BiLoaderAlt className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-white animate-spin" />
              )}
            </button>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default StartBrainstormDrawer;
