import { type FC } from "react";
import Chat from "./Chat";
import { useLayoutContext } from "../../contexts/LayoutContext";

const AIControl: FC = () => {
  const { chatWidth, deviceType } = useLayoutContext();

  return (
    <div
      id="chat-panel"
      className={`pb-6 xl:pb-8 pt-3 xl:pt-4 px-[18px] xl:px-6 h-full flex flex-col bg-white z-[10]`}
      style={{
        width: `${chatWidth}px`,
        maxWidth: `calc(100vw - ${
          396 * (deviceType === "laptop" ? 0.75 : 1)
        }px)`,
      }}
    >
      <Chat />
    </div>
  );
};

export default AIControl;
