import { type FC } from "react";
import { Link } from "react-router-dom";
import HomeIcon from "../Components/icons/HomeIcon";
import ResearchIcon from "../Components/icons/ResearchIcon";
import BagIcon from "../Components/icons/BagIcon";
import TelegramIcon from "../Components/icons/TelegramIcon";
import { useRagContext } from "../contexts/RagContext";

interface SidebarProps {
  page: string;
}

type SidebarItemType = {
  page: string;
  path: string;
  title: string;
  Icon: FC<any>;
};

const Sidebar: FC<SidebarProps> = ({ page }) => {
  const { openShareFeedbackModal } = useRagContext();
  const sidebarItems: SidebarItemType[] = [
    {
      page: "home",
      path: "/",
      title: "Home",
      Icon: HomeIcon,
    },
    {
      page: "research",
      path: "/research",
      title: "Research",
      Icon: ResearchIcon,
    },
    {
      page: "concept",
      path: "/concept",
      title: "Concept",
      Icon: BagIcon,
    },
  ];

  const SidebarItem = (item: SidebarItemType) => {
    return (
      <Link
        to={item.path}
        onClick={(e) => {
          if (item.page === page) {
            e.preventDefault();
          }
        }}
      >
        <div
          className={`w-9 xl:w-12 h-9 xl:h-12 flex items-center justify-center rounded-xl cursor-pointer ${
            item.page === page ? "border border-[#E4E4E7] shadow-md" : ""
          }`}
          title={item.title}
          data-event-name={`Research: Left Nav ${item.title} Tab`}
        >
          <item.Icon className="flex-none w-[18px] xl:w-6 h-[18px] xl:h-6"></item.Icon>
        </div>
        <div className="text-[9px] xl:text-xs font-medium text-[#09090B] text-center w-full mt-[3px] xl:mt-1">
          {item.title}
        </div>
      </Link>
    );
  };

  return (
    <div className="w-[60px] xl:w-20 flex-none border-r border-solid border-[#D4D4D8] bg-[#FCFCFC] flex flex-col justify-between">
      <div className="flex flex-col items-center gap-[7px] xl:gap-[10px] py-3 xl:py-4">
        {sidebarItems.map((item, index) => (
          <SidebarItem key={index} {...item} />
        ))}
      </div>
      <div className="xl:gap-[10px] py-3 xl:py-4 flex flex-col items-center">
        <div
          onClick={() => {
            openShareFeedbackModal(
              sidebarItems.find((item) => item.page === page)?.title
            );
          }}
          className="w-fit"
          data-event-name="Feedback: 'Share Feedback' Clicked"
        >
          <div className="w-9 xl:w-12 h-9 xl:h-12 flex items-center justify-center rounded-xl cursor-pointer">
            <TelegramIcon className="flex-none w-[18px] xl:w-6 h-[18px] xl:h-6"></TelegramIcon>
          </div>
          <div className="text-[9px] xl:text-xs font-medium text-[#09090B] text-center w-full mt-[3px] xl:mt-1">
            Feedback
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
