import { type FC, useEffect, useState } from "react";
import { HiOutlineFolderMinus, HiOutlineFolderPlus } from "react-icons/hi2";
import BpCheckbox from "../../common/BPCheckbox";

/**
 * Props:
 * - nestedStructure: the big object with top-level keys (e.g. "Team Documents", "My Documents", possibly "Other Files").
 * - selectedPaths: array of full file paths that are currently selected (“checked”).
 * - onSelectedPathsChange: callback to update that array in the parent.
 */
interface SourceMaterialFolderProps {
  nestedStructure: any;            // The big object from buildNestedStructure
  selectedPaths: string[];         // Full original filePaths that are selected
  onSelectedPathsChange: (paths: string[]) => void;
}

/** 
 * For rendering, we define an internal TreeNode shape for each folder/file.
 */
interface TreeNode {
  name: string;               // e.g. "test case 2" or "Amazon DynamoDB.pdf"
  isFolder: boolean;
  children: TreeNode[];
  fullFilePath?: string;      // only for files
  requiresOCR?: boolean;
}

const SourceMaterialFolder: FC<SourceMaterialFolderProps> = ({
  nestedStructure,
  selectedPaths,
  onSelectedPathsChange,

}) => {
  const [tree, setTree] = useState<TreeNode[]>([]);
  const [expandedPaths, setExpandedPaths] = useState<Set<string>>(new Set());

  // 1) Build the top-level tree from nestedStructure on mount or changes
  useEffect(() => {
    const topLevel = buildTree(nestedStructure, "");
    setTree(topLevel);
  }, [nestedStructure]);

  // 2) Auto-expand folders that contain any selected file
  useEffect(() => {
    if (tree.length === 0) return;
    const newExpanded = new Set<string>();
    // For each top-level node, expand if it has children that are selected
    for (const node of tree) {
      autoExpandForSelected(node, "", selectedPaths, newExpanded);
    }
    setExpandedPaths(newExpanded);
  }, [tree, selectedPaths]);

  /**
   * Recursively build a TreeNode array from the nested object.
   * If `value.__file === true`, it’s a file => store `fullFilePath`.
   * Otherwise, it’s a folder => recurse over its keys.
   */
  function buildTree(obj: any, currentPath: string): TreeNode[] {
    if (!obj || typeof obj !== "object") return [];

    return Object.keys(obj).map((key) => {
      const value = obj[key];
      const isFile = value && value.__file === true;
      const newPath = currentPath ? `${currentPath}/${key}` : key;

      if (isFile) {
        // It's a file => store full path
        return {
          name: key,
          isFolder: false,
          children: [],
          fullFilePath: value.originalFilePath,
          requiresOCR: value.requiresOCR,
        };
      } else {
        // It's a folder => build children
        return {
          name: key,
          isFolder: true,
          children: buildTree(value, newPath),
        };
      }
    });
  }

  /**
   * autoExpandForSelected => if this folder contains any selected file, we expand it.
   */
  function autoExpandForSelected(
    node: TreeNode,
    parentPath: string,
    selected: string[],
    expandedSet: Set<string>
  ): boolean {
    const nodePath = parentPath ? `${parentPath}/${node.name}` : node.name;
    if (!node.isFolder) {
      // file => return whether it is selected
      return node.fullFilePath ? selected.includes(node.fullFilePath) : false;
    }

    let anyChildSelected = false;
    for (const child of node.children) {
      if (autoExpandForSelected(child, nodePath, selected, expandedSet)) {
        anyChildSelected = true;
      }
    }
    if (anyChildSelected) {
      expandedSet.add(nodePath);
    }
    return anyChildSelected;
  }

  /**
   * Gather all file paths under a folder node (recursively).
   */
  function gatherAllFiles(node: TreeNode): string[] {
    if (!node.isFolder) {
      return node.fullFilePath ? [node.fullFilePath] : [];
    }
    let results: string[] = [];
    node.children.forEach((child) => {
      results = results.concat(gatherAllFiles(child));
    });
    return results;
  }

  // Toggle folder expand/collapse
  function toggleExpand(folderPath: string) {
    const copy = new Set(expandedPaths);
    if (copy.has(folderPath)) {
      copy.delete(folderPath);
    } else {
      copy.add(folderPath);
    }
    setExpandedPaths(copy);
  }

  /**
   * Toggle entire folder => select/unselect all child files
   */
  function handleFolderCheckbox(node: TreeNode, nodePath: string) {
    const allFiles = gatherAllFiles(node);
    const allSelected = allFiles.every((f) => selectedPaths.includes(f));
    let updated = [...selectedPaths];
    if (allSelected) {
      // unselect
      updated = updated.filter((f) => !allFiles.includes(f));
    } else {
      // select
      for (const filePath of allFiles) {
        if (!updated.includes(filePath)) {
          updated.push(filePath);
        }
      }
    }
    onSelectedPathsChange(updated);
  }

  /**
   * Toggle a single file
   */
  function handleFileCheckbox(filePath: string) {
    let updated = [...selectedPaths];
    const idx = updated.indexOf(filePath);
    if (idx >= 0) {
      updated.splice(idx, 1); // unselect
    } else {
      updated.push(filePath); // select
    }
    onSelectedPathsChange(updated);
  }

  /**
   * Are all children (files) in this folder selected?
   */
  function areAllChildrenSelected(node: TreeNode): boolean {
    const allFiles = gatherAllFiles(node);
    return (
      allFiles.length > 0 && allFiles.every((fp) => selectedPaths.includes(fp))
    );
  }

  /**
   * Render recursively
   */
  function renderTree(nodes: TreeNode[], parentPath: string): JSX.Element[] {
    return nodes.map((node) => {
      const nodePath = parentPath ? `${parentPath}/${node.name}` : node.name;
            // Check if this node or its children are under “Other Files”
            const isInOtherFiles =
            nodePath === "Other Files" || nodePath.startsWith("Other Files/");
      if (node.isFolder) {
        const expanded = expandedPaths.has(nodePath);
        const allSelected = areAllChildrenSelected(node);

        return (
          <div key={nodePath}>
            <div
              className={`w-full px-[9px] xl:px-3 py-[7px] xl:py-[10px] flex items-start gap-[9px] xl:gap-3 rounded-[10px] group sidebar-folder text-[#09090B]`}
            >
              {/* Expand/collapse button */}
              <button onClick={() => toggleExpand(nodePath)}>
                {expanded ? (
                  <HiOutlineFolderMinus className="text-lg xl:text-2xl" />
                ) : (
                  <HiOutlineFolderPlus className="text-lg xl:text-2xl" />
                )}
              </button>

              <p
                className="text-xs xl:text-base font-medium folder-name text-break"
                onClick={() => toggleExpand(nodePath)}
              >
                {node.name}
              </p>

              {/* Folder checkbox */}
              <button
                className={`outline-none flex-none ml-auto flex h-[18px] xl:h-6 items-center ${
                  allSelected ? "visible" : "invisible group-hover:visible"
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!isInOtherFiles) {
                    // Only run folder toggle if not "Other Files"
                  handleFolderCheckbox(node, nodePath);
                  }
                }}
              >
                {isInOtherFiles ? (
                  // No toggle callback => can't change
                  <BpCheckbox
                    size="small"
                    className="w-[10px] xl:w-[14px] h-[10px] xl:h-[14px]"
                    checked={true}
                    disabled={true}
                  />
                ) : (
                  <BpCheckbox
                  size="small"
                  className="w-[10px] xl:w-[14px] h-[10px] xl:h-[14px]"
                    checked={areAllChildrenSelected(node)}
                    onChange={(e) => {
                      if (!isInOtherFiles) {
                        // Only run folder toggle if not "Other Files"
                        handleFolderCheckbox(node, nodePath);
                      }
                    }
                  }
                  />
                )}
              </button>
            </div>

            {/* If expanded => show children */}
            {expanded && node.children.length > 0 && (
              <div className="relative pl-[27px] xl:pl-9 flex-col sidebar-folder-items flex">
                {renderTree(node.children, nodePath)}
              </div>
            )}
          </div>
        );
      } else {
        // File node
        const isSelected = node.fullFilePath
          ? selectedPaths.includes(node.fullFilePath)
          : false;

        return (
          <div
            key={nodePath}
            className={`w-full sidebar-folder-item px-[9px] xl:px-3 py-[7px] xl:py-[10px] rounded-xl flex items-start gap-[3px] xl:gap-1 justify-between cursor-pointer group ${
              isSelected
                ? "bg-[#E4E4E7] text-[#18181B]"
                : "bg-transparent text-[#52525B]"
            }`}
          >
            <p
              className={`text-xs xl:text-base document-title ${
                isSelected ? "font-medium" : "font-normal"
              }`}
            >
              {node.name}
            </p>

            {/* File checkbox */}
            {!isInOtherFiles ? (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  if (node.fullFilePath) handleFileCheckbox(node.fullFilePath);
                }}
              >
                <BpCheckbox
                  size="small"
                  className="w-[10px] xl:w-[14px] h-[10px] xl:h-[14px]"
                  checked={isSelected} 
                  />
              </button>
            ) : (
              // Example: show a disabled checkbox or no checkbox at all
              <BpCheckbox
                size="small"
                className="w-[10px] xl:w-[14px] h-[10px] xl:h-[14px]"
                checked={isSelected} 
                disabled={true} 
              />
            )}
          </div>
        );
      }
    });
  }

  return <div>{renderTree(tree, "")}</div>;
};

export default SourceMaterialFolder;
