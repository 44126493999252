import { Skeleton } from "@mui/material";
import { type FC, useCallback, useEffect, useRef, useState } from "react";
import ArrowRightIcon2 from "../../icons/ArrowRightIcon2";
import axios from "axios";
import { useUserContext } from "../../../contexts/UserContext";
import * as amplitude from "@amplitude/analytics-browser";
import {
  PlateEditor,
  PlateEditorRef,
} from "../../../Components/editor/plate-editor";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { useLayoutContext } from "../../../contexts/LayoutContext";
// import { toast } from "react-toastify";

interface ElevatorPitchContentProps {
  conceptData: any;
  visible?: boolean;
  getConceptDetail: () => void;
}

type ChatAction = "short" | "elaborate" | "regenerate" | "user_input";

const CanvasKey = "Elevator";
const CanvasTitle = "Elevator Pitch";

const ElevatorPitchContent: FC<ElevatorPitchContentProps> = ({
  conceptData,
  visible,
  getConceptDetail,
}) => {
  const { user } = useUserContext();
  const { deviceType } = useLayoutContext();
  const [userInput, setUserInput] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [currentVersion, setCurrentVersion] = useState(0);
  const [chatStatus, setChatStatus] = useState({
    status: "initial",
    response: "",
  });
  const plateEditorRef = useRef<PlateEditorRef>(null);
  const [hasStartedTyping, setHasStartedTyping] = useState(false);
  const [markLoading, setMarkLoading] = useState(false);

  const handleGenerateResponse = useCallback(
    async (action: ChatAction) => {
      if (showLoading || saving) return;
      if (plateEditorRef.current) {
        const editor = plateEditorRef.current.editor;
        const setValue = plateEditorRef.current.setValue;

        let params: any = {
          team_id: user?.organizationMemberships[0]?.organization.id,
          source_list: conceptData.source_list,
          pinecone_id: conceptData.pinecone_id,
          action: action,
        };

        if (typeof chatStatus.response === "string") {
          params.previous_response = chatStatus.response;
        } else {
          params.previous_response = editor.api.markdown.serialize(
            chatStatus.response
          );
        }

        if (conceptData.context && conceptData.context !== "") {
          params.context = conceptData.context;
        }

        if (action === "user_input") {
          if (userInput === "") return;
          params.user_input = userInput;
          setShowLoading(true);
          let response = await axios.post(
            `${process.env.REACT_APP_API_URL}api/getElevatorPitch`,
            params
          );
          setChatStatus({
            status: "updated",
            response: response.data.response,
          });
          setShowLoading(false);
          setUserInput("");
          onGenerateComplete();
          setValue(editor.api.markdown.deserialize(response.data.response));
        } else {
          setShowLoading(true);
          let response = await axios.post(
            `${process.env.REACT_APP_API_URL}api/getElevatorPitch`,
            params
          );
          setChatStatus({
            status: "updated",
            response: response.data.response,
          });
          setShowLoading(false);
          setUserInput("");
          onGenerateComplete();
          setValue(editor.api.markdown.deserialize(response.data.response));
        }
      }
    },
    [userInput, chatStatus, showLoading, saving, conceptData]
  );

  const handleSave = useCallback(async () => {
    if (saving) return;
    if (!plateEditorRef.current) return;
    const editor = plateEditorRef.current.editor;

    let params: any = {
      id: conceptData.id,
      [CanvasKey]: editor.api.markdown.serialize(chatStatus.response),
      plate: chatStatus.response,
    };

    try {
      setSaving(true);
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/editConcept",
        params
      );
      setSaving(false);
      setChatStatus({
        status: "initial",
        response: chatStatus.response,
      });
      if (response?.status === 200) {
        getConceptDetail();
      }
    } catch (error) {
      console.log(error);
    }
  }, [conceptData, chatStatus, saving]);

  const handleCancel = useCallback(() => {
    if (conceptData[CanvasKey].versions[currentVersion]?.plate)
      setChatStatus({
        status: "initial",
        response: conceptData[CanvasKey].versions[currentVersion]?.plate,
      });
    else
      setChatStatus({
        status: "initial",
        response: conceptData[CanvasKey].versions[currentVersion]?.value,
      });
  }, [conceptData, currentVersion]);

  const onGenerateComplete = useCallback(() => {
    if (!visible) {
      // toast.success("Elevator Pitch generate completed!", {
      //   position: "top-right",
      //   autoClose: 3000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: false,
      //   progress: 0,
      //   toastId: "my_toast",
      // });
    }
  }, [visible]);

  useEffect(() => {
    if (
      conceptData[CanvasKey] &&
      conceptData[CanvasKey].status === "generated"
    ) {
      let index = conceptData[CanvasKey].versions.findIndex(
        (e: any) => e.id === conceptData[CanvasKey].primaryVersionId
      );
      setCurrentVersion(index);
      if (conceptData[CanvasKey].versions[index].plate)
        setChatStatus((prev) => ({
          status: prev.status,
          response: conceptData[CanvasKey].versions[index].plate,
        }));
      else
        setChatStatus((prev) => ({
          status: prev.status,
          response: conceptData[CanvasKey].versions[index].value,
        }));
    }
  }, [conceptData[CanvasKey]]);

  const handleChangeVersion = (version: number) => {
    setCurrentVersion(version);
    if (conceptData[CanvasKey].versions[version].plate)
      setChatStatus((prev) => ({
        status: prev.status,
        response: conceptData[CanvasKey].versions[version].plate,
      }));
    else
      setChatStatus((prev) => ({
        status: prev.status,
        response: conceptData[CanvasKey].versions[version].value,
      }));
  };

  const handleSetPrimaryVersion = async () => {
    if (markLoading) return;
    if (
      conceptData[CanvasKey].versions[currentVersion].id !==
      conceptData[CanvasKey].primaryVersionId
    ) {
      let params: any = {
        id: conceptData.id,
        canvas: CanvasKey,
        primaryVersionId: conceptData[CanvasKey].versions[currentVersion].id,
      };
      try {
        setMarkLoading(true);
        let response = await axios.post(
          process.env.REACT_APP_API_URL + "api/primaryConceptCanvas",
          params
        );
        if (response?.status === 200) {
          getConceptDetail();
          amplitude.track(`Concept: ${CanvasTitle} Version Selected`);
        }
        setMarkLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  if (!visible) return null;

  return (
    <div className="flex flex-col gap-[9px] xl:gap-3 h-full">
      <div className="relative bg-white p-[15px] xl:p-5 rounded-[4px] flex-1">
        <div className="text-[#27272A] text-xs xl:text-base font-medium mb-[6px] xl:mb-2">
          {CanvasTitle}
        </div>
        <div
          className="text-xs xl:text-base font-normal text-[#000000cb] overflow-x-hidden overflow-y-auto cursor-pointer"
          style={{
            maxHeight:
              showLoading || chatStatus.status === "updated"
                ? conceptData[CanvasKey].versions.length > 1
                  ? `calc(100vh - ${
                      660 * (deviceType === "laptop" ? 0.75 : 1)
                    }px)`
                  : `calc(100vh - ${
                      640 * (deviceType === "laptop" ? 0.75 : 1)
                    }px)`
                : conceptData[CanvasKey].versions.length > 1
                ? `calc(100vh - ${
                    393 * (deviceType === "laptop" ? 0.75 : 1)
                  }px)`
                : `calc(100vh - ${
                    373 * (deviceType === "laptop" ? 0.75 : 1)
                  }px)`,
          }}
          onClick={() => {
            if (chatStatus.status !== "updated")
              if (conceptData[CanvasKey].versions[currentVersion]?.plate)
                setChatStatus({
                  status: "updated",
                  response:
                    conceptData[CanvasKey].versions[currentVersion]?.plate,
                });
              else
                setChatStatus({
                  status: "updated",
                  response:
                    conceptData[CanvasKey].versions[currentVersion]?.value,
                });
          }}
        >
          <PlateEditor
            initialMarkdown={
              conceptData[CanvasKey].versions[currentVersion]?.plate
                ? conceptData[CanvasKey].versions[currentVersion]?.plate
                : conceptData[CanvasKey].versions[currentVersion]?.value
            }
            readOnly={true}
          />
        </div>
        {conceptData[CanvasKey].versions.length > 1 && (
          <div className="absolute bottom-[6px] xl:bottom-2 left-0 flex w-full items-center justify-between px-[27px] xl:px-9">
            <div className="w-[87px] xl:w-[116px]"></div>
            <div className="flex items-center gap-[9px] xl:gap-3 bg-white rounded-md">
              <MdOutlineKeyboardArrowLeft
                className={`w-6 xl:w-8 h-6 xl:h-8 cursor-pointer select-none ${
                  currentVersion === 0 ? "text-[#A1A1AA]" : "text-[#09090B]"
                }`}
                onClick={() => {
                  if (currentVersion > 0)
                    handleChangeVersion(currentVersion - 1);
                }}
                data-event-name={`Concept: ${CanvasTitle} Version Changed`}
              />
              <span className="text-xs xl:text-base font-bold text-[#09090B] tracking-[3px] xl:tracking-[4px]">
                {`${currentVersion + 1} / ${
                  conceptData[CanvasKey].versions.length
                }`}
              </span>
              <MdOutlineKeyboardArrowRight
                className={`w-6 xl:w-8 h-6 xl:h-8 cursor-pointer select-none ${
                  currentVersion === conceptData[CanvasKey].versions.length - 1
                    ? "text-[#A1A1AA]"
                    : "text-[#09090B]"
                }`}
                onClick={() => {
                  if (
                    currentVersion <
                    conceptData[CanvasKey].versions.length - 1
                  )
                    handleChangeVersion(currentVersion + 1);
                }}
                data-event-name={`Concept: ${CanvasTitle} Version Changed`}
              />
            </div>
            <div className="flex items-center gap-[6px] xl:gap-2">
              <input
                type="checkbox"
                className="w-[15px] xl:w-5 h-[15px] xl:h-5 flex-none mt-[1px] xl:mt-[2px]"
                checked={
                  conceptData[CanvasKey].versions[currentVersion].id ===
                  conceptData[CanvasKey].primaryVersionId
                }
                onChange={(e) => {
                  e.preventDefault();
                  handleSetPrimaryVersion();
                }}
              />
              <span
                className="text-[10px] xl:text-sm font-normal text-[#3F3F46]"
                onClick={() => {
                  handleSetPrimaryVersion();
                }}
              >
                Set as Primary
              </span>
            </div>
          </div>
        )}
      </div>
      <div
        className="bg-white p-[15px] xl:p-5 rounded-[4px] flex-none"
        hidden={!showLoading}
      >
        <div className="text-xs xl:text-base font-medium text-[#71717A] mb-[7px] xl:mb-[10px]">
          Editing <span className="text-[#27272A]">{CanvasTitle}</span>
        </div>
        <div className="rounded-[4px] border-[#D4D4D8] border">
          <div className="">
            <div className="flex flex-col gap-[6px] xl:gap-2 h-[199px] xl:h-[266px] p-[9px] xl:p-3">
              <Skeleton
                height={10}
                sx={{
                  width: "70%",
                  transform: "scale(1)",
                  overflow: "hidden",
                }}
              />
              <Skeleton
                height={10}
                sx={{
                  width: "100%",
                  transform: "scale(1)",
                  overflow: "hidden",
                }}
              />
              <Skeleton
                height={10}
                sx={{
                  width: "45%",
                  transform: "scale(1)",
                  overflow: "hidden",
                }}
              />
              <Skeleton
                height={10}
                sx={{
                  width: "100%",
                  transform: "scale(1)",
                  overflow: "hidden",
                }}
              />
              <Skeleton
                height={10}
                sx={{
                  width: "70%",
                  transform: "scale(1)",
                  overflow: "hidden",
                }}
              />
              <Skeleton
                height={10}
                sx={{
                  width: "67%",
                  transform: "scale(1)",
                  overflow: "hidden",
                }}
              />
            </div>
            <div className="h-[39px] xl:h-[52px] w-full py-[10px] xl:py-[14px] px-3 xl:px-4 gap-[6px] xl:gap-2 flex items-center border-t border-[#E4E4E7] ">
              <input
                type="text"
                className="outline-none border-none bg-transparent text-[10px] xl:text-sm text-[#71717A] flex-1"
                placeholder="Chat to refine the text above"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    handleGenerateResponse("user_input");
                  }
                }}
              />
              <button
                className="w-[21px] xl:w-7 h-[21px] xl:h-7 flex-none bg-[#D4D4D8] rounded-full flex items-center justify-center"
                onClick={() => handleGenerateResponse("user_input")}
              >
                <ArrowRightIcon2
                  stroke="#ffffff"
                  className="w-3 xl:w-4 h-3 xl:h-4"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg-white p-[15px] xl:p-5 rounded-[4px] flex-none"
        hidden={showLoading || chatStatus.status !== "initial"}
      >
        <div className="flex gap-[6px] xl:gap-2 mb-[6px] xl:mb-2">
          <div
            className="w-fit h-[21px] xl:h-7 px-[9px] xl:px-3 flex items-center rounded-full border border-[#E4E4E7] text-[#52525B] text-[9px] xl:text-xs cursor-pointer"
            onClick={() => handleGenerateResponse("short")}
            data-event-name={`Concepts: ${CanvasTitle} Shortened`}
          >
            Shorten
          </div>
          <div
            className="w-fit h-[21px] xl:h-7 px-[9px] xl:px-3 flex items-center rounded-full border border-[#E4E4E7] text-[#52525B] text-[9px] xl:text-xs cursor-pointer"
            onClick={() => handleGenerateResponse("elaborate")}
            data-event-name={`Concepts: ${CanvasTitle} Elaborated`}
          >
            Elaborate
          </div>
          <div
            className="w-fit h-[21px] xl:h-7 px-[9px] xl:px-3 flex items-center rounded-full border border-[#E4E4E7] text-[#52525B] text-[9px] xl:text-xs cursor-pointer"
            onClick={() => handleGenerateResponse("regenerate")}
            data-event-name={`Concepts: ${CanvasTitle}  Regenerated`}
          >
            Regenerate
          </div>
        </div>
        <div className="h-[39px] xl:h-[52px] w-full bg-[#FCFCFC] rounded-[4px] border border-[#E4E4E7] py-[10px] xl:py-[14px] px-3 xl:px-4 gap-[6px] xl:gap-2 flex items-center">
          <input
            type="text"
            className="outline-none border-none bg-transparent text-[10px] xl:text-sm text-[#71717A] flex-1"
            placeholder="Chat to refine the text above"
            value={userInput}
            onChange={(e) => {
              setUserInput(e.target.value);
              // Track typing event in Amplitude
              if (!hasStartedTyping) {
                amplitude.track(`Concepts: ${CanvasTitle} Edited`, {
                  content: e.target.value,
                  length: e.target.value.length,
                });
                setHasStartedTyping(true); // Prevent tracking again until submission
              }
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleGenerateResponse("user_input");
              }
            }}
          />
          <button
            className="w-[21px] xl:w-7 h-[21px] xl:h-7 flex-none bg-[#3B82F6] rounded-full flex items-center justify-center"
            onClick={() => handleGenerateResponse("user_input")}
          >
            <ArrowRightIcon2
              stroke="#ffffff"
              className="w-3 xl:w-4 h-3 xl:h-4"
            />
          </button>
        </div>
      </div>
      <div
        className="bg-white p-[15px] xl:p-5 rounded-[4px] flex-none"
        hidden={showLoading || chatStatus.status === "initial"}
      >
        <div className="text-xs xl:text-base font-medium text-[#71717A] mb-[7px] xl:mb-[10px]">
          Editing <span className="text-[#27272A]">{CanvasTitle}</span>
        </div>
        <div className="rounded-[4px] border-[#3B82F6] border">
          <div className="">
            <div className="h-[174px] xl:h-[232px]">
              <PlateEditor
                initialMarkdown={
                  conceptData[CanvasKey].versions[currentVersion]?.plate
                    ? conceptData[CanvasKey].versions[currentVersion]?.plate
                    : conceptData[CanvasKey].versions[currentVersion]?.value
                }
                value={chatStatus.response}
                onChange={(value) => {
                  setChatStatus((prev) => ({
                    ...prev,
                    response: value,
                  }));
                }}
                ref={plateEditorRef}
              />
            </div>
            <div className="flex gap-3 xl:gap-4 mb-[6px] xl:mb-2 px-[9px] xl:px-3 items-center">
              {chatStatus.response === conceptData[CanvasKey] ||
              chatStatus.response === "" ? (
                <span className="text-[#3b83f680] text-[10px] xl:text-sm font-bold cursor-pointer underline">
                  Save
                </span>
              ) : (
                <span
                  className="text-[#3B82F6] text-[10px] xl:text-sm font-bold cursor-pointer underline"
                  onClick={() => handleSave()}
                >
                  {saving ? "Saving..." : "Save"}
                </span>
              )}
              <span
                className="text-[#3B82F6] text-[10px] xl:text-sm font-bold cursor-pointer underline"
                onClick={() => handleCancel()}
              >
                Cancel
              </span>
            </div>
            <div className="h-[39px] xl:h-[52px] w-full border-t border-[#E4E4E7] py-[10px] xl:py-[14px] px-3 xl:px-4 gap-[6px] xl:gap-2 flex items-center">
              <input
                type="text"
                className="outline-none border-none bg-transparent text-[10px] xl:text-sm text-[#71717A] flex-1"
                placeholder="Chat to refine the text above"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    handleGenerateResponse("user_input");
                  }
                }}
              />
              <button
                className="w-[21px] xl:w-7 h-[21px] xl:h-7 flex-none bg-[#3B82F6] rounded-full flex items-center justify-center"
                onClick={() => handleGenerateResponse("user_input")}
              >
                <ArrowRightIcon2
                  stroke="#ffffff"
                  className="w-3 xl:w-4 h-3 xl:h-4"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElevatorPitchContent;
