import { type FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {toast, type ToastOptions} from "react-toastify";
import { RouteComponentProps } from "react-router";
import { useLocation } from "react-router-dom";
import logoImage from "../../assets/auth/logo.svg";
import bgImage from "../../assets/auth/set-password-bg-image.png";
import circleImage from "../../assets/auth/register-circle-bg.svg";
import { useSignUp } from "@clerk/clerk-react";
import { useHistory } from "react-router";
import { ErrorMessage } from "@hookform/error-message";

type SomeComponentProps = RouteComponentProps;

const AcceptInvitation: FC<SomeComponentProps> = (): JSX.Element => {
  const { signUp } = useSignUp();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const location = useLocation();
  const [clerkStatus, setClerkStatus] = useState("");
  const [clerkTicket, setClerkTicket] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const status = searchParams.get("__clerk_status");
    const ticket = searchParams.get("__clerk_ticket");
    if (status && ticket) {
      setClerkStatus(status);
      setClerkTicket(ticket);
    }
  }, [location.search]);

  const generateSafePassword = (length = 12) => {
    const lowerCase = "abcdefghijklmnopqrstuvwxyz";
    const upperCase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbers = "0123456789";
    const symbols = "!@#$%^&*()_+<>?";
    const charset = lowerCase + upperCase + numbers + symbols;

    const getRandomChar = () => {
      let randomValue;
      do {
        randomValue = window.crypto.getRandomValues(new Uint32Array(1))[0];
      } while (randomValue >= Math.floor(0xFFFFFFFF / charset.length) * charset.length);
      return charset[randomValue % charset.length];
    };
    return Array.from({ length }, getRandomChar).join("");
  };

  const setUserNameOnly = async (data: any) => {
    const toastOptions:  ToastOptions = {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      toastId: "my_toast",
    };
    try {
      const generatedPassword = generateSafePassword();



      if (clerkStatus === "sign_up" && clerkTicket !== "") {
        // FIXME: Do anything with the response? Removed unused variable for now
        await signUp?.create({
          strategy: "ticket",
          ticket: clerkTicket,
          password: generatedPassword,
          unsafeMetadata: {
            user_name: data.user_name,
          },
        });

        toast.success("You accepted the invitation successfully", toastOptions);
        setTimeout(() => {
          history.push("/");
        }, 1000);
      } else {
        toast.error("Failed to accept the invitation", toastOptions);
      }
    } catch (error) {
      toast.error("Failed to accept the invitation", toastOptions);
    }
  };

  return (
    <div className="w-screen h-screen font-Satoshi">
      <div className="flex h-full">
        <div className="h-full flex flex-col justify-between py-[72px] px-24 bg-white w-[700px]">
          <div className="flex items-center gap-2">
            <img
              src={logoImage}
              alt="Logo"
              className="w-12 h-12 rounded-full flex-none"
            />
            <div className="">
              <div className="text-[22px] leading-none tracking-[-3%] text-[#27272A] font-Host-Grotesk font-medium">
                Growth
                <br />
                Signals
              </div>
            </div>
          </div>
          <form autoComplete="off" onSubmit={handleSubmit(setUserNameOnly)}>
            <div className="flex flex-col gap-4">
              <div>
                <div className="text-heading-4 leading-heading-4 text-[#232323] font-bold mb-2">
                  Accept your invitation
                </div>
                <div className="text-xl text-[#969696] font-normal">
                  Enter your username
                </div>
              </div>
              <div className="w-full">
                <label
                  htmlFor="user_name"
                  className="text-base text-[#27272A] mb-1 ml-1"
                >
                  Full name
                </label>
                <input
                  type="text"
                  id="user_name"
                  className="w-full h-12 border border-[#A1A1AA] rounded-lg px-4 py-3 text-sm leading-6 text-[#71717A] outline-none"
                  placeholder="Enter your full name"
                  {...register("user_name", {
                    required: "User name is required!",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="user_name"
                  render={({ messages }) =>
                    messages &&
                    Object.entries(messages).map(([type, message]) => (
                      <div
                        className="text-red-600 text-xs leading-5 no-underline mt-1"
                        key={type}
                      >
                        {message}
                      </div>
                    ))
                  }
                ></ErrorMessage>
              </div>
              <button className="w-full h-12 bg-[#3B82F6] rounded-lg py-3 px-6 flex justify-center items-center">
                <span className="text-base font-bold text-white">Submit</span>
              </button>
            </div>
          </form>
          <div className="h-12"></div>
        </div>
        <div className="flex-1 h-full relative bg-[#DBEAFE] overflow-hidden">
          <img
            src={bgImage}
            alt="background"
            className="absolute bottom-0 left-0 h-[64.45%] z-20"
          />
          <img
            src={circleImage}
            alt="circle"
            className="absolute bottom-0 right-0 h-1/2 z-10"
          />
        </div>
      </div>
    </div>
  );
};
export default AcceptInvitation;
