import { type FC } from "react";

const QuestionIcon: FC<any> = (props: any) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      stroke="#09090B"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={12}
        cy={12}
        r={9}
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.8125 9.75224C9.9887 9.25136 10.3365 8.829 10.7942 8.55998C11.252 8.29095 11.7902 8.1926 12.3135 8.28237C12.8368 8.37213 13.3115 8.64421 13.6534 9.05041C13.9954 9.45661 14.1825 9.97072 14.1817 10.5017C14.1817 12.0006 11.9334 12.75 11.9334 12.75M11.9624 15.75H11.9724"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default QuestionIcon
