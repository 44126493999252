import { type FC, useEffect, useState } from "react";
import { Drawer } from "@mui/material";

import { useRagContext } from "../../../contexts/RagContext";
import { useLayoutContext } from "../../../contexts/LayoutContext";
import searchIcon from "../../../assets/svg/search.svg";
import CreateConceptFolder from "./CreateConceptFolder";

/**
 * Splits file paths into two separate nested objects:
 *  1) teamRoot => for "/.../orgs/<orgId>/..."
 *  2) userRoot => for "/.../users/<userId>/..."
 * Each file node has { __file: true, originalFilePath: "...", requiresOCR: ... } so we can handle toggles properly.
 */

const CreateConceptDrawer: FC = () => {
  const [searchText, setSearchText] = useState("");
  const [unfilteredFolders, setUnfilteredFolders] = useState<any[]>([]);
  const [shownFolders, setShownFolders] = useState<any[]>([]);

  const {
    sourceList,
    createConceptDrawerVisible,
    selectedCreateConceptFiles,
    setSelectedCreateConceptFiles,
    closeCreateConceptDrawer,
    openConceptAddFolderModal,
    buildNestedStructureFromSourceList,
    createFolderArray,
    traverseAndFilter
  } = useRagContext();

  const { deviceType } = useLayoutContext();

  // 1) Build the nested structure once sourceList arrives
  useEffect(() => {
    if (!sourceList || !Array.isArray(sourceList) || sourceList.length === 0) {
      setUnfilteredFolders([]);
      setShownFolders([]);
      return;
    }

    const { teamRoot, userRoot } = buildNestedStructureFromSourceList(sourceList);
    // Wrap them in the final array
    const newFolders = createFolderArray(teamRoot, userRoot);

    setUnfilteredFolders(newFolders);
    setShownFolders(newFolders);
  }, [sourceList]);

  // 2) Filtering logic
  useEffect(() => {
    if (!searchText.trim()) {
      setShownFolders(unfilteredFolders);
      return;
    }
    const term = searchText.toLowerCase();
    const tempList = JSON.parse(JSON.stringify(unfilteredFolders));

    tempList.forEach((f: any) => {
      if (f.structure) {
        traverseAndFilter(f.structure, term);
      }
    });

    const filtered = tempList.filter(
      (f: any) => Object.keys(f.structure).length > 0
    );
    setShownFolders(filtered);
  }, [searchText, unfilteredFolders]);

  // Clear selection on close
  useEffect(() => {
    if (!createConceptDrawerVisible) {
      setSelectedCreateConceptFiles([]);
    }
  }, [createConceptDrawerVisible, setSelectedCreateConceptFiles]);

  return (
    <Drawer
      open={createConceptDrawerVisible}
      onClose={() => closeCreateConceptDrawer()}
      anchor="right"
    >
      <div className="w-[352px] xl:w-[470px]">
        {/* Header */}
        <div className="w-full h-[34px] xl:h-[46px] px-[15px] xl:px-5 py-[7px] xl:py-[10px] border-b border-solid border-[#D4D4D8]">
          <span className="text-[13px] xl:text-lg leading-[19px] xl:leading-[26px] font-medium text-[#09090B]">
            Select Source
          </span>
        </div>

        <div className="px-[15px] xl:px-5 py-3 xl:py-4">
        {/* Search Box */}
        <div
            className={`relative w-full h-[28px] xl:h-[38px] rounded-[4px] border border-solid border-[#D4D4D8] bg-white px-[7px] xl:px-[10px] py-[6px] xl:py-2 flex gap-[9px] xl:gap-3 items-center mb-3 xl:mb-4`}
          >
            <img
              src={searchIcon}
              alt="Search"
              className="w-[13px] xl:w-[18px] h-[13px] xl:h-[18px] flex-none"
            />
            <input
              type="text"
              className="flex-1 text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-[#A1A1AA] font-medium outline-none"
              placeholder="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>

          {/* Body => map shownFolders => <CreateConceptFolder /> */}
          <div
            className="w-full overflow-x-auto overflow-y-auto mb-3 xl:mb-4"
            style={{
              height: selectedCreateConceptFiles.length
                ? `calc(100vh - ${199 * (deviceType === "laptop" ? 0.75 : 1)}px)`
                : `calc(100vh - ${150 * (deviceType === "laptop" ? 0.75 : 1)}px)`,
            }}
            id="create-concept-folder-container"
          >
            <div className="w-fit min-w-full">
              {shownFolders.map((folderObj: any, idx: number) =>
                folderObj.isFolderDeleted === 1 ? (
                  <div key={idx}></div>
                ) : (
                  <CreateConceptFolder key={idx} folder={folderObj} />
                )
              )}
            </div>
          </div>

          {/* Footer => "Create New Concept" if user has selected files */}
          {selectedCreateConceptFiles.length > 0 && (
            <button
              className="w-full h-9 xl:h-12 rounded-xl bg-[#3B82F6] outline-none flex items-center justify-center gap-[6px] xl:gap-2"
              onClick={() => openConceptAddFolderModal("create_concept_drawer")}
            >
              <span className="text-white text-xs xl:text-base font-bold font-Satoshi">
                Create New Concept
              </span>
            </button>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default CreateConceptDrawer;
