import { type FC, useEffect, useState } from "react";
import { useRagContext } from "../../../contexts/RagContext";
import SourceMaterialFolder from "./SourceMaterialFolder";
import CommonMarkdown from "../../common/CommonMarkdown";
import * as amplitude from "@amplitude/analytics-browser";
import axios from "axios";

/**
 * Props:
 * - conceptData: e.g. { id, source_list (array of full file paths), context, ... }
 * - visible?: whether this component is shown
 * - getConceptDetail: function to refresh concept data from the server after changes
 */
interface SourceMaterialContentProps {
  conceptData: any;             // has .source_list (array of full paths that are “checked”)
  visible?: boolean;
  getConceptDetail: () => void; // refresh concept data after we update
}

const applicationName = process.env.REACT_APP_APPLICATION_NAME as string;

const SourceMaterialContent: FC<SourceMaterialContentProps> = ({
  conceptData,
  visible,
  getConceptDetail,
}) => {
  const { sourceList } = useRagContext(); // array of { filePath, type="file"/"folder", requiresOCR, ... } from context

  // We'll build a top-level nested structure with "Team Documents", "My Documents", and "Other Files"
  const [nestedStructure, setNestedStructure] = useState<any>({});

  // The user's "checked" paths => conceptData.source_list
  const [selectedPaths, setSelectedPaths] = useState<string[]>([]);

  // Whenever conceptData changes, sync the checked paths
  useEffect(() => {
    if (conceptData?.source_list) {
      setSelectedPaths(conceptData.source_list);
    }
  }, [conceptData?.source_list]);
  useEffect(() => {
    if (!sourceList || !Array.isArray(sourceList)) {
      setNestedStructure({});
      return;
    }
    const built = buildNestedStructureFromSourceList(sourceList, selectedPaths);
    setNestedStructure(built);
  }, [sourceList, selectedPaths]);

  /**
   * Helper to handle files that appear in `selectedPaths` but do not exist in `sourceList`.
   * We'll store them under a top-level "Other Files".
   */
  function buildNestedStructureFromSourceList(items: any[], checkedPaths: string[]) {
    const teamRoot: any = {};
    const userRoot: any = {};

    // 1) Build "Team Documents" and "My Documents"
    items.forEach((item: any) => {
      if (!item?.filePath) return;

      let path = item.filePath;
      if (path.startsWith("/")) {
        path = path.slice(1);
      }
      // e.g. "development/orgs/org_XXX/folderA/file.pdf"
      const parts = path.split("/");
      const docType = parts[1]; // "orgs" or "users"
      const relevantParts = parts.slice(3); // skip [0]=applicationName, [1]=orgs/users, [2]=org_XXX

      let currentRoot = docType === "users" ? userRoot : teamRoot;
      let cursor = currentRoot;
      for (let i = 0; i < relevantParts.length; i++) {
        const seg = relevantParts[i];
        if (!seg) continue;
        const isLast = i === relevantParts.length - 1;
        if (isLast && item.type === "file") {
          cursor[seg] = {
            __file: true,
            originalFilePath: item.filePath,
            requiresOCR: item.requiresOCR || false,
          };
        } else {
          if (!cursor[seg]) cursor[seg] = {};
          cursor = cursor[seg];
        }
      }
    });

    // 2) Create a big top-level object with "Team Documents" + "My Documents"
    const root: any = {
      "Team Documents": teamRoot,
      "My Documents": userRoot,
    };

    // 3) Missing paths => "Other Files"
    const missing = findMissingPaths(checkedPaths, items);
    if (missing.length > 0) {
      if (!root["Other Files"]) {
        root["Other Files"] = {};
      }
      const otherRoot = root["Other Files"];

      missing.forEach((filePath) => {
        // parse just the filename from the path
        const lastSlash = filePath.lastIndexOf("/");
        let filename = filePath;
        if (lastSlash >= 0 && lastSlash < filePath.length - 1) {
          filename = filePath.substring(lastSlash + 1);
        }
        // store it
        otherRoot[filename] = {
          __file: true,
          originalFilePath: filePath,
          requiresOCR: false, // or any default
        };
      });
    }

    return root;
  }

  /**
   * findMissingPaths => returns any paths in checkedPaths that do NOT exist in the sourceList
   */
  function findMissingPaths(checkedPaths: string[], allItems: any[]) {
    const missing: string[] = [];
    checkedPaths.forEach((p) => {
      // Is p found in sourceList?
      const found = allItems.some((item) => item.filePath === p);
      if (!found) {
        missing.push(p);
      }
    });
    return missing;
  }

  /**
   * When user toggles, we update conceptData.source_list => call editConcept => refresh
   */
  const handleSelectedPathsChange = async (newPaths: string[]) => {
    setSelectedPaths(newPaths);
    if (!conceptData?.id) return;

    const params = {
      id: conceptData.id,
      source_list: newPaths,
    };
    try {
      amplitude.track("Update concept source material");
      await axios.post(
        process.env.REACT_APP_API_URL + "api/editConcept",
        params
      );
      // Then refresh concept detail
      getConceptDetail();
    } catch (error) {
      console.log(error);
    }
  };

  if (!visible) return null;

  return (
    <div className="w-full p-[15px] xl:p-5">
      {/* The folder list => nested object => pass to SourceMaterialFolder for rendering */}
      <div
        className="w-full overflow-x-auto overflow-y-auto mb-3 xl:mb-4"
        id="concept-source-material-container"
      >
        <SourceMaterialFolder
          nestedStructure={nestedStructure}
          selectedPaths={selectedPaths}
          onSelectedPathsChange={handleSelectedPathsChange}
        />
      </div>

      {conceptData.context && conceptData.context !== "" && (
        <div className="text-[15px] xl:text-xl font-medium text-[#71717A]">
          <CommonMarkdown>{conceptData.context}</CommonMarkdown>
        </div>
      )}
    </div>
  );
};

export default SourceMaterialContent;
