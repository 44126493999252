import { type FC, useEffect, useMemo } from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "./Auth/PrivateRoute";
import PublicRoute from "./Auth/PublicRoute";
import routes from "./routes/routes";
import { useUserContext } from "./contexts/UserContext";
import { useRagContext } from "./contexts/RagContext";
// import { generateUniqueId } from "./utils";
import { io } from "socket.io-client";
import { ToastContainer, Flip } from "react-toastify";
import useDynamicClickTracking from "./analytics/DynamicClickTracking";
import * as amplitude from "@amplitude/analytics-browser";
import { useSessionList } from "@clerk/clerk-react";

const amplitudeKey = process.env.REACT_APP_AMPLITUDE_URL || "";
const DISALLOWED_ORGANIZATIONS =
  process.env.REACT_APP_ANALYTICS_DISABLED_PARTNERS?.split("|");

const App: FC = () => {
  const { user } = useUserContext();
  const { sessions } = useSessionList();
  const sessionId = useMemo(() => {
    if (sessions && sessions.length > 0) {
      return sessions[0].id;
    }
    return null;
  }, [sessions]);
  const organizationId = useMemo(() => {
    if (
      user &&
      user.organizationMemberships &&
      user.organizationMemberships[0].organization.id
    ) {
      return user.organizationMemberships[0].organization.id;
    }
  }, [user]);
  const userId = useMemo(() => {
    if (user && user.id) {
      return user.id;
    }
  }, [user]);

  const organization_name = useMemo(() => {
    if (user && user?.organizationMemberships)
      return user?.organizationMemberships["0"].organization?.name;
  }, [user]);
  useDynamicClickTracking(organization_name ?? '');
  const {
    setting,
    fetchSources,
    getConceptFolders,
    getSettingData,
    setSocketInterface,
  } = useRagContext();
  useEffect(() => {
    if (organizationId && setting?.verified) {
      fetchSources();
      getConceptFolders();
    }
  }, [organizationId, setting]);

  useEffect(() => {
    if (organizationId) {
      getSettingData();
    }
  }, [organizationId]);

  useEffect(() => {
    if (userId && sessionId) {
      const socket = io(`${process.env.REACT_APP_API_URL}`, {
        query: { socketId: sessionId, userId: userId },
      });

      setSocketInterface(socket, sessionId);
    }
  }, [userId, sessionId]);

  useEffect(() => {
    if (
      organization_name &&
      !DISALLOWED_ORGANIZATIONS?.includes(organization_name)
    ) {
      amplitude.init(amplitudeKey, {
        autocapture: {
          elementInteractions: true,
        },
      });
    }
  }, [organization_name]);

  return (
    <>
      <Switch>
        {routes.map((route, index) =>
          route.auth ? (
            <PrivateRoute exact {...route} key={index} />
          ) : (
            <PublicRoute exact {...route} key={index} />
          )
        )}
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        limit={1}
        transition={Flip}
      />
    </>
  );
};

export default App;
