import { type FC, useEffect, useState } from "react";
import { PlateEditor } from "../../../../Components/editor/plate-editor";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { useRagContext } from "../../../../contexts/RagContext";
import * as amplitude from "@amplitude/analytics-browser";
import axios from "axios";
import { useLayoutContext } from "../../../../contexts/LayoutContext";

interface SubCanvasProps {
  data: any;
  subCanvasKey: string;
  selected: boolean;
  getConceptDetail: () => void;
  onSelectCanvas: (value: string) => void;
  onChangeCanvasVersion: (value: string) => void;
  title: string;
  className: string;
}

const SubCanvas: FC<SubCanvasProps> = ({
  title,
  className,
  data,
  subCanvasKey,
  selected,
  getConceptDetail,
  onSelectCanvas,
  onChangeCanvasVersion,
}) => {
  const { selectedConceptId } = useRagContext();
  const { deviceType } = useLayoutContext();
  const [version, setVersion] = useState(0);
  const [markLoading, setMarkLoading] = useState(false);

  useEffect(() => {
    if (data) {
      let index = data.versions.findIndex(
        (e: any) => e.id === data.primaryVersionId
      );
      if (index !== -1) setVersion(index);
    }
  }, [data.versions.length]);

  const handleChangeVersion = (newVersion: number) => {
    setVersion(newVersion);
    if (selected) {
      onChangeCanvasVersion(
        data.versions[newVersion].plate
          ? data.versions[newVersion].plate
          : data.versions[newVersion].value
      );
    }
  };

  const handleSelectCanvas = () => {
    onSelectCanvas(
      data.versions[version].plate
        ? data.versions[version].plate
        : data.versions[version].value
    );
  };

  const handleSetPrimaryVersion = async () => {
    if (markLoading) return;
    if (data.versions[version].id !== data.primaryVersionId) {
      let params: any = {
        id: selectedConceptId,
        canvas: "BusinessModelCanvasJSON",
        subCanvas: subCanvasKey,
        primaryVersionId: data.versions[version].id,
      };
      try {
        setMarkLoading(true);
        let response = await axios.post(
          process.env.REACT_APP_API_URL + "api/primaryConceptCanvas",
          params
        );
        if (response?.status === 200) {
          getConceptDetail();
          amplitude.track(
            `Concept: Business Model Canvas ${title} Version Selected`
          );
        }
        setMarkLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  if (!data || data.versions.length === 0) return null;

  return (
    <div className={className} onClick={() => handleSelectCanvas()}>
      <div className="mb-1 text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] font-bold text-[#27272A]">
        {title}
      </div>
      <div
        className="text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] font-normal text-[#71717A] break-anywhere"
        style={{
          paddingBottom:
            data.versions.length > 1
              ? 20 * (deviceType === "laptop" ? 0.75 : 1)
              : 0,
        }}
      >
        <PlateEditor
          initialMarkdown={
            data.versions[version]?.plate
              ? data.versions[version]?.plate
              : data.versions[version]?.value
          }
          readOnly={true}
        />
      </div>
      {data.versions.length > 1 && (
        <div
          className="absolute bottom-[6px] xl:bottom-2 left-0 w-full flex items-center justify-between gap-[6px] xl:gap-2 px-[9px] xl:px-3 overflow-hidden"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex items-center gap-[9px] xl:gap-3 bg-white rounded-md">
            <MdOutlineKeyboardArrowLeft
              className={`w-6 xl:w-8 h-6 xl:h-8 cursor-pointer select-none ${
                version === 0 ? "text-[#A1A1AA]" : "text-[#09090B]"
              }`}
              onClick={() => {
                if (version > 0) handleChangeVersion(version - 1);
              }}
              data-event-name={`Concept: Business Model Canvas ${title} Version Changed`}
            />
            <span className="text-xs xl:text-base font-bold text-[#09090B] tracking-[3px] xl:tracking-[4px] whitespace-nowrap">
              {`${version + 1} / ${data.versions.length}`}
            </span>
            <MdOutlineKeyboardArrowRight
              className={`w-6 xl:w-8 h-6 xl:h-8 cursor-pointer select-none ${
                version === data.versions.length - 1
                  ? "text-[#A1A1AA]"
                  : "text-[#09090B]"
              }`}
              onClick={() => {
                if (version < data.versions.length - 1)
                  handleChangeVersion(version + 1);
              }}
              data-event-name={`Concept: Business Model Canvas ${title} Version Changed`}
            />
          </div>
          <div className="flex items-center gap-[6px] xl:gap-2">
            <input
              type="checkbox"
              className="w-[15px] xl:w-5 h-[15px] xl:h-5 flex-none mt-[1px] xl:mt-[2px]"
              checked={data.versions[version].id === data.primaryVersionId}
              onChange={(e) => {
                e.preventDefault();
                handleSetPrimaryVersion();
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SubCanvas;
