import { type FC } from "react";
import logo from "../assets/auth/logo.svg";
import ProfileComponent from "../Components/ProfileComponent";
import { useRagContext } from "../contexts/RagContext";
import ShareFeedbackModal from "../Components/ShareFeedbackModal/ShareFeedbackModal";
import SettingsModal from "../Components/SettingsModal";

const Header: FC = () => {
  const { openSettingsModal, handleCloseSettingsModal } = useRagContext();
  return (
    <>
      <div className="w-full h-[52px] xl:h-[70px] flex justify-between items-center px-[18px] border-b border-solid border-[#D4D4D8] flex-none bg-white">
        <div className="flex gap-2 items-center overflow-hidden w-fit">
          <img
            src={logo}
            alt="Logo"
            className="w-8 h-8 xl:w-[42px] xl:h-[42px] rounded-full flex-none"
          />
          <div>
            <div className="text-base xl:text-xl leading-none tracking-[-3%] text-[#27272A] font-medium font-Host-Grotesk">
              Growth
              <br />
              Signals
            </div>
          </div>
        </div>
        <ProfileComponent />
      </div>
      <ShareFeedbackModal />
      <SettingsModal
        open={openSettingsModal}
        handleClose={() => handleCloseSettingsModal()}
      />
    </>
  );
};

export default Header;
