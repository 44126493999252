import { type FC } from "react";

const OverviewIcon: FC<any> = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="black"
      {...props}
    >
      <g clipPath="url(#clip0_1202_22457)">
        <path
          d="M20.4998 12.859C20.4779 12.9096 20.4576 12.9608 20.4333 13.0104C20.3703 13.1443 20.2643 13.2532 20.1322 13.3197C20.0001 13.3863 19.8495 13.4066 19.7044 13.3775C19.5594 13.3484 19.4283 13.2715 19.332 13.1591C19.2358 13.0468 19.18 12.9054 19.1736 12.7576C19.1653 12.4868 19.171 12.2154 19.171 11.9443V6.28283C19.171 5.37075 18.6268 4.82323 17.72 4.82323H6.26769C5.38022 4.82323 4.82938 5.3744 4.82938 6.26221V17.7345C4.82938 18.6123 5.38255 19.1681 6.25638 19.1681C8.48593 19.1681 10.7157 19.1681 12.9457 19.1681C13.4686 19.1681 13.7989 19.6338 13.6066 20.0919C13.5152 20.3091 13.3362 20.4178 13.1293 20.496H5.85855C5.82899 20.4834 5.79838 20.4734 5.76708 20.4661C5.13973 20.3738 4.56628 20.0596 4.15087 19.5805C3.73546 19.1015 3.50565 18.4893 3.50316 17.8552C3.49895 13.9492 3.49895 10.0432 3.50316 6.13714C3.50378 5.43686 3.78224 4.76543 4.27741 4.27026C4.77258 3.77509 5.44401 3.49663 6.14429 3.49601C10.0494 3.49091 13.9546 3.49091 17.8597 3.49601C19.0609 3.49601 20.0967 4.30698 20.408 5.46887C20.4413 5.59594 20.4692 5.72434 20.4998 5.85207V12.859Z"
          fill="inherit"
        />
        <path
          d="M19.7036 20.4908C19.5473 20.3821 19.3753 20.2913 19.2379 20.1612C18.9266 19.8708 18.6302 19.5648 18.3328 19.2598C18.2663 19.1932 18.218 19.1869 18.1332 19.2302C16.2974 20.1775 14.1749 19.159 13.7757 17.1409C13.451 15.5043 14.6562 13.8428 16.3094 13.6482C17.932 13.4563 19.3387 14.4721 19.6524 16.0681C19.7808 16.7201 19.6876 17.4985 19.1903 18.2878C19.3088 18.3916 19.4405 18.4987 19.5626 18.6161C19.7695 18.8157 19.9794 19.0153 20.1703 19.2282C20.2964 19.3695 20.3912 19.5385 20.5003 19.6938V19.96C20.4638 20.0864 20.3959 20.2016 20.3028 20.2947C20.2097 20.3878 20.0945 20.4557 19.968 20.4922L19.7036 20.4908ZM16.7125 18.2655C17.041 18.2662 17.3623 18.1695 17.6358 17.9875C17.9092 17.8055 18.1225 17.5465 18.2487 17.2432C18.3749 16.94 18.4082 16.6061 18.3446 16.2838C18.2809 15.9616 18.123 15.6655 17.891 15.433C17.6589 15.2006 17.3631 15.0422 17.041 14.9779C16.7188 14.9136 16.3849 14.9464 16.0814 15.072C15.7779 15.1976 15.5185 15.4105 15.336 15.6836C15.1535 15.9567 15.0562 16.2779 15.0563 16.6063C15.0579 17.0454 15.2328 17.466 15.543 17.7768C15.8532 18.0875 16.2735 18.2631 16.7125 18.2655Z"
          fill="inherit"
        />
        <path
          d="M13.6627 8.56977C12.7173 8.56977 11.7717 8.57243 10.8263 8.56977C10.3436 8.56778 10.0233 8.12072 10.1813 7.68064C10.2768 7.41453 10.5216 7.24489 10.8226 7.24322C11.2983 7.24089 11.7737 7.24322 12.2493 7.24322C13.659 7.24322 15.069 7.24322 16.4791 7.24322C16.9687 7.24322 17.2921 7.67132 17.1444 8.11639C17.0522 8.3958 16.8024 8.56977 16.4818 8.57044C15.5433 8.5711 14.6036 8.57088 13.6627 8.56977Z"
          fill="inherit"
        />
        <path
          d="M13.662 12.5538C12.7163 12.5538 11.7709 12.5561 10.8256 12.5538C10.3433 12.5538 10.023 12.1038 10.1816 11.664C10.2771 11.3979 10.5222 11.2289 10.8236 11.2276C11.3379 11.2253 11.8518 11.2276 12.366 11.2276C13.7376 11.2276 15.1088 11.2276 16.4797 11.2276C16.969 11.2276 17.2924 11.6567 17.145 12.1014C17.0522 12.3805 16.8024 12.5541 16.4817 12.5545C15.5419 12.5549 14.602 12.5547 13.662 12.5538Z"
          fill="inherit"
        />
        <path
          d="M11.265 16.7831C11.105 16.7831 10.9447 16.7881 10.7847 16.7831C10.6115 16.7783 10.4471 16.7058 10.3267 16.5812C10.2063 16.4566 10.1395 16.2897 10.1406 16.1165C10.1418 15.9432 10.2107 15.7773 10.3327 15.6542C10.4548 15.5312 10.6201 15.4608 10.7934 15.4582C11.1137 15.4519 11.434 15.4509 11.7543 15.4582C11.9256 15.4638 12.088 15.5355 12.2075 15.6583C12.327 15.7811 12.3944 15.9453 12.3953 16.1167C12.3963 16.288 12.3309 16.4531 12.2128 16.5772C12.0947 16.7013 11.9332 16.7749 11.762 16.7825C11.5977 16.7888 11.4314 16.7831 11.265 16.7831Z"
          fill="inherit"
        />
        <path
          d="M7.80922 7.24411C7.93063 7.24411 8.05238 7.23979 8.17512 7.24411C8.34636 7.25101 8.50827 7.32395 8.62689 7.44764C8.74551 7.57132 8.81163 7.73614 8.81137 7.90751C8.81111 8.07889 8.7445 8.2435 8.62551 8.36683C8.50651 8.49016 8.34438 8.56261 8.17313 8.56899C7.93596 8.57665 7.69812 8.57665 7.46095 8.56899C7.28894 8.56329 7.1259 8.49088 7.00633 8.36709C6.88676 8.24331 6.82005 8.07786 6.82031 7.90575C6.82057 7.73365 6.88778 7.5684 7.00773 7.44497C7.12767 7.32155 7.29093 7.24963 7.46295 7.24444C7.57738 7.24012 7.69346 7.24411 7.80922 7.24411Z"
          fill="inherit"
        />
        <path
          d="M7.81057 11.2285C7.93199 11.2285 8.05373 11.2245 8.17647 11.2285C8.35225 11.229 8.52064 11.2993 8.64459 11.4239C8.76854 11.5486 8.8379 11.7173 8.83742 11.8931C8.83693 12.0689 8.76664 12.2373 8.642 12.3612C8.51737 12.4852 8.34859 12.5545 8.17281 12.5541C7.93565 12.5617 7.69781 12.562 7.46064 12.5541C7.28863 12.5483 7.12562 12.4758 7.00611 12.3519C6.8866 12.2281 6.81997 12.0626 6.82031 11.8905C6.82066 11.7184 6.88796 11.5532 7.00797 11.4298C7.12797 11.3064 7.29127 11.2346 7.4633 11.2295C7.5784 11.2235 7.69448 11.2285 7.81057 11.2285Z"
          fill="inherit"
        />
        <path
          d="M7.82143 16.7832C7.70002 16.7832 7.57827 16.7875 7.45553 16.7832C7.28351 16.7771 7.12061 16.7043 7.00132 16.5802C6.88203 16.4561 6.81572 16.2904 6.81641 16.1183C6.8171 15.9462 6.88475 15.7811 7.00503 15.658C7.12531 15.5348 7.28879 15.4633 7.46085 15.4586C7.69802 15.4516 7.93586 15.451 8.17303 15.4586C8.34426 15.4659 8.50602 15.5393 8.62436 15.6632C8.74269 15.7872 8.80841 15.9522 8.80772 16.1236C8.80703 16.295 8.73999 16.4595 8.62066 16.5825C8.50133 16.7055 8.33899 16.7776 8.1677 16.7835C8.05361 16.7888 7.93852 16.7832 7.82143 16.7832Z"
          fill="inherit"
        />
      </g>
      <defs>
        <clipPath id="clip0_1202_22457">
          <rect
            width={17}
            height="17.0033"
            fill="white"
            transform="translate(3.5 3.5)"
          />
        </clipPath>
      </defs>
    </svg>


  )
}

export default OverviewIcon
