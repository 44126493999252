import { type FC, useCallback, useEffect, useRef, useState } from "react";
import { Skeleton } from "@mui/material";
import ArrowRightIcon2 from "../../icons/ArrowRightIcon2";
import axios from "axios";
import { useUserContext } from "../../../contexts/UserContext";
import * as amplitude from "@amplitude/analytics-browser";
import SubCanvas from "./BusinessModelContent/SubCanvas";
import { useLayoutContext } from "../../../contexts/LayoutContext";
import {
  PlateEditor,
  PlateEditorRef,
} from "../../../Components/editor/plate-editor";
// import { toast } from "react-toastify";

interface BusinessModelContentProps {
  conceptData: any;
  visible?: boolean;
  getConceptDetail: () => void;
}

type ChatAction = "short" | "elaborate" | "regenerate" | "user_input";

type BusinessModelType =
  | "Channels"
  | "Cost_Structure"
  | "Customer_Relationships"
  | "Customer_Segments"
  | "Key_Activities"
  | "Key_Partnerships"
  | "Key_Resources"
  | "Revenue_Streams"
  | "Value_Propositions";

const businessModelCanvasTitle: any = {
  Key_Partnerships: "Key Partner",
  Key_Activities: "Key Activity",
  Key_Resources: "Key Resources",
  Value_Propositions: "Value Propositions",
  Customer_Relationships: "Customer Relationships",
  Channels: "Channels",
  Customer_Segments: "Customer Segments",
  Cost_Structure: "Cost Structure",
  Revenue_Streams: "Revenue Streams",
};

const CanvasKey = "BusinessModelCanvasJSON";
const CanvasTitle = "Business Model Canvas";

const BusinessModelContent: FC<BusinessModelContentProps> = ({
  conceptData,
  visible,
  getConceptDetail,
}) => {
  const { user } = useUserContext();
  const { deviceType } = useLayoutContext();
  const [selectedCanvas, setSelectedCanvas] =
    useState<BusinessModelType | null>(null);
  const [userInput, setUserInput] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [currentVersion, setCurrentVersion] = useState({
    Channels: 0,
    Cost_Structure: 0,
    Customer_Relationships: 0,
    Customer_Segments: 0,
    Key_Activities: 0,
    Key_Partnerships: 0,
    Key_Resources: 0,
    Revenue_Streams: 0,
    Value_Propositions: 0,
  });
  const [chatStatus, setChatStatus] = useState({
    status: "initial",
    response: "",
  });
  const [hasStartedTyping, setHasStartedTyping] = useState(false);
  const plateEditorRef = useRef<PlateEditorRef>(null);

  const handleGenerateResponse = useCallback(
    async (action: ChatAction) => {
      if (showLoading || saving || selectedCanvas === null) return;

      if (plateEditorRef.current) {
        const editor = plateEditorRef.current.editor;
        const setValue = plateEditorRef.current.setValue;

        let params: any = {
          team_id: user?.organizationMemberships[0]?.organization.id,
          source_list: conceptData.source_list,
          pinecone_id: conceptData.pinecone_id,
          action: action,
          selected_section: selectedCanvas,
        };

        if (typeof chatStatus.response === "string") {
          params.previous_response = chatStatus.response;
        } else {
          params.previous_response = editor.api.markdown.serialize(
            chatStatus.response
          );
        }

        if (conceptData.context && conceptData.context !== "") {
          params.context = conceptData.context;
        }

        if (action === "user_input") {
          if (userInput === "") return;
          params.user_input = userInput;
          setShowLoading(true);
          let response = await axios.post(
            `${process.env.REACT_APP_API_URL}api/getBusinessModelCanvasJSON`,
            params
          );
          setChatStatus({
            status: "updated",
            response: response.data.response,
          });
          setShowLoading(false);
          setUserInput("");
          onGenerateComplete();
          setValue(editor.api.markdown.deserialize(response.data.response));
        } else {
          setShowLoading(true);
          let response = await axios.post(
            `${process.env.REACT_APP_API_URL}api/getBusinessModelCanvasJSON`,
            params
          );
          setChatStatus({
            status: "updated",
            response: response.data.response,
          });
          setShowLoading(false);
          setUserInput("");
          onGenerateComplete();
          setValue(editor.api.markdown.deserialize(response.data.response));
        }
      }
    },
    [userInput, chatStatus, showLoading, saving, selectedCanvas, conceptData]
  );

  const handleSave = useCallback(async () => {
    if (saving) return;
    if (!plateEditorRef.current) return;
    const editor = plateEditorRef.current.editor;

    let params: any = {
      id: conceptData.id,
      BusinessModelCanvasJSON: true,
      SubCanvas: selectedCanvas,
      value: editor.api.markdown.serialize(chatStatus.response),
      plate: chatStatus.response,
    };
    try {
      setSaving(true);
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/editConcept",
        params
      );
      setSaving(false);
      setChatStatus({
        status: "initial",
        response: chatStatus.response,
      });
      if (response?.status === 200) {
        getConceptDetail();
      }
    } catch (error) {
      console.log(error);
    }
  }, [conceptData, chatStatus, saving, selectedCanvas]);

  const handleCancel = useCallback(() => {
    if (selectedCanvas)
      if (
        conceptData[CanvasKey][selectedCanvas as string].versions[
          currentVersion[selectedCanvas]
        ].plate
      )
        setChatStatus({
          status: "initial",
          response:
            conceptData[CanvasKey][selectedCanvas as string].versions[
              currentVersion[selectedCanvas]
            ].plate,
        });
      else
        setChatStatus({
          status: "initial",
          response:
            conceptData[CanvasKey][selectedCanvas as string].versions[
              currentVersion[selectedCanvas]
            ].value,
        });
  }, [conceptData, selectedCanvas]);

  const onGenerateComplete = useCallback(() => {
    if (!visible) {
      // toast.success("Business Model Canvas generate completed!", {
      //   position: "top-right",
      //   autoClose: 3000,
      //   hideProgressBar: true,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: false,
      //   progress: 0,
      //   toastId: "my_toast",
      // });
    }
  }, [visible]);

  useEffect(() => {
    if (
      conceptData[CanvasKey] &&
      conceptData[CanvasKey].status === "generated"
    ) {
      let tempCurrentVersion = {
        Channels: 0,
        Cost_Structure: 0,
        Customer_Relationships: 0,
        Customer_Segments: 0,
        Key_Activities: 0,
        Key_Partnerships: 0,
        Key_Resources: 0,
        Revenue_Streams: 0,
        Value_Propositions: 0,
      };
      Object.keys(businessModelCanvasTitle).forEach((key) => {
        let index = conceptData[CanvasKey][key].versions.findIndex(
          (e: any) => e.id === conceptData[CanvasKey][key].primaryVersionId
        );
        tempCurrentVersion[key as BusinessModelType] = index;
      });
      setCurrentVersion(tempCurrentVersion);
    }
  }, [conceptData[CanvasKey]]);

  if (!visible) return null;

  return (
    <div className="flex flex-col gap-[9px] xl:gap-3 h-full">
      <div className="bg-white p-[15px] xl:p-5 rounded-[4px] flex-1">
        <div
          className="bg-white border border-solid border-[#D4D4D8] rounded-2xl overflow-x-hidden overflow-y-auto"
          style={{
            maxHeight:
              showLoading || chatStatus.status === "updated"
                ? `calc(100vh - ${Math.ceil(
                    640 * (deviceType === "laptop" ? 0.75 : 1)
                  )}px)`
                : `calc(100vh - ${Math.ceil(
                    342 * (deviceType === "laptop" ? 0.75 : 1)
                  )}px)`,
          }}
        >
          <div className="grid grid-cols-5">
            <SubCanvas
              title="Key Partner"
              className={`relative cursor-pointer p-[9px] xl:p-3 border-r border-solid border-[#D4D4D8] rounded-tl-2xl -outline-offset-1 outline-1 ${
                selectedCanvas === "Key_Partnerships"
                  ? "outline-[#3B82F6] outline"
                  : "hover:outline-[#3B82F6] hover:outline outline-none"
              }`}
              data={conceptData[CanvasKey].Key_Partnerships}
              subCanvasKey="Key_Partnerships"
              getConceptDetail={getConceptDetail}
              selected={selectedCanvas === "Key_Partnerships"}
              onChangeCanvasVersion={(value) => {
                setChatStatus((prev) => ({
                  status: prev.status,
                  response: value,
                }));
              }}
              onSelectCanvas={(value) => {
                setSelectedCanvas("Key_Partnerships");
                setShowLoading(false);
                setChatStatus((prev) => ({
                  status: "updated",
                  response: value,
                }));
              }}
            />
            <div className="flex flex-col border-r border-solid border-[#D4D4D8]">
              <SubCanvas
                title="Key Activity"
                className={`relative cursor-pointer flex-1 p-[9px] xl:p-3 border-b border-solid border-[#D4D4D8] -outline-offset-1 outline-1 ${
                  selectedCanvas === "Key_Activities"
                    ? "outline-[#3B82F6] outline"
                    : "hover:outline-[#3B82F6] hover:outline outline-none"
                }`}
                data={conceptData[CanvasKey].Key_Activities}
                subCanvasKey="Key_Activities"
                getConceptDetail={getConceptDetail}
                selected={selectedCanvas === "Key_Activities"}
                onChangeCanvasVersion={(value) => {
                  setChatStatus((prev) => ({
                    status: prev.status,
                    response: value,
                  }));
                }}
                onSelectCanvas={(value) => {
                  setSelectedCanvas("Key_Activities");
                  setShowLoading(false);
                  setChatStatus((prev) => ({
                    status: "updated",
                    response: value,
                  }));
                }}
              />
              <SubCanvas
                title="Key Resources"
                className={`relative cursor-pointer flex-1 p-[9px] xl:p-3 -outline-offset-1 outline-1 ${
                  selectedCanvas === "Key_Resources"
                    ? "outline-[#3B82F6] outline"
                    : "hover:outline-[#3B82F6] hover:outline outline-none"
                }`}
                data={conceptData[CanvasKey].Key_Resources}
                subCanvasKey="Key_Resources"
                getConceptDetail={getConceptDetail}
                selected={selectedCanvas === "Key_Resources"}
                onChangeCanvasVersion={(value) => {
                  setChatStatus((prev) => ({
                    status: prev.status,
                    response: value,
                  }));
                }}
                onSelectCanvas={(value) => {
                  setSelectedCanvas("Key_Resources");
                  setShowLoading(false);
                  setChatStatus((prev) => ({
                    status: "updated",
                    response: value,
                  }));
                }}
              />
            </div>
            <SubCanvas
              title="Value Propositions"
              className={`relative cursor-pointer p-[9px] xl:p-3 border-r border-solid border-[#D4D4D8] -outline-offset-1 outline-1 ${
                selectedCanvas === "Value_Propositions"
                  ? "outline-[#3B82F6] outline"
                  : "hover:outline-[#3B82F6] hover:outline outline-none"
              }`}
              data={conceptData[CanvasKey].Value_Propositions}
              subCanvasKey="Value_Propositions"
              getConceptDetail={getConceptDetail}
              selected={selectedCanvas === "Value_Propositions"}
              onChangeCanvasVersion={(value) => {
                setChatStatus((prev) => ({
                  status: prev.status,
                  response: value,
                }));
              }}
              onSelectCanvas={(value) => {
                setSelectedCanvas("Value_Propositions");
                setShowLoading(false);
                setChatStatus((prev) => ({
                  status: "updated",
                  response: value,
                }));
              }}
            />
            <div className="flex flex-col border-r border-solid border-[#D4D4D8]">
              <SubCanvas
                title="Customer Relationships"
                className={`relative cursor-pointer flex-1 p-[9px] xl:p-3 border-b border-solid border-[#D4D4D8] -outline-offset-1 outline-1 ${
                  selectedCanvas === "Customer_Relationships"
                    ? "outline-[#3B82F6] outline"
                    : "hover:outline-[#3B82F6] hover:outline outline-none"
                }`}
                data={conceptData[CanvasKey].Customer_Relationships}
                subCanvasKey="Customer_Relationships"
                getConceptDetail={getConceptDetail}
                selected={selectedCanvas === "Customer_Relationships"}
                onChangeCanvasVersion={(value) => {
                  setChatStatus((prev) => ({
                    status: prev.status,
                    response: value,
                  }));
                }}
                onSelectCanvas={(value) => {
                  setSelectedCanvas("Customer_Relationships");
                  setShowLoading(false);
                  setChatStatus((prev) => ({
                    status: "updated",
                    response: value,
                  }));
                }}
              />
              <SubCanvas
                title="Channels"
                className={`relative cursor-pointer flex-1 p-[9px] xl:p-3 -outline-offset-1 outline-1 ${
                  selectedCanvas === "Channels"
                    ? "outline-[#3B82F6] outline"
                    : "hover:outline-[#3B82F6] hover:outline outline-none"
                }`}
                data={conceptData[CanvasKey].Channels}
                subCanvasKey="Channels"
                getConceptDetail={getConceptDetail}
                selected={selectedCanvas === "Channels"}
                onChangeCanvasVersion={(value) => {
                  setChatStatus((prev) => ({
                    status: prev.status,
                    response: value,
                  }));
                }}
                onSelectCanvas={(value) => {
                  setSelectedCanvas("Channels");
                  setShowLoading(false);
                  setChatStatus((prev) => ({
                    status: "updated",
                    response: value,
                  }));
                }}
              />
            </div>
            <SubCanvas
              title="Customer Segments"
              className={`relative cursor-pointer p-[9px] xl:p-3 rounded-tr-2xl -outline-offset-1 outline-1 ${
                selectedCanvas === "Customer_Segments"
                  ? "outline-[#3B82F6] outline"
                  : "hover:outline-[#3B82F6] hover:outline outline-none"
              }`}
              data={conceptData[CanvasKey].Customer_Segments}
              subCanvasKey="Customer_Segments"
              getConceptDetail={getConceptDetail}
              selected={selectedCanvas === "Customer_Segments"}
              onChangeCanvasVersion={(value) => {
                setChatStatus((prev) => ({
                  status: prev.status,
                  response: value,
                }));
              }}
              onSelectCanvas={(value) => {
                setSelectedCanvas("Customer_Segments");
                setShowLoading(false);
                setChatStatus((prev) => ({
                  status: "updated",
                  response: value,
                }));
              }}
            />
          </div>
          <div className="grid grid-cols-2 border-t border-solid border-[#D4D4D8]">
            <SubCanvas
              title="Cost Structure"
              className={`relative cursor-pointer p-[9px] xl:p-3 border-r border-solid border-[#D4D4D8] -outline-offset-1 outline-1 rounded-bl-2xl ${
                selectedCanvas === "Cost_Structure"
                  ? "outline-[#3B82F6] outline"
                  : "hover:outline-[#3B82F6] hover:outline outline-none"
              }`}
              data={conceptData[CanvasKey].Cost_Structure}
              subCanvasKey="Cost_Structure"
              getConceptDetail={getConceptDetail}
              selected={selectedCanvas === "Cost_Structure"}
              onChangeCanvasVersion={(value) => {
                setChatStatus((prev) => ({
                  status: prev.status,
                  response: value,
                }));
              }}
              onSelectCanvas={(value) => {
                setSelectedCanvas("Cost_Structure");
                setShowLoading(false);
                setChatStatus((prev) => ({
                  status: "updated",
                  response: value,
                }));
              }}
            />
            <SubCanvas
              title="Revenue Streams"
              className={`relative cursor-pointer p-[9px] xl:p-3 -outline-offset-1 outline-1 rounded-br-2xl ${
                selectedCanvas === "Revenue_Streams"
                  ? "outline-[#3B82F6] outline"
                  : "hover:outline-[#3B82F6] hover:outline outline-none"
              }`}
              data={conceptData[CanvasKey].Revenue_Streams}
              subCanvasKey="Revenue_Streams"
              getConceptDetail={getConceptDetail}
              selected={selectedCanvas === "Revenue_Streams"}
              onChangeCanvasVersion={(value) => {
                setChatStatus((prev) => ({
                  status: prev.status,
                  response: value,
                }));
              }}
              onSelectCanvas={(value) => {
                setSelectedCanvas("Revenue_Streams");
                setShowLoading(false);
                setChatStatus((prev) => ({
                  status: "updated",
                  response: value,
                }));
              }}
            />
          </div>
        </div>
      </div>
      <div
        className="bg-white p-[15px] xl:p-5 rounded-[4px] flex-none"
        hidden={!showLoading}
      >
        <div className="text-xs xl:text-base font-medium text-[#71717A] mb-[7px] xl:mb-[10px]">
          Editing <span className="text-[#27272A]">Business Model Canvas</span>
        </div>
        <div className="rounded-[4px] border-[#D4D4D8] border">
          <div className="">
            <div className="flex flex-col gap-[6px] xl:gap-2 h-[199px] xl:h-[266px] p-[9px] xl:p-3">
              <Skeleton
                height={10}
                sx={{
                  width: "70%",
                  transform: "scale(1)",
                  overflow: "hidden",
                }}
              />
              <Skeleton
                height={10}
                sx={{
                  width: "100%",
                  transform: "scale(1)",
                  overflow: "hidden",
                }}
              />
              <Skeleton
                height={10}
                sx={{
                  width: "45%",
                  transform: "scale(1)",
                  overflow: "hidden",
                }}
              />
              <Skeleton
                height={10}
                sx={{
                  width: "100%",
                  transform: "scale(1)",
                  overflow: "hidden",
                }}
              />
              <Skeleton
                height={10}
                sx={{
                  width: "70%",
                  transform: "scale(1)",
                  overflow: "hidden",
                }}
              />
              <Skeleton
                height={10}
                sx={{
                  width: "67%",
                  transform: "scale(1)",
                  overflow: "hidden",
                }}
              />
            </div>
            <div className="h-[39px] xl:h-[52px] w-full py-[10px] xl:py-[14px] px-3 xl:px-4 gap-[6px] xl:gap-2 flex items-center border-t border-[#E4E4E7] ">
              <input
                type="text"
                className="outline-none border-none bg-transparent text-[10px] xl:text-sm text-[#71717A] flex-1"
                placeholder="Chat to refine the text above"
                value={userInput}
                disabled
              />
              <button
                className="w-[21px] xl:w-7 h-[21px] xl:h-7 flex-none bg-[#D4D4D8] rounded-full flex items-center justify-center"
                onClick={() => handleGenerateResponse("user_input")}
              >
                <ArrowRightIcon2
                  stroke="#ffffff"
                  className="w-3 xl:w-4 h-3 xl:h-4"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg-white p-[15px] xl:p-5 rounded-[4px] flex-none"
        hidden={showLoading || chatStatus.status !== "initial"}
      >
        {selectedCanvas ? (
          <>
            <div className="flex gap-[6px] xl:gap-2 mb-2">
              <div
                className="w-fit h-[21px] xl:h-7 px-[9px] xl:px-3 flex items-center rounded-full border border-[#E4E4E7] text-[#52525B] text-[9px] xl:text-xs cursor-pointer"
                onClick={() => handleGenerateResponse("short")}
                data-event-name="Concepts: BMC Shortened"
              >
                Shorten
              </div>
              <div
                className="w-fit h-[21px] xl:h-7 px-[9px] xl:px-3 flex items-center rounded-full border border-[#E4E4E7] text-[#52525B] text-[9px] xl:text-xs cursor-pointer"
                onClick={() => handleGenerateResponse("elaborate")}
                data-event-name="Concepts: BMC Elaborated"
              >
                Elaborate
              </div>
              <div
                className="w-fit h-[21px] xl:h-7 px-[9px] xl:px-3 flex items-center rounded-full border border-[#E4E4E7] text-[#52525B] text-[9px] xl:text-xs cursor-pointer"
                onClick={() => handleGenerateResponse("regenerate")}
                data-event-name="Concepts: BMC Regenerated"
              >
                Regenerate
              </div>
            </div>
            <div className="h-[39px] xl:h-[52px] w-full rounded-[4px] border py-[10px] xl:py-[14px] px-3 xl:px-4 gap-[6px] xl:gap-2 flex items-center bg-[#FCFCFC] border-[#E4E4E7]">
              <span className="text-[10px] xl:text-sm text-[#3B82F6] font-bold">
                {businessModelCanvasTitle[selectedCanvas]}
              </span>
              <input
                type="text"
                className="outline-none border-none bg-transparent text-[10px] xl:text-sm text-[#71717A] flex-1"
                placeholder="Chat to refine the text above"
                value={userInput}
                onChange={(e) => {
                  setUserInput(e.target.value);
                  // Track typing event in Amplitude
                  if (!hasStartedTyping) {
                    amplitude.track("Concepts: BMC Edited", {
                      content: e.target.value,
                      length: e.target.value.length,
                    });
                    setHasStartedTyping(true); // Prevent tracking again until submission
                  }
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    handleGenerateResponse("user_input");
                  }
                }}
              />
              <button
                className="w-[21px] xl:w-7 h-[21px] xl:h-7 flex-none bg-[#3B82F6] rounded-full flex items-center justify-center"
                onClick={() => handleGenerateResponse("user_input")}
              >
                <ArrowRightIcon2
                  stroke="#ffffff"
                  className="w-3 xl:w-4 h-3 xl:h-4"
                />
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="flex gap-[6px] xl:gap-2 mb-2">
              <div className="w-fit h-[21px] xl:h-7 px-[9px] xl:px-3 flex items-center rounded-full bg-[#F4F4F5] text-[#71717A] text-[9px] xl:text-xs cursor-pointer">
                Shorten
              </div>
              <div className="w-fit h-[21px] xl:h-7 px-[9px] xl:px-3 flex items-center rounded-full bg-[#F4F4F5] text-[#71717A] text-[9px] xl:text-xs cursor-pointer">
                Elaborate
              </div>
              <div className="w-fit h-[21px] xl:h-7 px-[9px] xl:px-3 flex items-center rounded-full bg-[#F4F4F5] text-[#71717A] text-[9px] xl:text-xs cursor-pointer">
                Regenerate
              </div>
            </div>
            <div className="h-[39px] xl:h-[52px] w-full rounded-[4px] border py-[10px] xl:py-[14px] px-3 xl:px-4 gap-[6px] xl:gap-2 flex items-center bg-[#F4F4F5] border-[#D4D4D8]">
              <input
                type="text"
                className="outline-none border-none bg-transparent text-[10px] xl:text-sm text-[#71717A] flex-1"
                placeholder="Select a section to refine"
                readOnly
                disabled
              />
              <button className="w-[21px] xl:w-7 h-[21px] xl:h-7 flex-none bg-[#D4D4D8] rounded-full flex items-center justify-center">
                <ArrowRightIcon2
                  stroke="#ffffff"
                  className="w-3 xl:w-4 h-3 xl:h-4"
                />
              </button>
            </div>
          </>
        )}
      </div>
      <div
        className="bg-white p-[15px] xl:p-5 rounded-[4px] flex-none"
        hidden={showLoading || chatStatus.status === "initial"}
      >
        <div className="text-xs xl:text-base font-medium text-[#71717A] mb-[7px] xl:mb-[10px]">
          Editing <span className="text-[#27272A]">Business Model Canvas</span>
        </div>
        <div className="rounded-[4px] border-[#3B82F6] border">
          <div className="">
            <div className="h-[174px] xl:h-[232px]">
              {selectedCanvas && (
                <PlateEditor
                  initialMarkdown={
                    conceptData[CanvasKey][selectedCanvas as BusinessModelType]
                      .versions[
                      currentVersion[selectedCanvas as BusinessModelType]
                    ]?.plate
                      ? conceptData[CanvasKey][
                          selectedCanvas as BusinessModelType
                        ].versions[
                          currentVersion[selectedCanvas as BusinessModelType]
                        ]?.plate
                      : conceptData[CanvasKey][
                          selectedCanvas as BusinessModelType
                        ].versions[
                          currentVersion[selectedCanvas as BusinessModelType]
                        ]?.value
                  }
                  value={chatStatus.response}
                  onChange={(value) => {
                    setChatStatus((prev) => ({
                      ...prev,
                      response: value,
                    }));
                  }}
                  ref={plateEditorRef}
                />
              )}
            </div>
            <div className="flex gap-4 mb-2 px-[9px] xl:px-3 items-center">
              {chatStatus.response ===
                conceptData[CanvasKey][selectedCanvas as string] ||
              chatStatus.response === "" ? (
                <span className="text-[#3b83f680] text-[10px] xl:text-sm font-bold cursor-pointer underline">
                  Save
                </span>
              ) : (
                <span
                  className="text-[#3B82F6] text-[10px] xl:text-sm font-bold cursor-pointer underline"
                  onClick={() => handleSave()}
                >
                  {saving ? "Saving..." : "Save"}
                </span>
              )}
              <span
                className="text-[#3B82F6] text-[10px] xl:text-sm font-bold cursor-pointer underline"
                onClick={() => handleCancel()}
              >
                Cancel
              </span>
            </div>
            <div className="h-[39px] xl:h-[52px] w-full border-t border-[#E4E4E7] py-[10px] xl:py-[14px] px-3 xl:px-4 gap-[6px] xl:gap-2 flex items-center">
              {selectedCanvas && (
                <span className="text-[10px] xl:text-sm text-[#3B82F6] font-bold">
                  {businessModelCanvasTitle[selectedCanvas]}
                </span>
              )}
              <input
                type="text"
                className="outline-none border-none bg-transparent text-[10px] xl:text-sm text-[#71717A] flex-1"
                placeholder="Chat to refine the text above"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    handleGenerateResponse("user_input");
                  }
                }}
              />
              <button
                className="w-[21px] xl:w-7 h-[21px] xl:h-7 flex-none bg-[#3B82F6] rounded-full flex items-center justify-center"
                onClick={() => handleGenerateResponse("user_input")}
              >
                <ArrowRightIcon2
                  stroke="#ffffff"
                  className="w-3 xl:w-4 h-3 xl:h-4"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessModelContent;
