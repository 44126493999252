import { type FC } from "react";

const ListIcon: FC<any> = (props: any) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      stroke="#71717A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={3}
        y={5}
        width={18}
        height={6}
        rx={1}
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <rect
        x={3}
        y={13}
        width={18}
        height={6}
        rx={1}
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ListIcon
