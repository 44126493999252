import { type FC, useCallback, useState, useEffect } from "react";
import { useUserContext } from "../../contexts/UserContext";
import { toast, type ToastOptions } from "react-toastify";
import {
  // MdRadioButtonChecked,
  // MdRadioButtonUnchecked,
  MdOutlineAdd,
  MdClose,
  MdArrowBack,
} from "react-icons/md";
import * as amplitude from "@amplitude/analytics-browser";
import axios from "axios";

const TeamManagement: FC = () => {
  const { user /*, isLoaded, isSignedIn */ } = useUserContext();
  const [userData, setUserData] = useState<any[]>([]);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [isEditingUser, setIsEditingUser] = useState(false);
  const [isInviteStarted, setInviteStarted] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [addingUsers, setAddingUsers] = useState([
    {
      email: "",
      role: "org:member",
      inviter_user_id: user?.id,
      team_id: user?.organizationMemberships[0]?.organization.id,
      team_name: user?.organizationMemberships[0]?.organization.name,
    },
  ]);
  const [hasStartedTyping, setHasStartedTyping] = useState(false);

  const dataFetch = useCallback(async () => {
    try {
      const teamId = user?.organizationMemberships[0]?.organization.id;
      if (!teamId) throw new Error("Team Id is not available");

      const data = new FormData();
      data.append("team_id", teamId);

      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}api/getUsers`,
        data,
        {
          headers: {},
        }
      );

      setUserData(result.data.data);
    } catch (error) {
      console.log(error);
    }
  }, [user, setUserData]);

  useEffect(() => {
    dataFetch().then((r) => r);
  }, [dataFetch]);

  const handleDeleteClick = async (userData: any) => {
    try {
      const shouldDelete = window.confirm(
        "Are you sure you want to delete this user?"
      );
      if (shouldDelete) {
        if (!userData["joined"])
          userData["requesting_user_id"] = user?.id;
        const result = await axios.post(
          process.env.REACT_APP_API_URL + "api/deleteUser",
          userData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (result.status === 200) {
          toast.success("User Deleted!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: "my_toast",
          });
          await dataFetch();
        }
      }
    } catch (error) {}
  };

  const handleAddUser = () => {
    setAddingUsers((prevUsers) => [
      ...prevUsers,
      {
        email: "",
        role: "org:member",
        inviter_user_id: user?.id,
        team_id: user?.organizationMemberships[0]?.organization.id,
        team_name: user?.organizationMemberships[0]?.organization.name,
      },
    ]);
  };

  // FIXME: Is this used?
  const handleChangeAddUserName = (_value: string, _index: number) => {
    setAddingUsers((prevUsers) =>
      prevUsers.map((user, _idx) => {
        return {
          ...user,
        };
      })
    );
  };

  const handleChangeAddUserEmail = (value: string, index: number) => {
    setAddingUsers((prevUsers) =>
      prevUsers.map((user, idx) => {
        return {
          ...user,
          email: idx === index ? value : user.email,
        };
      })
    );
  };

  const toastOptions: ToastOptions = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    toastId: "my_toast",
  }

  const handleInviteUsers = () => {



    const filteredUsers = addingUsers.filter((user) => user.email !== "");
    if (filteredUsers.length === 0) {
      toast.warn("Please input username and email correctly", toastOptions);
      return;
    }
    setInviteStarted(true);
    axios
      .post(process.env.REACT_APP_API_URL + "api/inviteUsers", filteredUsers)
      .then(function (response: any) {
        toast.success(response.data.message as string, toastOptions);
        dataFetch().then(r => r);
        setAddingUsers([
          {
            email: "",
            role: "org:member",
            inviter_user_id: user?.id,
            team_id: user?.organizationMemberships[0]?.organization.id,
            team_name: user?.organizationMemberships[0]?.organization.name,
          },
        ]);
        setIsAddingUser(false);
        setInviteStarted(false);
      })
      .catch(function (error: any) {
        toast.error(error.response.data.message, toastOptions);
      });
  };

  const handleUpdateUser = async () => {
    const data = new FormData();
    data.append("id", selectedUser?.id);
    data.append("role", selectedUser?.role);
    const result = await axios.post(
      process.env.REACT_APP_API_URL + "api/editUser",
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setIsEditingUser(false);
    if (result.status === 200) {
      toast.success("User Edited!", toastOptions);
      dataFetch().then(r => r);
      setSelectedUser(null);
    }
  };

  return (
    <div className="pt-3 xl:pt-4 px-[111px] xl:px-[148px] pb-12 xl:pb-16">
      <div
        className="w-full mb-3 xl:mb-4"
        hidden={isAddingUser || isEditingUser}
      >
        <div className="flex flex-col gap-[6px] xl:gap-2 mb-3 xl:mb-4">
          {userData.map((user, idx) => {
            return (
              <div
                className="flex flex-col gap-[6px] xl:gap-2 mb-3 xl:mb-4 cursor-pointer"
                key={idx}
              >
                {user?.users.map((userData: any, index: number) => {
                  return (
                    <div
                      className="w-full py-[6px] xl:py-2 px-3 xl:px-4 rounded-lg border border-[#E4E4E7] flex items-center gap-[7px] xl:gap-[10px]"
                      key={index}
                      onClick={() => {
                        // setIsEditingUser(true)
                        setSelectedUser(userData);
                      }}
                    >
                      <div className="w-[30px] xl:w-10 h-[30px] xl:h-10 rounded-full flex items-center justify-center bg-[#D4D4D8]">
                        <span className="text-xs xl:text-base font-medium text-[#27272A] uppercase">
                          {userData?.user_name[0]}
                        </span>
                      </div>
                      <div className="flex-1">
                        <div className="flex items-center gap-1">
                          <div className="text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] font-bold text-black">
                            {userData?.user_name}
                          </div>
                          {!userData?.joined && (
                            <div className="h-4 xl:h-[22px] py-[1px] xl:py-[2px] px-[6px] xl:px-2 bg-[#DBEAFE] border border-[#3B82F6] rounded-full flex items-center justify-center">
                              <span className="text-[7px] xl:text-[10px] leading-[13px] xl:leading-[18px] font-bold text-[#3B82F6]">
                                Invitation Sent
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="text-[9px] xl:text-xs leading-[15px] xl:leading-5 font-medium text-[#71717A]">
                          {userData?.email}
                        </div>
                      </div>
                      <button
                        className="outline-none"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteClick(userData).then(r => r);
                        }}
                      >
                        <MdClose className="text-xl text-[#A1A1AA]" />
                      </button>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <button
          className="outline-none w-full h-9 xl:h-12 border border-[#71717A] bg-transparent px-[18px] xl:px-6 py-[9px] xl:py-3 flex items-center justify-center gap-[6px] xl:gap-2 rounded-lg"
          onClick={() => setIsAddingUser(true)}
          data-event-name="Settings: 'Add Member' Clicked"
        >
          <MdOutlineAdd className="text-lg xl:text-2xl text-[#71717A]" />
          <span
            className="text-xs xl:text-base font-bold text-[#71717A]"
            data-event-name="Settings: 'Add Member' Clicked"
          >
            Add Members
          </span>
        </button>
      </div>
      <div className="w-full" hidden={!isAddingUser}>
        <div className="w-full mb-3 xl:mb-4">
          <button
            className="outline-none flex gap-[6px] xl:gap-2 bg-transparent"
            onClick={() => setIsAddingUser(false)}
          >
            <MdArrowBack className="text-lg xl:text-2xl flex-none text-[#71717A]" />
            <span className="text-xs xl:text-base font-bold text-[#71717A]">
              Back
            </span>
          </button>
        </div>
        <div className="pl-[3px] xl:pl-1 text-xs xl:text-base font-medium text-[#27272A] mb-[6px] xl:mb-2">
          Add Team Members
        </div>
        <div className="flex flex-col gap-[6px] xl:gap-2 mb-3 xl:mb-4">
          {addingUsers.map((user, index) => {
            return (
              <div className="flex gap-[6px] xl:gap-2" key={index}>
                <input
                  type="text"
                  value={user.email}
                  className="w-[100%] h-9 xl:h-12 border border-[#A1A1AA] rounded-lg outline-none px-3 xl:px-4 py-[9px] xl:py-3 text-[#71717A] text-[10px] xl:text-sm leading-[18px] xl:leading-6 font-medium"
                  placeholder="Email Address"
                  onChange={(e) => {
                    handleChangeAddUserEmail(e.target.value, index);
                    // Track typing event in Amplitude
                    if (!hasStartedTyping) {
                      amplitude.track("Settings: User Email Added", {
                        content: e.target.value,
                        length: e.target.value.length,
                      });
                      setHasStartedTyping(true); // Prevent tracking again until submission
                    }
                  }}
                />
                <button
                  className="flex-none outline-none"
                  onClick={() =>
                    setAddingUsers((prevUsers) =>
                      prevUsers.filter((_e, idx) => idx !== index)
                    )
                  }
                  disabled={index === 0}
                  data-event-name="Settings: Invitation Sent"
                >
                  <MdClose className="text-lg xl:text-2xl flex-none text-[#71717A]" />
                </button>
              </div>
            );
          })}
          <button
            className="outline-none w-full h-9 xl:h-12 border border-[#71717A] bg-transparent px-[18px] xl:px-6 py-[9px] xl:py-3 flex items-center justify-center gap-[6px] xl:gap-2 rounded-lg"
            onClick={() => handleAddUser()}
            data-event-name="Settings: 'Add Member' Clicked"
          >
            <MdOutlineAdd className="text-lg xl:text-2xl text-[#71717A]" />
            <span
              className="text-xs xl:text-base font-bold text-[#71717A]"
              data-event-name="Settings: 'Add Member' Clicked"
            >
              Add Member
            </span>
          </button>
        </div>
        <button
          className={`w-full h-9 xl:h-12 flex items-center justify-center rounded-lg cursor-pointer ${
            isInviteStarted ? "bg-[#E4E4E7]" : "bg-[#3B82F6]"
          } outline-none`}
          onClick={() => handleInviteUsers()}
          data-event-name="Settings: Invite Sent"
        >
          <span
            className={`text-xs xl:text-base font-bold ${
              isInviteStarted ? "text-[#A1A1AA]" : "text-white"
            }`}
            data-event-name="Settings: Invite Sent"
          >
            {isInviteStarted ? "Inviting..." : "Invite"}
          </span>
        </button>
      </div>
      <div className="w-full" hidden={!isEditingUser}>
        <div className="w-full mb-3 xl:mb-4">
          <button
            className="outline-none flex gap-[6px] xl:gap-2 bg-transparent"
            onClick={() => setIsEditingUser(false)}
          >
            <MdArrowBack className="text-lg xl:text-2xl flex-none text-[#71717A]" />
            <span className="text-xs xl:text-base font-bold text-[#71717A]">
              Back
            </span>
          </button>
        </div>
        <div className="pl-[3px] xl:pl-1 text-xs xl:text-base font-medium text-[#27272A] mb-[6px] xl:mb-2">
          Edit Team Member
        </div>
        <div className="flex flex-col gap-[6px] xl:gap-2 mb-3 xl:mb-4">
          <div className="flex gap-[6px] xl:gap-2">
            <input
              type="text"
              value={selectedUser?.email}
              className="w-[100%] h-9 xl:h-12 border border-[#A1A1AA] rounded-lg outline-none px-3 xl:px-4 py-[9px] xl:py-3 text-[#71717A] text-[10px] xl:text-sm leading-[18px] xl:leading-6 font-medium"
              placeholder="Email Address"
              onChange={(e) =>
                setSelectedUser((prevUser: any) => {
                  return { ...prevUser, email: e.target.value };
                })
              }
            />
          </div>
        </div>
        <button
          className="w-full h-9 xl:h-12 flex items-center justify-center rounded-lg cursor-pointer bg-[#3B82F6] outline-none"
          onClick={() => handleUpdateUser()}
        >
          <span className="text-xs xl:text-base font-bold text-white">
            Update
          </span>
        </button>
      </div>
    </div>
  );
};

export default TeamManagement;
