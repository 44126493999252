import { type ChangeEvent, type FC, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { IoCloseOutline } from "react-icons/io5";
import axios from "axios";
import { useRagContext } from "../../../../contexts/RagContext";
import { useUserContext } from "../../../../contexts/UserContext";

interface AddFolderModalProps {
  open?: boolean;
  handleClose?: () => void;
  /** 
   * parentFolders is an array of folder names (strings).
   * If parentFolders = [] => root
   * If parentFolders = ["Documents", "Reports"] => current path: "Documents/Reports"
   */
  parentFolders: string[];
}

const AddFolderModal: FC<AddFolderModalProps> = ({
  open,
  handleClose,
  parentFolders,
}) => {
  const { fetchSources } = useRagContext();
  const { user } = useUserContext();
  const [title, setTitle] = useState<string>("");

  useEffect(() => {
    if (open) {
      setTitle("");
    }
  }, [open]);

  const handleSaveFolder = async () => {
    if (title.trim() === "") return;

    // Build the old_path from the parentFolders array
    // e.g. parentFolders = ["My Documents", "Reports"] => "Reports"
    let parent_folder_array = [...parentFolders];
    parent_folder_array.shift();
    const old_path = parent_folder_array.join("/");

    // Determine the scope of the document (user or team)
    const topLevelFolder = parentFolders[0]; // e.g., "My Documents" or "Team Documents"
    const source_scope = topLevelFolder === "Team Documents" ? "team" : "user";
    // Build the params expected by your /api/folders (create_folder) endpoint
  // Build the params expected by your /api/folders (create_folder) endpoint
    const params = {
      team_id: user?.organizationMemberships?.[0]?.organization.id,
      user_id: user?.id,
      application_name: process.env.REACT_APP_APPLICATION_NAME as string, // Required by your backend
      old_path: old_path, // The current parent path
      folder_name: title.trim(), // The new folder's name
      source_scope: source_scope, // 'user' or 'team'
    };
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "api/folders",
        params
      );
      if (response.status === 200) {
        // Successfully created on the backend => refresh folder list
        fetchSources();
        handleClose?.();
      }
    } catch (error) {
      console.error("Error creating folder:", error);
    }
  };

  return (
    <Modal
      open={Boolean(open)}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[327px] xl:w-[436px] bg-white rounded-[10px] shadow-lg">
        {/* Header */}
        <div className="py-3 xl:py-4 px-[18px] xl:px-6 flex items-center justify-between border-b border-solid border-[#E4E4E7] gap-3 xl:gap-4">
          <div className="">
            <div className="text-[13px] xl:text-lg leading-[16px] xl:leading-[22px] font-bold text-[#18181B] mb-[3px] xl:mb-1">
              Add Folder
            </div>
          </div>
          <button
            className="w-[30px] xl:w-10 h-[30px] xl:h-10 border border-solid border-[#D4D4D8] flex items-center justify-center flex-none rounded-full"
            onClick={handleClose}
          >
            <IoCloseOutline className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-[#09090B]" />
          </button>
        </div>

        {/* Body */}
        <div className="p-[18px] xl:p-6">
          <div className="w-full flex flex-col gap-3 xl:gap-4 mb-6 xl:mb-8">
            <div className="w-full">
              <label
                htmlFor="edit-folder-title"
                className="px-[3px] xl:px-1 mb-[3px] xl:mb-1"
              >
                Folder Name
              </label>
              <input
                type="text"
                id="edit-folder-title"
                name="edit-folder-title"
                className="w-full border border-solid border-[#D4D4D8] outline-none px-3 xl:px-4 py-[9px] xl:py-3 rounded-lg h-9 xl:h-12 text-[10px] xl:text-sm font-medium text-[#3F3F46]"
                value={title}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
              />
            </div>
          </div>
          <div className="flex gap-3 xl:gap-4">
            <button
              className="outline-none border border-solid border-[#71717A] h-9 xl:h-12 flex items-center justify-center rounded-lg w-full"
              onClick={handleClose}
            >
              <span className="text-xs xl:text-base font-bold text-[#71717A]">
                Cancel
              </span>
            </button>
            <button
              className="outline-none bg-[#3B82F6] h-9 xl:h-12 flex items-center justify-center rounded-lg w-full"
              onClick={handleSaveFolder}
            >
              <span className="text-xs xl:text-base font-bold text-white">
                Save
              </span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddFolderModal;
