import { type FC, createContext, type ReactNode, useContext, useEffect, useState } from "react";
import { useResizable } from "react-resizable-layout";
import { BreadCrumbMenu } from "../Components/common/BreadCrumb";

interface LayoutContextType {
  isSidebarDragging: boolean;
  sidebarWidth: number;
  sidebarDragBarProps: Omit<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLHRElement>, HTMLHRElement>,
    "ref"
  >;
  isChatDragging: boolean;
  chatWidth: number;
  chatDragBarProps: Omit<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLHRElement>, HTMLHRElement>,
    "ref"
  >;
  sidebarOpen: boolean;
  deviceType: DeviceType;
  breadCrumbs: BreadCrumbMenu[];
  setBreadCrumbs: (arg: BreadCrumbMenu[]) => void;
  setChatPosition: React.Dispatch<React.SetStateAction<number>>;
  setSidebarOpen: (arg: boolean) => void;
}

type DeviceType = "mobile" | "laptop" | "pc";

const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

export const LayoutContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const {
    isDragging: isSidebarDragging,
    position: sidebarWidth,
    splitterProps: sidebarDragBarProps,
  } = useResizable({
    axis: "x",
    initial: 300,
    min: 300,
    max: 700,
  });
  const {
    isDragging: isChatDragging,
    position: chatWidth,
    splitterProps: chatDragBarProps,
    setPosition: setChatPosition,
  } = useResizable({
    axis: "x",
    initial: 10000,
    min: 0,
    reverse: true,
  });
  const [breadCrumbs, setBreadCrumbs] = useState<BreadCrumbMenu[]>([]);
  const [deviceType, setDeviceType] = useState<DeviceType>("pc");

  const handleResize = () => {
    if (window.innerWidth < 1280) {
      setDeviceType("laptop");
    } else {
      setDeviceType("pc");
    }
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let contextValue = {
    isSidebarDragging,
    sidebarWidth,
    sidebarDragBarProps,
    isChatDragging,
    chatWidth,
    chatDragBarProps,
    sidebarOpen,
    breadCrumbs,
    deviceType,
    setBreadCrumbs,
    setChatPosition,
    setSidebarOpen,
  };

  return (
    <LayoutContext.Provider value={contextValue}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = () => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error(
      "useUserContext must be used within a LayoutContextProvider"
    );
  }
  return context;
};
