import { type FC } from "react";

const PieChartIcon: FC<any> = (props: any) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      stroke="black"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2748 13.8891C17.9495 13.8891 18.5158 14.446 18.4127 15.1123C17.8074 19.0323 14.4516 21.9428 10.4042 21.9428C5.92635 21.9428 2.29688 18.3134 2.29688 13.8365C2.29688 10.1481 5.09898 6.7123 8.25372 5.93546C8.93161 5.76809 9.62635 6.24493 9.62635 6.94283C9.62635 11.6712 9.7853 12.8944 10.6832 13.5597C11.5811 14.2249 12.6369 13.8891 17.2748 13.8891Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6921 9.9517C21.7458 6.9138 18.0142 2.01696 13.4669 2.10117C13.1132 2.10749 12.83 2.40222 12.8142 2.75485C12.6995 5.25275 12.8542 8.48959 12.9406 9.95696C12.9669 10.4138 13.3258 10.7727 13.7816 10.7991C15.29 10.8854 18.6448 11.0033 21.1069 10.6306C21.4416 10.5801 21.6869 10.2896 21.6921 9.9517Z"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PieChartIcon
