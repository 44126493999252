"use client";

import { forwardRef, useEffect, useImperativeHandle } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { Plate } from "@udecode/plate-common/react";

import { useCreateEditor } from "./use-create-editor";
import { Editor, EditorContainer } from "../plate-ui/editor";
import remarkEmoji from "remark-emoji";
import { deserializeMd } from "@udecode/plate-markdown";

interface PlateEditorProps {
  initialMarkdown?: string;
  value?: string;
  readOnly?: boolean;
  onChange?: (value: string) => void;
}

export interface PlateEditorRef {
  editor: any;
  setValue: (value: any) => void;
}

export const PlateEditor = forwardRef(({
  initialMarkdown = "",
  value,
  readOnly = false,
  onChange,
}: PlateEditorProps, ref) => {
  const editor = useCreateEditor({ initialMarkdown, readOnly });

  useEffect(() => {
    if (editor) {
      // console.log(initialMarkdown);
      // console.log(editor.api.markdown.deserialize(initialMarkdown));
      if (typeof initialMarkdown === "string")
        editor.tf.setValue(editor.api.markdown.deserialize(initialMarkdown));
      else editor.tf.setValue(initialMarkdown);
    }
  }, [initialMarkdown, editor]);

  const setValue = (value: any) => {
    editor.tf.setValue(value);
  };

  useImperativeHandle(ref, () => ({
    editor,
    setValue,
  }));

  return (
    <DndProvider backend={HTML5Backend}>
      <Plate
        editor={editor}
        onChange={(e) => {
          const serializedValue = editor.api.markdown.serialize(e.value);
          const markdownString = serializedValue
            .replaceAll("- <br>", "\n - ")
            .replaceAll("<br>", "");
          // console.log(serializedValue);

          if (onChange)
            // onChange(markdownString)
            onChange(
              e.value.map((item: any) => {
                const { id, ...rest } = item;
                return rest;
              })
            );
        }}
      >
        <EditorContainer>
          <Editor readOnly={readOnly} />
        </EditorContainer>
      </Plate>
    </DndProvider>
  );
});
