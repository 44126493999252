import { type FC } from "react";

const TrashIcon: FC<any> = (props: any) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      stroke="#EF4444"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.3238 9.46777C19.3238 9.46777 18.7808 16.2028 18.4658 19.0398C18.3158 20.3948 17.4788 21.1888 16.1078 21.2138C13.4988 21.2608 10.8868 21.2638 8.27881 21.2088C6.95981 21.1818 6.13681 20.3778 5.98981 19.0468C5.67281 16.1848 5.13281 9.46777 5.13281 9.46777"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.708 6.23926H3.75"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4386 6.23949C16.6536 6.23949 15.9776 5.68449 15.8236 4.91549L15.5806 3.69949C15.4306 3.13849 14.9226 2.75049 14.3436 2.75049H10.1106C9.53163 2.75049 9.02363 3.13849 8.87363 3.69949L8.63063 4.91549C8.47663 5.68449 7.80063 6.23949 7.01562 6.23949"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TrashIcon
