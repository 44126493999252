import { type FC, useState, useEffect } from "react";
import { toast } from "react-toastify";
import searchIcon from "../../assets/svg/search.svg";
import { GrLinkNext } from "react-icons/gr";
import axios from "axios";
import Folder from "./Folder";
import { useUserContext } from "../../contexts/UserContext";
import { useRagContext } from "../../contexts/RagContext";
import moment from "moment";
import { useHistory } from "react-router";
import { useLayoutContext } from "../../contexts/LayoutContext";

export interface MyCustomCSS extends React.CSSProperties {
  "--line-clamp": number;
  "--width": string;
  "--font-size": string;
  "--align": string;
}



const Sidebar: FC = () => {
  const [searchText, setSearchText] = useState("");
  const [unfilteredFolders, setUnfilteredFolders] = useState<any[]>([]);
  const [shownFolders, setShownFolders] = useState<any[]>([]);

  const { user } = useUserContext();
  const history = useHistory();
  const {
    sourceList,                // <-- We read from context
    selectedFiles,
    setting,
    socketId,
    fetchSources,
    openConceptAddFolderModal,
    getBrainstormList,
    openFileManagerModal,
    buildNestedStructureFromSourceList,
    createFolderArray,
    traverseAndFilter,
    openShareFeedbackModal,
  } = useRagContext();

  const { deviceType, setChatPosition } = useLayoutContext();

  // 1) On mount or whenever sourceList changes, build "Team Documents" + "My Documents"
  useEffect(() => {
    if (!sourceList || !Array.isArray(sourceList) || sourceList.length === 0) {
      setUnfilteredFolders([]);
      setShownFolders([]);
      return;
    }

    // Build separate nested objects
    const { teamRoot, userRoot } = buildNestedStructureFromSourceList(sourceList);

    // Wrap them in the array of objects that "Folder" expects
    const newFolders = createFolderArray(teamRoot, userRoot);

    setUnfilteredFolders(newFolders);
    setShownFolders(newFolders);
  }, [sourceList]);

  // ------------------------------------------------------------------------------
  // useEffect: Filter shownFolders based on searchText
  // ------------------------------------------------------------------------------
  useEffect(() => {
    if (searchText.trim() === "") {
      // If no search text, show all folders
      setShownFolders(unfilteredFolders);
      return;
    }

    const lowerSearch = searchText.toLowerCase();
    const tempList = JSON.parse(JSON.stringify(unfilteredFolders));

    tempList.forEach((folderObj: any) => {
      if (folderObj.structure) {
        traverseAndFilter(folderObj.structure, lowerSearch);
      }
    });

    const filtered = tempList.filter(
      (f: any) => Object.keys(f.structure).length > 0
    );
    setShownFolders(filtered);
  }, [searchText, unfilteredFolders]);

  const handleStartOCR = async (
    target_path: string,
    file_name: string
  ) => {
    try {
      // Decide if it's team or user scope
      const source_scope = target_path.startsWith("My Documents") ? "user" : "team";
      const result = await axios.post(
        process.env.REACT_APP_API_URL + "api/documents/ocr",
        {
          file_name,
          target_path,
          team_id: user?.organizationMemberships[0]?.organization.id,
          user_id: user?.id,
          application_name: process.env.REACT_APP_APPLICATION_NAME,
          source_scope,
        }
      );
      if (result.status === 200) {
        fetchSources();
        toast.success("OCR processed successfully", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          toastId: "my_toast",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // 5) Create new chat
  const handleCreateNewChat = () => {
    setChatPosition(10000);
    window.postMessage({ type: "create_new_chat" }, "*");
  };

  // ------------------------------------------------------------------------------
  // handleGetHMWQuestions: Triggers a Brainstorm creation, then navigates to concept
  // ------------------------------------------------------------------------------
  const handleGetHMWQuestions = async () => {
    try {
      let params: any = {
        team_id: user?.organizationMemberships[0]?.organization.id,
        user_id: user?.id,
        name: "Brainstorm " + moment().format("YYYY-MM-DD h:mm a"),
        context: "",
        fromSource: true,
        socketId,
      };
      params.source_list = selectedFiles
      const result = await axios.post(
        process.env.REACT_APP_API_URL + "api/getHMWQuestions",
        params
      );

      const searchParams = new URLSearchParams();
      searchParams.set("render_type", "modal");
      searchParams.set("render_modal", "brainstorm_modal");
      searchParams.set("brainstorm_id", result.data.brainstorm_id);

      history.push({
        pathname: "/concept",
        search: `?${searchParams.toString()}`,
      });
      getBrainstormList();
    } catch (error) {
      console.log(error);
    }
  };

  // ------------------------------------------------------------------------------
  // Render
  // ------------------------------------------------------------------------------
  return (
    <div className="flex flex-col h-full w-[243px] xl:w-[324px]">
      <div className="flex flex-col gap-3 xl:gap-4 flex-1 p-[15px] xl:p-5">
        {/* Search Input */}
        <div className="relative w-full rounded-xl bg-white px-3 xl:px-4 py-[9px] xl:py-3 flex gap-[9px] xl:gap-3 items-center">
          <img
            src={searchIcon}
            alt="Search"
            className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none"
          />
          <input
            type="text"
            className="flex-1 text-[10px] xl:text-sm leading-4 xl:leading-[22px] text-[#A1A1AA] font-medium outline-none"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search or type a command"
          />
        </div>

        {/* Body */}
        <div className="flex flex-col justify-between h-[calc(100vh-157px)] xl:h-[calc(100vh-210px)]">
          <div className="flex-1 overflow-y-auto overflow-x-hidden block">
            <div>
              <div className="flex items-center justify-between">
                <div className="py-[3px] xl:py-1 px-[7px] xl:px-[10px] text-[10px] xl:text-sm leading-4 xl:leading-[22px] font-bold text-[#00000067]">
                  {shownFolders.length} Folders
                </div>
                <button
                  className="p-0 outline-none border-none bg-transparent text-[#2563EB] text-[10px] xl:text-sm leading-4 xl:leading-[22px] font-bold"
                  onClick={() => openFileManagerModal()}
                  disabled={!setting?.verified}
                  data-event-name="Research: Folder Manager Clicked"
                >
                  Manage
                </button>
              </div>

              {/* Map each folder object => <Folder /> */}
              <div
                className="overflow-x-auto overflow-y-auto w-full"
                style={{
                  height: selectedFiles.length
                    ? `calc(100vh - ${414 * (deviceType === "laptop" ? 0.75 : 1)}px)`
                    : `calc(100vh - ${240 * (deviceType === "laptop" ? 0.75 : 1)}px)`,
                }}
                id="folder-container"
              >
                <div className="w-fit min-w-full">
                  {shownFolders.map((folderObj: any, index: number) => (
                    <Folder
                      key={index}
                      folder={folderObj}
                      handleStartOCR={handleStartOCR}
                    />
                  ))}
                </div>
              </div>

              {/* Action buttons if user selected files */}
              {selectedFiles.length > 0 && (
                <div className="flex-col gap-[7px] xl:gap-[10px] mt-[7px] xl:mt-[10px] flex">
                  <button
                    className="w-full h-9 xl:h-12 rounded-xl bg-[#3B82F6] outline-none flex items-center justify-center gap-[6px] xl:gap-2"
                    onClick={() => openConceptAddFolderModal("selected_source")}
                    data-event-name="Research: Concept From Selected Source Created"
                  >
                    <span className="text-white text-xs xl:text-base font-bold font-Satoshi">
                      Create New Concept
                    </span>
                    <GrLinkNext className="text-white text-xs xl:text-base font-bold font-Satoshi" />
                  </button>
                  <button
                    className="w-full h-9 xl:h-12 rounded-xl bg-[#3B82F6] outline-none flex items-center justify-center gap-[6px] xl:gap-2"
                    onClick={handleCreateNewChat}
                    data-event-name="Research: Chat From Selected Source Created"
                  >
                    <span className="text-white text-xs xl:text-base font-bold font-Satoshi">
                      Create New Chat
                    </span>
                    <GrLinkNext className="text-white text-xs xl:text-base font-bold font-Satoshi" />
                  </button>
                  <button
                    className="w-full h-9 xl:h-12 rounded-xl bg-[#3B82F6] outline-none flex items-center justify-center gap-[6px] xl:gap-2"
                    onClick={handleGetHMWQuestions}
                    data-event-name="Research: Brainstorm from Selected Source Created"
                  >
                    <span className="text-white text-xs xl:text-base font-bold font-Satoshi">
                      Start Brainstorm
                    </span>
                    <GrLinkNext className="text-white text-xs xl:text-base font-bold font-Satoshi" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
