import { type ChangeEvent, type FC, type MouseEvent, useEffect, useMemo, useRef, useState } from "react";
import { useRagContext } from "../../../contexts/RagContext";
import GridIcon from "../../icons/GridIcon";
import ListIcon from "../../icons/ListIcon";
import FilterIcon from "../../icons/FilterIcon";
import HamburgerIcon from "../../icons/HambugerIcon";
import FileManagerEmptyFileIcon from "../../../assets/svg/file-mananger-empty-file.svg";
import { GoPlus } from "react-icons/go";
import RenameFolderModal from "./RenameFolderModal/RenameFolderModal";
import AddFolderModal from "./AddFolderModal/AddFolderModal";
import { IoCloseOutline } from "react-icons/io5";
import FolderIcon from "../../../assets/svg/folder.svg";
import FilePDFIcon from "../../../assets/svg/file-pdf.svg";
import FileDOCIcon from "../../../assets/svg/file-doc.svg";
import FilePPTIcon from "../../../assets/svg/file-ppt.svg";
import FileXLSXIcon from "../../../assets/svg/file-xlsx.svg";
import ContentIcon from "../../../assets/svg/content-icon.svg";
import ThreeDotsIcon from "../../icons/ThreeDotsIcon";
import BpCheckbox from "../../common/BPCheckbox";
import { Menu, MenuItem, MenuProps, styled } from "@mui/material";
import TrashIcon from "../../icons/TrashIcon";
import EditIcon from "../../icons/EditIcon";
import axios from "axios";
import { useUserContext } from "../../../contexts/UserContext";
import { MdFileUpload, MdOutlineDriveFolderUpload } from "react-icons/md";
import UploadFileModal from "./UploadFileModal/UploadFileModal";
import { useLayoutContext } from "../../../contexts/LayoutContext";
import DeleteModal from "./DeleteModal/DeleteModal";

// FIXME: Still relevant after recent changes?
// import { FileManagerModalActionButton } from "./FileManagerModalActionButton";

import { getExtension } from "../../../utils";

// -----------------------------------------------------------------------------
// 1) We create a FolderNode type for each folder/file
// -----------------------------------------------------------------------------
interface FolderNode {
  name: string;                           // e.g. "test case 1"
  isFolder: boolean;                      // true if folder, false if file
  children: Record<string, FolderNode>;   // subfolders if folder
  filePath?: string;                      // path if file
}

// -----------------------------------------------------------------------------
//) "buildNestedStructureFromSourceList" -> convert  sourceList into two roots
//    (teamRoot for "/.../orgs/..." and userRoot for "/.../users/...").
function buildNestedStructureFromSourceList(items: any[]) {
  const teamRoot: any = {};
  const userRoot: any = {};

  items.forEach((item: any) => {
    if (!item.filePath) return;

    // Remove leading slash if present
    let path = item.filePath;
    if (path.startsWith("/")) {
      path = path.slice(1);
    }
    // e.g. "development/orgs/org_ABC/folderA/file.pdf"

    const parts = path.split("/"); // ["development","orgs","org_ABC","folderA","file.pdf"]
    const docType = parts[1];      // "orgs" or "users"
    const meaningfulParts = parts.slice(3); // skip first 3 => ["folderA","file.pdf"]

    // Decide if it's Team docs or My docs
    let currentRoot = docType === "users" ? userRoot : teamRoot;

    // Walk subfolders
    let cursor = currentRoot;
    for (let i = 0; i < meaningfulParts.length; i++) {
      const seg = meaningfulParts[i];
      if (!seg) continue;
      const isLast = i === meaningfulParts.length - 1;

      if (item.type === "file" && isLast) {
        // Mark file => store object or just `null`
        // We'll store null => then transform to FolderNode with filePath
        cursor[seg] = null;
      } else {
        if (!cursor[seg]) {
          cursor[seg] = {};
        }
        cursor = cursor[seg];
      }
    }
  });

  return { teamRoot, userRoot };
}

// -----------------------------------------------------------------------------
// 3) "structureToNode" -> recursively convert the nested objects into FolderNodes
//    If value === null => file => we store filePath
// -----------------------------------------------------------------------------
function structureToNode(name: string, value: any, parentPath = ""): FolderNode {
  // If we see `null` => file
  if (value === null) {
    return {
      name,
      isFolder: false,
      children: {},
      filePath: parentPath ? parentPath + "/" + name : name,
    };
  }

  // Otherwise => folder => recurse children
  const children: Record<string, FolderNode> = {};
  for (const [childName, childVal] of Object.entries(value)) {
    children[childName] = structureToNode(
      childName,
      childVal,
      parentPath ? parentPath + "/" + name : name
    );
  }
  return {
    name,
    isFolder: true,
    children,
  };
}

// Icons by extension
const fileIcons: Record<string, string> = {
  pdf: FilePDFIcon,
  doc: FileDOCIcon,
  docx: FileDOCIcon,
  ppt: FilePPTIcon,
  pptx: FilePPTIcon,
  xls: FileXLSXIcon,
  xlsx: FileXLSXIcon,
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    transformOrigin={{ horizontal: "right", vertical: "top" }}
    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 14,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
    "rgb(255, 255, 255) 0px 0px 0px 0px, " +
    "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, " +
    "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, " +
    "rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "6px",
    },
    "& .MuiMenuItem-root": {
      padding: "10px 12px",
      gap: "12px",
      width: "224px",
      borderRadius: "12px",
    },
  },
}));

// -----------------------------------------------------------------------------
// FILE MANAGER MODAL
// -----------------------------------------------------------------------------

const FileManagerModal: FC = () => {
  const {
    fileManagerModalVisible,
    closeFileManagerModal,
    sourceList,
    setting,
    socketId,
    selectedUploadFiles,
    setSelectedUploadFiles,
    fetchSources,
  } = useRagContext();

  const { user } = useUserContext();
  const { setBreadCrumbs } = useLayoutContext();

  // UI states
  const [listType, setListType] = useState<"grid" | "list">("grid");
  const [parentFolders, setParentFolders] = useState<string[]>([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [renameFolderModalOpen, setRenameFolderModalOpen] = useState(false);
  const [addFolderModalOpen, setAddFolderModalOpen] = useState(false);
  const [isSelectFolder, setIsSelectFolder] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState<FolderNode | null>(null);
  const [selectedFile, setSelectedFile] = useState<FolderNode | null>(null);
  const [filterType, setFilterType] = useState("all");
  const [sortType, setSortType] = useState("");

  // Menus
  const [folderMenuAnchorEl, setFolderMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const folderMenuOpen = Boolean(folderMenuAnchorEl);
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const filterMenuOpen = Boolean(filterMenuAnchorEl);
  const [sortMenuAnchorEl, setSortMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const sortMenuOpen = Boolean(sortMenuAnchorEl);
  const [uploadMenuAnchorEl, setUploadMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const uploadMenuOpen = Boolean(uploadMenuAnchorEl);

  // Upload
  const [isFolderUpload, setFolderUpload] = useState(false);
  const [isInRootFolder, setIsInRootFolder] = useState(false);
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const folderUploadRef = useRef<HTMLInputElement>(null);

  // If user selected files in the hidden input => handleAddDocument
  useEffect(() => {
    if (selectedUploadFiles?.length) {
      handleAddDocument();
    }
  }, [selectedUploadFiles]);

  // ---------------------------------------------------------------------------
  // 1) Build a "rootNode" from sourceList => "Team Documents" + "My Documents"
  // ---------------------------------------------------------------------------
  const rootNode = useMemo(() => {
    if (!sourceList || sourceList.length === 0) return null;
    // 1) Convert to {teamRoot, userRoot}
    const { teamRoot, userRoot } = buildNestedStructureFromSourceList(sourceList);

    // 2) Convert to FolderNode
    const teamDocs = structureToNode("Team Documents", teamRoot, "");
    const myDocs = structureToNode("My Documents", userRoot, "");

    return {
      name: "root",
      isFolder: true,
      children: {
        "Team Documents": teamDocs,
        "My Documents": myDocs,
      },
    } as FolderNode;
  }, [sourceList]);

  // ---------------------------------------------------------------------------
  // 4) Recursively get the "current folder node" from parentFolders
  // ---------------------------------------------------------------------------
  function getCurrentFolderNode(): FolderNode | null {
    if (!rootNode) return null;
    let node = rootNode;

    for (const folderName of parentFolders) {
      if (!node.children[folderName]) {
        return null; // path doesn't exist
      }
      node = node.children[folderName];
    }
    return node;
  }

  // ---------------------------------------------------------------------------
  // 3) Convert current folder's children => array => filter + sort
  // ---------------------------------------------------------------------------
  function getCurrentFolderContents(): FolderNode[] {
    const node = getCurrentFolderNode();
    if (!node) return [];

    let items = Object.values(node.children);

    // Filter
    items = items.filter((item) => {
      if (filterType === "all") return true;
      if (item.isFolder) return true;  // always keep folders
      const extension = getExtension(item.filePath || item.name);
      return extension === filterType;
    });

    // Sort
    if (sortType) {
      items.sort((a, b) => {
        // 1) Folders vs. files
        if (a.isFolder && !b.isFolder) {
          return sortType === "ascending" ? -1 : sortType === "descending" ? 1 : 0;
        }
        if (!a.isFolder && b.isFolder) {
          return sortType === "ascending" ? 1 : sortType === "descending" ? -1 : 0;
        }

        // 2) Both folders or both files => sort by name
        if (sortType === "ascending") {
          return a.name.localeCompare(b.name);
        } else if (sortType === "descending") {
          return b.name.localeCompare(a.name);
        } else if (sortType === "typeasc" || sortType === "typedisc") {
          // If we had a known extension, we could do a custom sort
          // but right now we only have "name". We'll do normal name sort
          return a.name.localeCompare(b.name);
        }
        // fallback
        return 0;
      });
    }

    return items;
  }

  const currentItems = getCurrentFolderContents();

  // ---------------------------------------------------------------------------
  // Check if entire structure is empty (=> "No Items" screen)
  // ---------------------------------------------------------------------------
  const isEmptyStructure = useMemo(() => {
    if (!sourceList || sourceList.length === 0) return true;
    return false;
  }, [sourceList]);

  // ---------------------------------------------------------------------------
  // 4) Folder navigation
  // ---------------------------------------------------------------------------
  function openFolder(folderName: string) {
    setParentFolders((prev) => [...prev, folderName]);
  }

  // ---------------------------------------------------------------------------
  // 5) Upload logic (unchanged from  code). 
  // ---------------------------------------------------------------------------
  const handleFileUpload = (
    event: ChangeEvent<HTMLInputElement>,
    isFolder: boolean
  ) => {
    setUploadMenuAnchorEl(null);
    if (event.target.files) {
      const filesArray = Array.from(event.target.files);
      const allowedExtensions = /\.(pdf|docx|pptx|xlsx)$/i; // Allow PDF, DOCX, PPTX, and XLSX files
      const uploaded: any = [];
      const folderStructure: any = {};

      filesArray.forEach((file) => {
        if (allowedExtensions.exec(file.name)) {
          uploaded.push(file);

          // Construct folder structure
          if (isFolder) {
            const pathParts = file.webkitRelativePath.split("/");
            let currentLevel = folderStructure;

            pathParts.forEach((part, index) => {
              if (index === pathParts.length - 1) {
                if (!currentLevel.files) {
                  currentLevel.files = [];
                }
                currentLevel.files.push(file);
              } else {
                if (!currentLevel[part]) {
                  currentLevel[part] = {};
                }
                currentLevel = currentLevel[part];
              }
            });
          }
        }
      });

      if (isFolder) {
        // setUploadFolderStructure(folderStructure)
      }
      setSelectedUploadFiles(uploaded);
    }
  };

  const handleAddDocument = async () => {
    if (!selectedUploadFiles?.length) return;
    // make a boolean to check if the folder is being uploaded in "My Documents" or "Team Documents" called documentType
    let source_scope = parentFolders[0] === "Team Documents" ? "team" : "user";
    // Create a copy of parentFolders and use it for manipulation
    const targetFolders = [...parentFolders];
    targetFolders.shift(); // Remove the first element for constructing the target path
    const target_path = targetFolders.join("/");

    const team_id = user?.organizationMemberships[0]?.organization.id || "";
    const application_name = process.env.REACT_APP_APPLICATION_NAME as string;
    const uploadBatch = async (filesBatch: any, index: any) => {
      const batchData = new FormData();
      batchData.append("team_id", team_id);
      batchData.append("user_id", user?.id ?? '');
      batchData.append("source_scope", source_scope);
      batchData.append("application_name", application_name);
      // remove leading slash if desired
      batchData.append("uri", target_path);
      batchData.append("socketId", socketId);
      batchData.append("batchCount", index);
      for (let i = 0; i < filesBatch.length; i++) {
        isFolderUpload
          ? batchData.append(
              "files",
              filesBatch[i],
              filesBatch[i].webkitRelativePath
            )
          : batchData.append("files", filesBatch[i]);
      }

      try {
        await axios.post(process.env.REACT_APP_API_URL + "api/documents", batchData);
      } catch (error) {
        console.error("Upload error:", error);
      }
    };

    const chunkSize = 5;
    const filesArray = Array.from(selectedUploadFiles);

    for (let i = 0; i < filesArray.length; i += chunkSize) {
      const chunk = filesArray.slice(i, i + chunkSize);
      await uploadBatch(chunk, i);
    }

    // cleanup
    if (fileUploadRef.current) fileUploadRef.current.value = "";
    if (folderUploadRef.current) folderUploadRef.current.value = "";
    setSelectedUploadFiles([]); // reset
    fetchSources(); // refetch structure
  }

  // ---------------------------------------------------------------------------
  // 6) BreadCrumbs
  // ---------------------------------------------------------------------------
  useEffect(() => {
    if (fileManagerModalVisible) {
      setBreadCrumbs([
        {
          title: "Research",
          active: false,
          onClick: () => {
            closeFileManagerModal();
          },
        },
        {
          title: "File Manager",
          active: parentFolders.length === 0,
          onClick: () => {
            setParentFolders([]);
          },
        },
        ...parentFolders.map((name, idx) => ({
          title: name,
          active: idx === parentFolders.length - 1,
          onClick: () => {
            if (idx !== parentFolders.length - 1) {
              setParentFolders((prev) => prev.slice(0, idx + 1));
            }
          },
        })),
      ]);
    } else {
      setBreadCrumbs([
        {
          title: "Research",
          active: true,
          onClick: () => {},
        },
      ]);
    }
  }, [fileManagerModalVisible, parentFolders, closeFileManagerModal, setBreadCrumbs]);
  
  // ---------------------------------------------------------------------------
  // A Hook to check if we are in the root folders: Team Documents or My Documents
  // ---------------------------------------------------------------------------
  useEffect(() => {
    setIsInRootFolder(parentFolders.length === 1 && (parentFolders[0] === "Team Documents" || parentFolders[0] === "My Documents"));
  } , [parentFolders]);

  // ---------------------------------------------------------------------------
  // 7) Menus & Modals
  // ---------------------------------------------------------------------------
  const handleFolderMenuClose = () => setFolderMenuAnchorEl(null);
  const handleFilterMenuClose = () => setFilterMenuAnchorEl(null);
  const handleSortMenuClose = () => setSortMenuAnchorEl(null);
  const handleUploadMenuClose = () => setUploadMenuAnchorEl(null);

  return (
    <div
      className="w-full h-full absolute top-0 left-0 z-[1000] bg-white"
      hidden={!fileManagerModalVisible}
    >
      {/* HEADER */}
      <div className="px-[30px] xl:px-10 pt-3 xl:pt-4 pb-[18px] xl:pb-6 flex justify-between">
        <div className="flex justify-between items-center w-full">
          <div>
            <div className="text-[15px] xl:text-xl font-bold text-[#27272A]">
              File Manager
            </div>
          </div>
          <div className="flex items-center gap-[3px] xl:gap-1">
            {/* Toggle grid/list */}
            <div className={`file-mananger-list-type ${listType}-type`}>
              <div
                className="file-mananger-list-type-grid"
                onClick={() => setListType("grid")}
              >
                <GridIcon className="w-[18px] xl:w-6 h-[18px] xl:h-6" />
              </div>
              <div
                className="file-mananger-list-type-list"
                onClick={() => setListType("list")}
              >
                <ListIcon className="w-[18px] xl:w-6 h-[18px] xl:h-6" />
              </div>
              <div className="file-mananger-list-type-bg" />
            </div>

            {/* Filter */}
            <button
              className="outline-none w-[30px] xl:w-10 h-[30px] xl:h-10 border border-[#D4D4D8] items-center justify-center rounded-lg flex"
              onClick={(e: MouseEvent<HTMLButtonElement>) => setFilterMenuAnchorEl(e.currentTarget)}
            >
              <FilterIcon className="w-[15px] xl:w-5 h-[15px] xl:h-5" />
            </button>

            {/* Sort */}
            <button
              className="outline-none w-[30px] xl:w-10 h-[30px] xl:h-10 border border-[#D4D4D8] items-center justify-center rounded-lg flex"
              onClick={(e: MouseEvent<HTMLButtonElement>) => setSortMenuAnchorEl(e.currentTarget)}
            >
              <HamburgerIcon className="w-[15px] xl:w-5 h-[15px] xl:h-5" />
            </button>
          </div>
        </div>
      </div>

      {/* BODY */}
      <div className="px-[30px] xl:px-10 relative h-[calc(100vh-130px)] xl:h-[calc(100vh-174px)]">
        {isEmptyStructure ? (
          <div className="py-[111px] xl:py-[148px]">
            <div className="flex flex-col items-center">
              <img src={FileManagerEmptyFileIcon} alt="" />
              <div className="mb-[6px] xl:mb-2 text-[15px] xl:text-xl text-[#27272A] font-bold">
                No Items Yet!
              </div>
              <div className="text-xs xl:text-base text-[#71717A] font-normal mb-[30px] xl:mb-10">
                Looks like you haven’t added any items. Add some here.
              </div>
              <button
                className="h-[21px] xl:h-12 rounded-xl bg-[#3B82F6] outline-none flex items-center justify-center gap-[6px] xl:gap-2 w-[165px] xl:w-[220px]"
                onClick={() => setAddFolderModalOpen(true)}
              >
                <GoPlus className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-white" />
                <span className="text-white text-xs xl:text-base font-bold font-Satoshi">
                  Create New Folder
                </span>
              </button>
            </div>
          </div>
        ) : (
            // If not empty, render our current items in grid or list
          <>
            {listType === "grid" ? (
              <div className="flex flex-wrap gap-3 xl:gap-4 h-[calc(100vh-156px)] xl:h-[calc(100vh-209px)] overflow-auto content-baseline">
                {currentItems.map((item, index) => {
                  if (item.isFolder) {
                    const childrenCount = Object.keys(item.children).length;
                    return (
                      <div
                        key={index}
                        className="w-[160px] xl:w-[213px] h-[140px] xl:h-[186px] rounded-xl border border-solid border-[#E4E4E7] relative flex items-center justify-center cursor-pointer group"
                        onClick={() => openFolder(item.name)}
                      >
                        <img
                          src={FolderIcon}
                          alt="Folder"
                          className="-translate-y-[7px] xl:-translate-y-[10px] w-[55px] xl:w-[74px] h-[55px] xl:h-[74px]"
                        />
                        <div className="absolute w-full left-0 bottom-0 py-[6px] xl:py-2 pl-3 xl:pl-4 pr-[9px] xl:pr-3 flex gap-[3px] xl:gap-1 justify-between">
                          <div className="w-[calc(100%-21px)] xl:w-[calc(100%-28px)]">
                            <div className="text-[10px] xl:text-sm leading-[15px] xl:leading-[20px] text-[#09090B] font-semibold mb-[1.5px] xl:mb-[2px] w-full whitespace-nowrap overflow-hidden text-ellipsis">
                              {item.name}
                            </div>
                            <div className="text-[9px] xl:text-xs leading-[15px] xl:leading-[20px] text-[#71717A] font-normal">
                              {childrenCount} Items
                            </div>
                          </div>
                          {item.name !== "My Documents" && item.name !== "Team Documents" && (
                            <button
                              className="border-none outline-none flex-none w-[18px] xl:w-6 h-[18px] xl:h-6"
                              onClick={(e) => {
                                e.stopPropagation();
                                setIsSelectFolder(true);
                                setSelectedFolder(item);
                                setFolderMenuAnchorEl(e.currentTarget);
                              }}
                            >
                              <ThreeDotsIcon className="w-[18px] xl:w-6 h-[18px] xl:h-6" />
                            </button>
                          )}
                        </div>
                        <BpCheckbox
                          size="small"
                          className={`w-[14px] xl:w-[18px] h-[14px] xl:h-[18px] !absolute top-[11px] xl:top-[15px] right-[11px] xl:right-[15px] !hidden`}
                          checked={false}
                        />
                      </div>
                    );
                  } else {
                    // It's a file => use filePath
                    const extension = getExtension(item.filePath || item.name) || "pdf";
                    return (
                      <div
                        key={index}
                        className="w-[160px] xl:w-[213px] h-[140px] xl:h-[186px] rounded-xl border border-solid border-[#E4E4E7] relative flex items-center justify-center cursor-pointer group"
                      >
                        <img
                          src={fileIcons[extension] || FilePDFIcon}
                          alt="File"
                          className="-translate-y-[7px] xl:-translate-y-[10px] w-[55px] xl:w-[74px] h-[55px] xl:h-[74px]"
                        />
                        <div className="absolute w-full left-0 bottom-0 py-[6px] xl:py-2 pl-3 xl:pl-4 pr-[9px] xl:pr-3 flex gap-[3px] xl:gap-1 justify-between">
                          <div className="w-[calc(100%-21px)] xl:w-[calc(100%-28px)]">
                            <div className="text-[10px] xl:text-sm leading-[15px] xl:leading-[20px] text-[#09090B] font-semibold mb-[1.5px] xl:mb-[2px] w-full whitespace-nowrap overflow-hidden text-ellipsis">
                              {item.name}
                            </div>
                            <div className="text-[9px] xl:text-xs leading-[15px] xl:leading-[20px] text-[#71717A] font-normal">
                              5.6mb
                            </div>
                          </div>
                          <button
                            className="border-none outline-none flex-none w-[18px] xl:w-6 h-[18px] xl:h-6"
                            onClick={(e) => {
                              e.stopPropagation();
                              setIsSelectFolder(false);
                              setSelectedFile(item);
                              setFolderMenuAnchorEl(e.currentTarget);
                            }}
                          >
                            <ThreeDotsIcon className="w-[18px] xl:w-6 h-[18px] xl:h-6" />
                          </button>
                        </div>
                        <BpCheckbox
                          size="small"
                          className={`w-[14px] xl:w-[18px] h-[14px] xl:h-[18px] !absolute top-[11px] xl:top-[15px] right-[11px] xl:right-[15px] !hidden`}
                          checked={false}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            ) : (
              // LIST VIEW
              <div className="relative rounded-2xl overflow-auto h-[calc(100vh-199px)] xl:h-[calc(100vh-265px)]">
                <div className="shadow-sm overflow-hidden">
                  <table className="border-collapse table-auto w-full text-[10px] xl:text-sm">
                    <colgroup>
                      <col className="w-[54px] xl:w-[72px]" />
                      <col className="w-auto" />
                      <col className="w-[184px] xl:w-[245px]" />
                      <col className="w-[184px] xl:w-[245px]" />
                      <col className="w-[75px] xl:w-[100px]" />
                    </colgroup>
                    <thead className="bg-[#DBEAFE]">
                      <tr>
                        <th className="border-b font-medium p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-left">
                          <BpCheckbox size="small" />
                        </th>
                        <th className="border-b font-bold p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-left">
                          Name
                        </th>
                        <th className="border-b font-bold p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-center">
                          Type
                        </th>
                        <th className="border-b font-bold p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-center">
                          Items
                        </th>
                        <th className="border-b font-bold p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-center">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {currentItems.map((item, index) => {
                        const isFolder = item.isFolder;
                        const childrenCount = Object.keys(item.children).length;
                        return (
                          <tr
                            key={index}
                            className="cursor-pointer"
                            onClick={() => {
                              if (isFolder) {
                                openFolder(item.name);
                              }
                            }}
                          >
                            <td className="border-b p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px]">
                              <BpCheckbox size="small" />
                            </td>
                            <td className="border-b p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px]">
                              <div className="flex items-center gap-[3px] xl:gap-1">
                                {isFolder ? (
                                  <img
                                    src={FolderIcon}
                                    alt="Folder"
                                    className="w-[18px] xl:w-6 h-[18px] xl:h-6 -translate-y-[1.5px] xl:-translate-y-[2px]"
                                  />
                                ) : (
                                  <img
                                  src={FilePDFIcon}
                                    alt="File"
                                    className="w-[18px] xl:w-6 h-[18px] xl:h-6 -translate-y-[1.5px] xl:-translate-y-[2px]"
                                  />
                                )}
                                <span>{item.name}</span>
                              </div>
                            </td>
                            <td className="border-b p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-center">
                              {isFolder ? "Folder" : "File"}
                            </td>
                            <td className="border-b p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-center">
                              {isFolder ? `${childrenCount} Items` : ""}
                            </td>
                            <td className="border-b p-[18px] xl:p-6 py-[9px] xl:py-3 text-[#09090B] text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] text-center">
                              <button
                                className="border-none outline-none flex-none w-[18px] xl:w-6 h-[18px] xl:h-6"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (isFolder) {
                                    setIsSelectFolder(true);
                                    setSelectedFolder(item);
                                  } else {
                                    setIsSelectFolder(false);
                                    setSelectedFile(item);
                                  }
                                  setFolderMenuAnchorEl(e.currentTarget);
                                }}
                              >
                                <ThreeDotsIcon className="w-[18px] xl:w-6 h-[18px] xl:h-6" />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </>
        )}

        {/* Floating "+" button to upload */}
        {((!selectedUploadFiles || selectedUploadFiles.length === 0 ) && parentFolders.length !== 0) && (
          <button
            className="w-9 xl:w-12 h-9 xl:h-12 border-none outline-none flex items-center justify-center bg-[#3B82F6] rounded-lg absolute bottom-10 right-10"
            onClick={(e: MouseEvent<HTMLButtonElement>) => setUploadMenuAnchorEl(e.currentTarget)}
          >
            <GoPlus className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-white" />
          </button>
        )}
      </div>

      {/* Folder Menu */}
      <StyledMenu
        id="folder-menu"
        anchorEl={folderMenuAnchorEl}
        open={folderMenuOpen}
        onClose={handleFolderMenuClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
      >
        {isSelectFolder && (
          <MenuItem
            onClick={() => {
              setFolderMenuAnchorEl(null);
              setRenameFolderModalOpen(true);
            }}
            disableRipple
          >
            <EditIcon className="w-[18px] xl:w-6 h-[18px] xl:h-6" />
            <span className="text-[#3F3F46] text-xs xl:text-base font-medium">
              Rename
            </span>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setFolderMenuAnchorEl(null);
            setDeleteModalOpen(true);
          }}
          disableRipple
        >
          <TrashIcon className="w-[18px] xl:w-6 h-[18px] xl:h-6" />
          <span className="text-[#EF4444] text-xs xl:text-base font-medium">
            Delete
          </span>
        </MenuItem>
      </StyledMenu>

      {/* Filter Menu */}
      <StyledMenu
        id="filter-menu"
        anchorEl={filterMenuAnchorEl}
        open={filterMenuOpen}
        onClose={handleFilterMenuClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
      >
        <MenuItem
          className={`${filterType === "all" ? "!bg-[#DBEAFE]" : ""}`}
          onClick={() => {
            setFilterType("all");
            handleFilterMenuClose();
          }}
          disableRipple
        >
          <img
            src={ContentIcon}
            alt=""
            className="w-[18px] xl:w-6 h-[18px] xl:h-6"
          />
          <span className="text-[#3F3F46] text-xs xl:text-base font-medium">
            All File
          </span>
        </MenuItem>
        <MenuItem
          className={`${filterType === "docx" ? "!bg-[#DBEAFE]" : ""}`}
          onClick={() => {
            setFilterType("docx");
            handleFilterMenuClose();
          }}
          disableRipple
        >
          <img
            src={FileDOCIcon}
            alt=""
            className="w-[18px] xl:w-6 h-[18px] xl:h-6"
          />
          <span className="text-[#3F3F46] text-xs xl:text-base font-medium">
            Documents
          </span>
        </MenuItem>
        <MenuItem
          className={`${filterType === "pptx" ? "!bg-[#DBEAFE]" : ""}`}
          onClick={() => {
            setFilterType("pptx");
            handleFilterMenuClose();
          }}
          disableRipple
        >
          <img
            src={FilePPTIcon}
            alt=""
            className="w-[18px] xl:w-6 h-[18px] xl:h-6"
          />
          <span className="text-[#3F3F46] text-xs xl:text-base font-medium">
            Powerpoint
          </span>
        </MenuItem>
        <MenuItem
          className={`${filterType === "xlsx" ? "!bg-[#DBEAFE]" : ""}`}
          onClick={() => {
            setFilterType("xlsx");
            handleFilterMenuClose();
          }}
          disableRipple
        >
          <img
            src={FileXLSXIcon}
            alt=""
            className="w-[18px] xl:w-6 h-[18px] xl:h-6"
          />
          <span className="text-[#3F3F46] text-xs xl:text-base font-medium">
            XLSX
          </span>
        </MenuItem>
        <MenuItem
          className={`${filterType === "pdf" ? "!bg-[#DBEAFE]" : ""}`}
          onClick={() => {
            setFilterType("pdf");
            handleFilterMenuClose();
          }}
          disableRipple
        >
          <img
            src={FilePDFIcon}
            alt=""
            className="w-[18px] xl:w-6 h-[18px] xl:h-6"
          />
          <span className="text-[#3F3F46] text-xs xl:text-base font-medium">
            PDF
          </span>
        </MenuItem>
      </StyledMenu>

      {/* Sort Menu */}
      <StyledMenu
        id="sort-menu"
        anchorEl={sortMenuAnchorEl}
        open={sortMenuOpen}
        onClose={handleSortMenuClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
      >
        <MenuItem
          className={`${sortType === "ascending" ? "!bg-[#DBEAFE]" : ""}`}
          onClick={() => {
            setSortType("ascending");
            handleSortMenuClose();
          }}
          disableRipple
        >
          <span className="text-[#3F3F46] text-xs xl:text-base font-medium">
            Ascending
          </span>
        </MenuItem>
        <MenuItem
          className={`${sortType === "descending" ? "!bg-[#DBEAFE]" : ""}`}
          onClick={() => {
            setSortType("descending");
            handleSortMenuClose();
          }}
          disableRipple
        >
          <span className="text-[#3F3F46] text-xs xl:text-base font-medium">
            Descending
          </span>
        </MenuItem>
        <MenuItem
          className={`${
            sortType === "typeasc" || sortType === "typedisc"
              ? "!bg-[#DBEAFE]"
              : ""
          }`}
          onClick={() => {
            setSortType((prev) =>
              prev === "typeasc" ? "typedisc" : "typeasc"
            );
            handleSortMenuClose();
          }}
          disableRipple
        >
          <span className="text-[#3F3F46] text-xs xl:text-base font-medium">
            Type
          </span>
        </MenuItem>
      </StyledMenu>

      {/* Upload Menu */}
      {parentFolders.length > 0 && (
      <StyledMenu
        id="upload-menu"
        anchorEl={uploadMenuAnchorEl}
        open={uploadMenuOpen}
        onClose={handleUploadMenuClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
      >
        {!isInRootFolder &&(
          <>
            <MenuItem
              disableRipple
              onClick={() => {
                fileUploadRef.current?.click();
              }}
            >
              <MdFileUpload className="text-lg xl:text-2xl flex-none text-[#3F3F46]" />
              <span className="text-[#3F3F46] text-xs xl:text-base font-medium">
                Upload File
              </span>
            </MenuItem>
          </>
        )}
        <MenuItem
          disableRipple
          onClick={() => {
            folderUploadRef.current?.click();
          }}
        >
          <MdOutlineDriveFolderUpload className="text-lg xl:text-2xl flex-none text-[#3F3F46]" />
          <span className="text-[#3F3F46] text-xs xl:text-base font-medium">
            Upload Folder
          </span>
        </MenuItem>
        <MenuItem onClick={() => setAddFolderModalOpen(true)} disableRipple>
          <GoPlus className="text-lg xl:text-2xl flex-none text-[#3F3F46]" />
          <span className="text-[#3F3F46] text-xs xl:text-base font-medium">
            Create Folder
          </span>
        </MenuItem>
      </StyledMenu>
      )}

      {/* Delete Modal */}
      <DeleteModal
        open={deleteModalOpen}
        folder={selectedFolder}
        file={selectedFile}
        isFolder={isSelectFolder}
        parentFolders={parentFolders}
        handleClose={() => setDeleteModalOpen(false)}
      />

      {/* Rename Folder Modal */}
      <RenameFolderModal
        open={renameFolderModalOpen}
        folder={selectedFolder}
        parentFolders={parentFolders}
        handleClose={() => setRenameFolderModalOpen(false)}
      />

      {/* Add Folder Modal */}
      <AddFolderModal
        open={addFolderModalOpen}
        parentFolders={parentFolders}
        handleClose={() => setAddFolderModalOpen(false)}
      />

      {/* Upload File Modal */}
      <UploadFileModal />

      {/* Hidden Inputs for uploading */}
      <input
        type="file"
        accept=".docx, .pdf, .pptx, .xlsx"
        ref={fileUploadRef}
        multiple
        hidden
        name="file_upload"
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleFileUpload(e, false)}
        disabled={!setting?.verified}
      />
      <input
        type="file"
        accept=".docx, .pdf, .pptx, .xlsx"
        ref={folderUploadRef}
        multiple
        hidden
        // @ts-expect-error: webkitdirectory is a non-standard property
        webkitdirectory="true"
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleFileUpload(e, true)}
      />
    </div>
  );
};

export default FileManagerModal;
