import { type FC } from "react";

const HambugerIcon: FC<any> = (props: any) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="#71717A"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.3307 6.29233H1.66406V4.76172H18.3307V6.29233Z"
        fill="inherit"
      />
      <path
        d="M4.69437 9.35356H15.3004V10.8842H4.69437V9.35356Z"
        fill="inherit"
      />
      <path
        d="M12.2701 15.476H7.72467V13.9454H12.2701V15.476Z"
        fill="inherit"
      />
    </svg>
  )
}

export default HambugerIcon
