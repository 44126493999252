import { type FC } from "react";

const FiveForceIcon: FC<any> = (props: any) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#3B82F6"
      stroke="#3B82F6"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={12}
        cy={12}
        r="4.5"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M2.30112 11.5686C2.57267 11.5686 2.84421 11.5627 3.11576 11.5713C3.26013 11.5754 3.32395 11.5297 3.31399 11.3785C3.29767 11.2364 3.29904 11.0927 3.31807 10.9509C3.3416 10.8287 3.39002 10.6499 3.47556 10.6156C3.60319 10.5644 3.82133 10.559 3.91637 10.6368C4.38072 11.0192 4.82153 11.4297 5.26008 11.8433C5.3868 11.9632 5.36598 12.1284 5.23519 12.2456C4.80841 12.6262 4.38479 13.0149 3.93538 13.3652C3.83084 13.4466 3.61269 13.4444 3.47466 13.3982C3.39229 13.3706 3.33888 13.1855 3.31807 13.0638C3.28322 12.8678 3.35472 12.6004 3.24882 12.4859C3.15514 12.385 2.88042 12.4483 2.68536 12.447C2.28619 12.4433 1.88701 12.4524 1.48784 12.4429C1.15022 12.4347 0.944743 12.1913 1.01308 11.9044C1.06241 11.7007 1.22263 11.5763 1.46566 11.5704C1.744 11.5636 2.02279 11.569 2.30112 11.5686Z"
        fill="inherit"
      />
      <path
        d="M12.4426 3.46023C12.6833 3.46023 12.8952 3.44077 13.1011 3.46793C13.2115 3.48241 13.355 3.553 13.4034 3.6417C13.445 3.71954 13.4106 3.88788 13.3473 3.96481C12.9929 4.39517 12.6232 4.81377 12.2507 5.22784C12.1095 5.38442 11.9393 5.38261 11.7981 5.22784C11.4139 4.81332 11.0287 4.39744 10.6667 3.96662C10.6318 3.91748 10.6087 3.86104 10.5989 3.8016C10.5892 3.74215 10.5932 3.68128 10.6105 3.6236C10.6445 3.54259 10.7943 3.47833 10.8997 3.46612C11.107 3.44168 11.3188 3.45887 11.5533 3.45887C11.5587 3.36294 11.5673 3.28374 11.5673 3.2041C11.5673 2.63119 11.5673 2.05827 11.5673 1.48446C11.5673 1.2686 11.6578 1.10116 11.8683 1.02694C12.0493 0.96223 12.2276 1.00567 12.3317 1.1627C12.401 1.27694 12.4381 1.40784 12.439 1.54148C12.4485 2.17005 12.4426 2.79546 12.4426 3.46023Z"
        fill="inherit"
      />
      <path
        d="M20.5457 11.5678H22.3805C22.7887 11.5678 22.9961 11.7096 23.0028 11.9932C23.0096 12.2846 22.791 12.443 22.3791 12.4457C21.792 12.4478 21.2048 12.45 20.6177 12.4521C20.5927 12.4584 20.5686 12.4677 20.5457 12.4797C20.5457 12.6752 20.5634 12.887 20.5389 13.0906C20.5249 13.2033 20.4638 13.3653 20.3805 13.3997C20.2832 13.44 20.109 13.4042 20.0185 13.3332C19.6039 12.9969 19.2038 12.6403 18.8069 12.2846C18.6137 12.1122 18.6177 11.9461 18.8115 11.7678C19.1998 11.4117 19.5854 11.0497 19.9873 10.7093C20.0948 10.6257 20.2306 10.5871 20.3661 10.6016C20.5471 10.6329 20.5494 10.8116 20.5471 10.9687C20.543 11.1555 20.5457 11.3429 20.5457 11.5678Z"
        fill="inherit"
      />
      <path
        d="M12.4398 20.5514C12.4398 21.1999 12.4398 21.8099 12.4398 22.42C12.4422 22.5176 12.4309 22.6151 12.4063 22.7096C12.348 22.9078 12.1963 23.0042 11.999 23.0028C11.8017 23.0015 11.651 22.8983 11.5967 22.701C11.5729 22.5984 11.5627 22.4931 11.5664 22.3878C11.5664 21.7864 11.5664 21.1845 11.5664 20.5514C11.3355 20.5514 11.1251 20.5691 10.9196 20.5442C10.8069 20.5302 10.644 20.4682 10.6101 20.3849C10.5702 20.2872 10.6073 20.1107 10.6784 20.0229C11.0156 19.6079 11.3731 19.2083 11.7293 18.8101C11.8999 18.6191 12.0551 18.6155 12.2271 18.8028C12.6019 19.2056 12.9757 19.6097 13.3323 20.0288C13.4011 20.1093 13.4405 20.2813 13.4011 20.37C13.3617 20.4587 13.2106 20.5288 13.1001 20.5433C12.8929 20.5691 12.682 20.5514 12.4398 20.5514Z"
        fill="inherit"
      />
    </svg>
  )
}

export default FiveForceIcon
