import { type FC } from 'react'
import Modal from "@mui/material/Modal";
import { IoCloseOutline } from "react-icons/io5";
import axios from "axios";
import { useRagContext } from "../../../../contexts/RagContext";
import { useUserContext } from "../../../../contexts/UserContext";

interface DeleteModalProps {
  open: boolean;
  handleClose?: () => void;
  folder?: any;        // The folder node you’re deleting
  file?: any;          // The file node or file name you’re deleting
  isFolder?: boolean;
  parentFolders: string[]; // e.g. ["Team Documents", "test case 1", "subfolder"]
}

const DeleteModal: FC<DeleteModalProps> = ({
  open,
  handleClose,
  folder,
  file,
  isFolder,
  parentFolders,
}) => {
  const { fetchSources } = useRagContext();
  const { user } = useUserContext();

  // Helper: build the "old_path" for a folder 
  // by skipping the first item in parentFolders (Team/My Docs),
  // then appending folder.name
  function buildFolderPath(): string {
    const pathArr = [...parentFolders];
    // e.g. pathArr = ["Team Documents","test case 1","subfolderA"]
    // remove the first item => "Team Documents"
    pathArr.shift();
    // folder.name is the final piece
    if (folder && folder.name) {
      pathArr.push(folder.name);
    }
    return pathArr.join("/");
  }

  interface RequestParams {
    old_path: string;
    application_name: string;
    source_scope: string;
    team_id?: string;
    user_id?: string;
  }
  interface DeleteFolderBody {
    old_path: string;
    application_name: string;
    team_id?: string;
    user_id?: string;
  }
  // 2) Adjust function signature to return RequestParams
  function getRequestParams(oldPath: string): RequestParams {
    const topLevel = parentFolders[0]; // "Team Documents" or "My Documents"
  
    // 3) Create `params` as a RequestParams object
    const params: RequestParams = {
      application_name: process.env.REACT_APP_APPLICATION_NAME as string,
      source_scope: topLevel === "Team Documents" ? "team" : "user",
      old_path: oldPath,
      team_id: user?.organizationMemberships?.[0]?.organization.id,
      user_id: user?.id,
    };

    return params;
  }

  const handleDelete = async () => {
    try {
      if (isFolder) {
        // 1) Build old_path from parentFolders + folder.name
        const oldPath = buildFolderPath();
        const body = getRequestParams(oldPath);

        // 2) Send DELETE /api/folders
        await axios.delete(
          process.env.REACT_APP_API_URL + "api/folders",
          { data: body } // pass body via "data"
        );

        // If successful => refresh folder structure
        fetchSources();
        handleClose?.();
      } else {
        // Deleting a file
        // If your "file" object has { name }, or if it’s just a string, adjust as needed
        const pathArr = [...parentFolders];
        pathArr.shift();
        // if "file" is a string, pathArr.push(file)
        // or if "file" is an object => pathArr.push(file.name)
        pathArr.push(file?.name ?? file);

        const body = getRequestParams(pathArr.join("/"));

        // 2) Send DELETE /api/documents
        await axios.delete(
          process.env.REACT_APP_API_URL + "api/documents",
          { data: body }
        );

        fetchSources();
        handleClose?.();
      }
    } catch (error) {
      console.error("Error deleting:", error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[327px] xl:w-[436px] bg-white rounded-[10px] shadow-lg">
        <div className="py-3 xl:py-4 px-[18px] xl:px-6 flex items-center justify-between border-b border-solid border-[#E4E4E7] gap-3 xl:gap-4">
          <div>
            <div className="text-[13px] xl:text-lg leading-[16px] xl:leading-[22px] font-bold text-[#18181B] mb-[3px] xl:mb-1">
              Delete
            </div>
            <div className="text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] font-medium text-[#71717A]">
              Please confirm
            </div>
          </div>
          <button
            className="w-[30px] xl:w-10 h-[30px] xl:h-10 border border-solid border-[#D4D4D8] flex items-center justify-center flex-none rounded-full"
            onClick={() => handleClose?.()}
          >
            <IoCloseOutline className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-[#09090B]" />
          </button>
        </div>

        <div className="p-[18px] xl:p-6">
          <div className="w-full text-[15px] xl:text-xl font-normal text-[#18181B] mb-6 xl:mb-8">
            This will be permanently deleted. This action is irreversible.
          </div>
          <div className="flex gap-3 xl:gap-4">
            <button
              className="outline-none border border-solid border-[#71717A] h-9 xl:h-12 flex items-center justify-center rounded-lg w-full"
              onClick={() => handleClose?.()}
            >
              <span className="text-xs xl:text-base font-bold text-[#71717A]">
                Cancel
              </span>
            </button>
            <button
              className="outline-none bg-[#FA302F] h-9 xl:h-12 flex items-center justify-center rounded-lg w-full"
              onClick={handleDelete}
            >
              <span className="text-xs xl:text-base font-bold text-white">
                Delete
              </span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
