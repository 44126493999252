// src/UserContext.tsx
import { type FC, createContext, type ReactNode, useContext, useEffect, useState } from 'react';
import { useUser } from '@clerk/clerk-react';
import { UserResource } from '@clerk/types';

interface Organization {
    id: string;
    name: string;
    organization: Organization;
}

type ExtendedUserResource = UserResource & {
    organizationMemberships?: Organization[];
};

interface UserContextType {
    // FIXME: This type definition is mostly correct, but is missing things like organizationMemberships
    user: ExtendedUserResource | null;
    isLoaded: boolean;
    isSignedIn: boolean;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const { isLoaded, isSignedIn, user } = useUser();
    const [userData, setUserData] = useState<any | null>(null);

    useEffect(() => {
        if (isLoaded && isSignedIn) {
            setUserData(user);
        }
    }, [isLoaded, isSignedIn, user]);

    return (
        <UserContext.Provider value={{ user: userData, isLoaded: !!isLoaded, isSignedIn: !!isSignedIn }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUserContext = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUserContext must be used within a UserProvider');
    }
    return context;
};
