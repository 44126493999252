import { useEffect } from 'react'
import * as amplitude from '@amplitude/analytics-browser'

const DISALLOWED_ORGANIZATIONS = process.env.REACT_APP_ANALYTICS_DISABLED_PARTNERS?.split("|")

const useDynamicClickTracking = (organizationName: string) => {
  useEffect(() => {
    if (DISALLOWED_ORGANIZATIONS?.includes(organizationName)) {
      return;
    }
    const amplitudeKey = process.env.REACT_APP_AMPLITUDE_URL || ''
    amplitude.init(amplitudeKey)

    const screenWidth = window.screen.width
    const screenHeight = window.screen.height

    const dpi = window.devicePixelRatio * 96 // Approximate DPI
    const diagonalSize = Math.sqrt(
      Math.pow(screenWidth / dpi, 2) + Math.pow(screenHeight / dpi, 2)
    ).toFixed(2) // Diagonal size in inches

    const identify = new amplitude.Identify();
    identify.set('teamName', organizationName);
    identify.set('screenWidth', screenWidth);
    identify.set('screenHeight', screenHeight);
    identify.set('approxScreenSize', parseFloat(diagonalSize));

    amplitude.identify(identify);

    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      const elementWithEventName = target.closest('[data-event-name]') as HTMLElement
      const eventName = elementWithEventName?.getAttribute('data-event-name')
      if (eventName) {
        amplitude.track(eventName, {
          id: target.id || '',
          className: target.className || '',
          tagName: target.tagName || '',
        })
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [organizationName])
}

export default useDynamicClickTracking