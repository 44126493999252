import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import SetPassword from "../pages/auth/SetPassword";
import AcceptInvitation from "../pages/auth/AcceptInvitation";
import Home from "../pages/home";
import Research from "../pages/research";
import Concept from "../pages/concept";
import Privacy from "../pages/privacy";

const routes = [
  {
    component: Home,
    page: "home",
    path: "/",
    layout: true,
    auth: true,
  },
  {
    component: Privacy,
    page: "privacy",
    path: "/privacy",
    layout: true,
    auth: true,
  },
  {
    component: Research,
    page: "research",
    path: "/research",
    layout: true,
    auth: true,
  },
  {
    component: Concept,
    page: "concept",
    path: "/concept",
    layout: true,
    auth: true,
  },
  {
    component: Login,
    page: "login",
    path: "/login",
    layout: false,
    auth: false,
  },
  {
    component: Register,
    page: "register",
    path: "/register",
    layout: false,
    auth: false,
  },
  {
    component: SetPassword,
    page: "setPassword",
    path: "/setPassword",
    layout: false,
    auth: false,
  },
  {
    component: AcceptInvitation,
    page: "acceptInvitation",
    path: "/acceptInvitation",
    layout: false,
    auth: false,
  },
];

export default routes;
