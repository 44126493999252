import { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  extractQAPairs,
  generateUniqueId,
  getDocumentName,
  getExtension,
  getSourceRetriever,
} from "../../utils";
import { toast } from "react-toastify";
import { IoSaveOutline } from "react-icons/io5";
import editPencilIcon from "../../assets/svg/edit-pen.svg";
import topicIcon from "../../assets/svg/topic.svg";
import backIcon from "../../assets/svg/back.svg";
import pdfIcon from "../../assets/svg/pdf.svg";
import folderCheckedIcon from "../../assets/svg/folder-checked.svg";
import chatTypeIcon from "../../assets/svg/chat-type.svg";
import internetIcon from "../../assets/svg/internet.svg";
import arxivIcon from "../../assets/svg/arxiv.svg";
import elsevierNonSolusIcon from "../../assets/svg/elsevier-non-solus.svg";
import Loading from "../common/Loading";
import { useUserContext } from "../../contexts/UserContext";
import { GrFormNext, GrFormNextLink } from "react-icons/gr";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import Markdown from "react-markdown";
import $ from "jquery";
import type { AnchorHTMLAttributes, ChangeEvent, FC, KeyboardEvent, MouseEvent, ReactNode } from "react";

import {
  ClickAwayListener,
  Fade,
  Popover,
  Popper,
  Skeleton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import { SiResearchgate } from "react-icons/si";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

import { useRagContext } from "../../contexts/RagContext";
import DuplicateIcon from "../icons/DuplicateIcon";
import RefreshIcon from "../icons/RefreshIcon";
import GooglePatentsIcon from "../icons/GooglePatentsIcon";
import GoogleScholarIcon from "../icons/GoogleScholarIcon";
import CodeIcon from "../icons/CodeIcon";
import TrashIcon from "../icons/TrashIcon";
import moment from "moment";
import { useHistory } from "react-router";
import RisingStarIcon from "../icons/RisingStarIcon";
import BrainIcon from "../icons/BrainIcon";
import LightIcon from "../icons/LightIcon";
import { GiArtificialHive } from "react-icons/gi";
import { useLayoutContext } from "../../contexts/LayoutContext";
import * as amplitude from "@amplitude/analytics-browser";

interface Message {
  isUserMessage: boolean;
  text: string;
  id: string;
  documents: any[];
  followUpQuestions: any[];
  source_retriever?: string;
  logs?: any[];
}

interface ChatTopic {
  text: string;
  id: string;
}

interface QuestionProps {
  msg: Message;
  index: any;
  handleSubmitFromEditAction: any;
}

const Question: FC<QuestionProps> = ({
  msg,
  // FIXME: index is unused
  index,
  handleSubmitFromEditAction,
}) => {
  const [isEditing, setEditing] = useState(false);
  const [prompt, setPrompt] = useState(msg.text);

  const { user } = useUserContext();

  const handleEditPrompt = () => {
    setEditing(true);
  };

  const handleSubmit = () => {
    setEditing(false);
    handleSubmitFromEditAction(prompt);
  };

  const onEnterPress = (event: any) => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      setEditing(false);
      handleSubmitFromEditAction(prompt);
      event.preventDefault();
    }
  };

  return (
    <div className="flex gap-3 xl:gap-4 group relative pr-[30px] xl:pr-10">
      <div className="flex flex-col gap-3 xl:gap-4">
        <div className="w-[26px] xl:w-[34px] h-[26px] xl:h-[34px] flex-none rounded-full bg-[#d4d4d8] flex items-center justify-center">
          <span className="text-black text-[10px] xl:text-sm leading-[18px] xl:leading-6 font-medium">
            {(user?.unsafeMetadata?.user_name as string)?.charAt(0) ?? ''}
          </span>
        </div>
      </div>
      {isEditing ? (
        <textarea
          className="rounded-lg py-[9px] xl:py-3 px-4 xl:px-4 bg-[#FCFCFC] border border-[#D4D4D8] flex-1 outline-none text-[10px] xl:text-sm leading-[18px] xl:leading-6 font-medium text-[#3F3F46]"
          value={prompt}
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setPrompt(e.target.value)}
          autoFocus
          onKeyDown={(e: KeyboardEvent<HTMLTextAreaElement> ) => onEnterPress(e)}
        ></textarea>
      ) : (
        <div className="flex-1 text-xs xl:text-base font-medium text-[#18181B]">
          {prompt}
        </div>
      )}
      {isEditing ? (
        <button
          className="outline-none w-[21px] xl:w-7 h-[21px] xl:h-7 border border-[#D4D4D8] items-center justify-center rounded-lg absolute bottom-0 right-0 hidden group-hover:flex"
          onClick={() => handleSubmit()}
        >
          <IoSaveOutline className="text-xs xl:text-base text-[#18181B]" />
        </button>
      ) : (
        <button
          className="outline-none w-[21px] xl:w-7 h-[21px] xl:h-7 border border-[#D4D4D8] items-center justify-center rounded-lg absolute bottom-0 right-0 hidden group-hover:flex"
          onClick={() => handleEditPrompt()}
        >
          <img
            src={editPencilIcon}
            alt="Edit"
            className="w-3 xl:w-4 h-3 xl:h-4"
          />
        </button>
      )}
    </div>
  );
};

interface TopicProps {
  topic: ChatTopic;
  fetchChatHistory: any;
  fetchChatTopics: () => void;
}

const Topic: FC<TopicProps> = ({
  topic,
  fetchChatHistory,
  fetchChatTopics,
}) => {
  const handleTopicClick = () => {
    fetchChatHistory(topic.id);
  };

  const handleDeleteTopic = async () => {
    try {
      await axios.post(process.env.REACT_APP_API_URL + "api/deleteChat", {
        topic_id: topic.id,
      });
      fetchChatTopics();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex gap-[6px] xl:gap-2 group">
      <button className="outline-none w-[21px] xl:w-7 h-[21px] xl:h-7 border border-[#D4D4D8] items-center justify-center rounded-full flex flex-none">
        <img src={topicIcon} alt="topic" className="w-6 xl:w-8 h-6 xl:h-8" />
      </button>
      <div
        className="flex-1 text-xs xl:text-base font-medium text-[#18181B] cursor-pointer"
        data-event-name="Research: Previous Query Opened"
        onClick={() => {
          handleTopicClick();
        }}
      >
        {topic.text}
      </div>
      <button
        className="outline-none w-[21px] xl:w-7 h-[21px] xl:h-7 border border-[#D4D4D8] items-center justify-center rounded-full flex flex-none invisible group-hover:visible"
        onClick={() => handleDeleteTopic()}
      >
        <TrashIcon
          className="w-3 xl:w-4 h-3 xl:h-4 flex-none"
          stroke="#09090B"
        />
      </button>
    </div>
  );
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#040404",
    color: "#e5e5e5",
    maxWidth: 220,
    border: "1px solid #dadde9",
    marginBottom: "8px !important",
    padding: "2px 4px !important",
    fontSize: "11px !important",
  },
}));

interface AnswerProps {
  msg: Message;
  index: any;
  documentClick: any;
  sourceIndexClick: any;
  selectedSourceIndex: number[];
  handleFollowUpQuestionClick: (question: string) => void;
  handleSubmitFromEditAction: any;
  setSelectedText: any;
  setButtonPosition: any;
  chatRef: any;
  initialSearch: boolean;
  isGenerating: boolean;
  loadingAnswerIndex: number;
}

const Answer: FC<AnswerProps> = ({
  msg,
  index,
  documentClick,
  sourceIndexClick,
  selectedSourceIndex,
  handleFollowUpQuestionClick,
  handleSubmitFromEditAction,
  setSelectedText,
  setButtonPosition,
  chatRef,
  initialSearch,
  isGenerating,
  loadingAnswerIndex,
}) => {
  const {
    socket,
    socketId,
    sourceList,
    openConceptAddFolderModal,
    openActivityLogsModal,
    getBrainstormList,
    fetchSources,
  } = useRagContext();
  const { deviceType, setChatPosition } = useLayoutContext();
  const { user } = useUserContext();
  const [followUpQuestions, setFollowUpQuestions] = useState<string[]>([]);
  const answerRef = useRef<HTMLDivElement>(null);
  const [popoverAnchorEl, setPopoverAnchorEl] =
    useState<HTMLElement | null>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [logViewMore, setLogViewMore] = useState(false);
  // FIXME: savedLinks is unused
  const [savedLinks, setSavedLinks] = useState<{ [key: string]: boolean }>({});
  // const [isSaved, setIsSaved] = useState(false); // Track if the link has been saved
  const history = useHistory();

  const handlePopoverClose = () => {
    setPopoverOpen(false);
    setPopoverAnchorEl(null);
  };

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setPopoverOpen(true);
    setPopoverAnchorEl(event.currentTarget);
  };

  interface FollowUpQuestionsData {
    socketId: string;
    questions?: string[];
  }

  const copyToClipBoard = (message: string) => {
    navigator.clipboard.writeText(message).then(r => r);
    toast.success("Copied to Clipboard!", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: 0,
      toastId: "my_toast",
    });
  };

  useEffect(() => {
    if (msg?.followUpQuestions.length > 0) {
      setFollowUpQuestions(msg?.followUpQuestions);
    }
  }, [msg?.followUpQuestions]);
  useEffect(() => {
    const answerElement = answerRef.current;

    if (answerElement) {
      // FIXME: not entirely happy with this double recasting
      answerElement.addEventListener("mouseup", handleMouseUp as unknown as EventListener);
    }
    socket.on("follow_up_questions", (data: FollowUpQuestionsData) => {
      if (data.socketId === socketId) {
        let needScroll = needScrollCheck();
        setFollowUpQuestions(data.questions ?? []);
        if (needScroll) {
          setTimeout(() => {
            handleScroll();
          }, 0);
        }
      }
    });

    return () => {
      socket.off("follow_up_questions");
      if (answerElement) {
        // FIXME: not entirely happy with this double recasting
        answerElement.removeEventListener("mouseup", handleMouseUp as unknown as EventListener);
      }
    };
  }, []);

  const needScrollCheck = useCallback(() => {
    if (chatRef.current) {
      return (
        chatRef.current.scrollHeight -
          chatRef.current.clientHeight -
          chatRef.current.scrollTop <=
        150
      );
    }
  }, [chatRef]);

  const handleScroll = useCallback(() => {
    if (chatRef.current) {
      scrollToBottom(chatRef.current);
    }
  }, [chatRef]);

  const handleDocumentClick = (document: any, sourceIndex: number) => {
    if (window.innerWidth > 1350) {
      setChatPosition(424);
    } else {
      setChatPosition(300);
    }
    documentClick(document);
    sourceIndexClick(index, sourceIndex);
  };

  const handleMouseUp = (_event: MouseEvent<HTMLDivElement>) => {
    const selection = document.getSelection();
    const answerElement = answerRef.current;
    if (
      selection &&
      selection.toString() !== "" &&
      answerElement &&
      answerElement.contains(selection.anchorNode)
    ) {
      const range = selection.getRangeAt(0);
      const selectedContent = range.cloneContents();
      const tempDiv = document.createElement("div");
      tempDiv.appendChild(selectedContent);
      const divElements = tempDiv.querySelectorAll("div");
      let extraHeightMinus = 0;
      if (divElements.length > 0) {
        extraHeightMinus = 40;
      }

      const rect = range.getBoundingClientRect();
      setSelectedText(selection.toString());
      setButtonPosition({
        top: chatRef?.current?.scrollTop + rect.bottom - 136 - extraHeightMinus,
        left: chatRef?.current?.offsetWidth / 3, // No need to adjust for scroll
      });
    } else {
      setSelectedText(null);
      setButtonPosition(null);
    }
  };

  const CurrentChatIcon = () => {
    switch (msg.source_retriever) {
      case "selected_source": {
        return (
          <img
            src={folderCheckedIcon}
            data-event-name="Research: Source Selected"
            alt=""
            className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none"
          />
        );
      }
      case "chat_with_llm": {
        return (
          <GiArtificialHive className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none" />
        );
      }
      case "arxiv": {
        return (
          <img
            src={arxivIcon}
            data-event-name="Research: Arxiv Selected"
            alt=""
            className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none"
          />
        );
      }
      case "google_scholar": {
        return (
          <GoogleScholarIcon className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none" />
        );
      }
      case "sciencedirect": {
        return (
          <img
            src={elsevierNonSolusIcon}
            data-event-name="Research: Science Direct Selected"
            alt=""
            className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none"
          />
        );
      }
      case "google_patents": {
        return (
          <GooglePatentsIcon className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none" />
        );
      }
      case "tavily": {
        return (
          <img
            src={chatTypeIcon}
            alt=""
            data-event-name="Research: Tavily Selected"
            className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none"
          />
        );
      }
      default: {
        return (
          <img
            src={folderCheckedIcon}
            alt=""
            className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none"
          />
        );
      }
    }
  };

  const handleGetHMWQuestions = async (context: string) => {
    try {
      let params: any = {
        team_id: user?.organizationMemberships[0]?.organization.id,
        source_list: [],
        name: "Brainstorm " + moment().format("YYYY-MM-DD h:mm a"),
        context: context,
        fromSource: false,
        socketId,
      };
      const result = await axios.post(
        process.env.REACT_APP_API_URL + "api/getHMWQuestions",
        params
      );

      const searchParams = new URLSearchParams();
      searchParams.set("render_type", "modal");
      searchParams.set("render_modal", "brainstorm_modal");
      searchParams.set("brainstorm_id", result.data.brainstorm_id);

      history.push({
        pathname: "/concept",
        search: `?${searchParams.toString()}`,
      });
      getBrainstormList();
      handlePopoverClose();
    } catch (error) {
      console.log(error);
    }
  };
  const handleSaveSourceLink = async (href: string, sourceName: string) => {
    try {
      const team_id = user?.organizationMemberships[0]?.organization.id;
      const payload = {
        team_id: team_id,
        url: href,
        source_name: sourceName,
      };
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "api/saveSourceLink",
        payload
      );
      if (response.status === 200) {
        fetchSources();
        // setIsSaved(true);
        setSavedLinks((prev) => ({
          ...prev,
          [href]: true,
        }));
      }
    } catch (error) {
      console.error("Error saving source link:", error);
    }
  };
  return (
    <div ref={answerRef}>
      <div className="flex gap-3 xl:gap-4">
        <div className="w-6 xl:w-8 h-6 xl:h-8 rounded-full flex flex-none items-center justify-center">
          {CurrentChatIcon()}
        </div>
        {/* <button
            className="outline-none w-[21px] xl:w-7 h-[21px] xl:h-7 border border-[#D4D4D8] flex items-center justify-center rounded-lg"
            onClick={() => copyToClipBoard(msg.text.trim())}
          >
            <img src={copyIcon} alt="Edit" className="w-3 xl:w-4 h-3 xl:h-4" />
          </button> */}
        <div
          className="flex-1"
          style={{
            width: `calc(100% - ${
              48 * (deviceType === "laptop" ? 0.75 : 1)
            }px)`,
          }}
        >
          {msg.text?.length ? (
            <div className="w-full text-xs xl:text-base font-medium text-[#000000cb] c-markdown-text">
              {/* {parse(msg.text.trim()) as string} */}
              <Markdown
                components={{
                  a: ({ node, href, children, ...props }: {
                    node: unknown;
                    href?: string | undefined;
                    children?: ReactNode;
                  } & AnchorHTMLAttributes<HTMLAnchorElement>) => (
                    <span className="relative inline-block group">
                      <a
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                        {...props}
                        className="underline text-blue-600 m-0 p-0"
                      >
                        {children}
                      </a>
                      {/* {href && (
                        <HtmlTooltip
                          title={
                            <div>
                              {savedLinks[href]
                                ? "Added successfully"
                                : "Add to source"}
                            </div>
                          }
                          placement="right"
                        >
                          <button
                            className={`w-[18px] xl:w-6 h-[18px] xl:h-6 items-center justify-center rounded-full shadow-md absolute right-0 top-1/2 translate-x-full -translate-y-1/2 hidden group-hover:flex ${
                              savedLinks[href] ? "bg-[#3B82F6]" : "bg-white"
                            }`}
                            onClick={() =>
                              handleSaveSourceLink(
                                href || "",
                                children?.toString() || ""
                              )
                            }
                          >
                            {savedLinks[href] ? (
                              <CheckIcon className="w-3 h-3" />
                            ) : (
                              <AddSourceIcon className="w-3 h-3" />
                            )}
                          </button>
                        </HtmlTooltip>
                      )} */}
                    </span>
                  ),
                }}
              >
                {msg.text.trim()}
              </Markdown>
            </div>
          ) : (
            <> </>
          )}
          {msg.source_retriever !== "selected_source" &&
          msg.text?.length === 0 ? (
            <div className="flex gap-3 xl:gap-4">
              <div
                className="flex flex-col gap-[13px] xl:gap-[18px]"
                style={{
                  width: `calc(100% - ${
                    48 * (deviceType === "laptop" ? 0.75 : 1)
                  }px)`,
                }}
              >
                <div
                  className="overflow-hidden"
                  style={{
                    maxHeight: logViewMore
                      ? "none"
                      : 312 * (deviceType === "laptop" ? 0.75 : 1),
                  }}
                >
                  {msg.logs?.map((log: any, index: number) => (
                    <div
                      className="text-xs xl:text-base font-normal text-[#000000cc]"
                      key={index}
                    >
                      {log.output}
                    </div>
                  ))}
                </div>
                <button
                  className={`outline-none border-none bg-transparent gap-[6px] xl:gap-2 items-center w-fit ${
                    msg.logs?.length !== undefined && msg.logs?.length > 4
                      ? "flex"
                      : "hidden"
                  }`}
                  onClick={() => setLogViewMore(!logViewMore)}
                >
                  <span className="text-xs xl:text-base font-bold text-[#3B82F6]">
                    {logViewMore ? "View Less" : "View More"}
                  </span>
                  {logViewMore ? (
                    <MdKeyboardArrowUp className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-[#3B82F6]" />
                  ) : (
                    <MdKeyboardArrowDown className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-[#3B82F6]" />
                  )}
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
          {isGenerating && index === loadingAnswerIndex ? (
            <></>
          ) : (
            <div className="flex gap-[9px] xl:gap-3 mt-3">
              <HtmlTooltip title={<div>Copy</div>} placement="top">
                <button
                  className="outline-none w-[21px] xl:w-7 h-[21px] xl:h-7 border border-[#D4D4D8] items-center justify-center rounded-lg flex"
                  onClick={() => copyToClipBoard(msg.text)}
                >
                  <DuplicateIcon className="w-3 xl:w-4 h-3 xl:h-4" />
                </button>
              </HtmlTooltip>
              <HtmlTooltip title={<div>Regenerate</div>} placement="top">
                <button
                  className="outline-none w-[21px] xl:w-7 h-[21px] xl:h-7 border border-[#D4D4D8] items-center justify-center rounded-lg flex"
                  onClick={() => handleSubmitFromEditAction()}
                >
                  <RefreshIcon className="w-3 xl:w-4 h-3 xl:h-4" />
                </button>
              </HtmlTooltip>
              <HtmlTooltip title={<div>Activity Logs</div>} placement="top">
                <button
                  className="outline-none w-[21px] xl:w-7 h-[21px] xl:h-7 border border-[#D4D4D8] items-center justify-center rounded-lg flex"
                  onClick={() => openActivityLogsModal(msg.logs || [])}
                >
                  <CodeIcon className="w-3 xl:w-4 h-3 xl:h-4" />
                </button>
              </HtmlTooltip>
              {/* <HtmlTooltip title={<div>Create Concept</div>} placement="top"> */}
              <button
                className="outline-none w-[21px] xl:w-7 h-[21px] xl:h-7 bg-[#3B82F6] items-center justify-center rounded-lg flex"
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  handlePopoverOpen(e);
                }}
              >
                <RisingStarIcon className="w-3 xl:w-4 h-3 xl:h-4" />
              </button>
              {/* </HtmlTooltip> */}
              {/* <HtmlTooltip title={<div>Start Brainstorm</div>} placement="top">
                <button
                  className="outline-none w-[21px] xl:w-7 h-[21px] xl:h-7 bg-[#3B82F6] items-center justify-center rounded-lg flex"
                  onClick={() => {
                    handleGetHMWQuestions(msg.text)
                  }}
                >
                  <StarIcon className="w-3 xl:w-4 h-3 xl:h-4" />
                </button>
              </HtmlTooltip> */}
            </div>
          )}
        </div>
      </div>
      {msg.documents?.length > 0 && (
        <div className="flex flex-col gap-[6px] xl:gap-2 mt-4">
          {msg.documents[0] !== "ChatHistory" ? (
            <div className="text-xs xl:text-base font-medium text-[#27272A]">
              Source File:
            </div>
          ) : (
            <></>
          )}
          <div className="grid grid-cols-2 gap-[4px] xl:gap-[6px] pl-11">
            {msg.documents.map((document: any, sourceIndex: number) =>
              document !== "ChatHistory" ? (
                <div
                  className={`flex items-center gap-[7px] xl:gap-[10px] p-[6px] xl:p-2 rounded-lg cursor-pointer ${
                    index === selectedSourceIndex[0] &&
                    sourceIndex === selectedSourceIndex[1]
                      ? "bg-[#60A5FA]"
                      : "border border-[#0000000c] bg-[#FBFDFF]"
                  }`}
                  key={sourceIndex}
                  onClick={() => handleDocumentClick(document, sourceIndex)}
                  data-event-name="Research: Source File Clicked"
                >
                  <button className="w-6 xl:w-8 h-6 xl:h-8 flex items-center justify-center rounded bg-[#F4F4F5] outline-none flex-none">
                    <img
                      src={
                        getExtension(document?.document) === "pdf"
                          ? pdfIcon
                          : process.env.PUBLIC_URL +
                            `/img/${getExtension(document?.document)}.png`
                      }
                      alt="docIcon"
                      className="w-[18px] xl:w-6 h-[18px] xl:h-6"
                    />
                  </button>
                  <div
                    className={`text-[9px] xl:text-xs leading-[15px] xl:leading-5 font-medium text-ellipsis overflow-hidden whitespace-nowrap ${
                      index === selectedSourceIndex[0] &&
                      sourceIndex === selectedSourceIndex[1]
                        ? "text-white"
                        : "text-[#27272A]"
                    }`}
                  >
                    {" " + getDocumentName(document?.document)} <br />
                    {" (page:" + parseInt(document?.page + 1) + ")  "}
                  </div>
                </div>
              ) : null
            )}
          </div>
        </div>
      )}
      {!isGenerating && followUpQuestions?.length > 0 && (
        <div className="flex flex-col gap-3 xl:gap-4 mt-4">
          {msg.documents[0] !== "ChatHistory" ? (
            <div className="text-xs xl:text-base font-medium text-[#27272A]">
              You may also want to ask
            </div>
          ) : (
            <></>
          )}
          <div className="flex flex-col gap-[3px] xl:gap-1">
            {followUpQuestions.map((question: string, index: number) => (
              <div
                key={index}
                className="p-[11px] xl:p-[15px] pr-[26px] xl:pr-[35px] rounded-lg cursor-pointer bg-[#F6F6F9] hover:bg-gray-100 transition relative group"
                onClick={() => handleFollowUpQuestionClick(question)}
              >
                <p
                  className="text-[10px] xl:text-sm font-medium text-gray-800"
                  data-event-name="Research: Follow Up Question Asked"
                >
                  {question}
                </p>
                <button className="w-[15px] xl:w-5 h-[15px] xl:h-5 hidden group-hover:flex items-center justify-center absolute bottom-[11px] xl:bottom-[15px] right-[15px] xl:right-5">
                  <MdOutlineKeyboardArrowRight className="w-[15px] xl:w-5 h-[15px] xl:h-5 text-[#09090B]" />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      <Popover
        open={popoverOpen}
        anchorEl={popoverAnchorEl}
        onClose={() => handlePopoverClose()}
        className="mt-[7px] xl:mt-[10px] ml-[4px] xl:ml-[5px]"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="p-[4px] xl:p-[6px] bg-white rounded-lg flex flex-col gap-[3px] xl:gap-1 w-[176px] xl:w-[235px]">
          <div
            className="w-full px-[9px] xl:px-3 py-[7px] xl:py-[10px] flex gap-[9px] xl:gap-3 cursor-pointer"
            onClick={() => {
              handleGetHMWQuestions(msg.text);
            }}
          >
            <BrainIcon
              stroke="#09090B"
              className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none"
            />
            <div className="text-xs xl:text-base font-medium text-[#3F3F46]">
              Start Brainstorm
            </div>
          </div>
          <div
            className="w-full px-[9px] xl:px-3 py-[7px] xl:py-[10px] flex gap-[9px] xl:gap-3 cursor-pointer"
            onClick={() => {
              openConceptAddFolderModal("window_selection", msg.text);
            }}
          >
            <LightIcon
              stroke="#18181B"
              className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none"
            />
            <div className="text-xs xl:text-base font-medium text-[#3F3F46]">
              Create Concept
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
};

const createMessage = (
  text: string,
  isUserMessage: boolean,
  documents: any[],
  source_retriever?: string,
  logs?: any[]
): Message => {
  return {
    isUserMessage,
    text,
    id: generateUniqueId(),
    documents,
    source_retriever: source_retriever,
    followUpQuestions: [],
    logs: logs || [],
  };
};

const Chat: FC = () => {
  const ref = useRef<HTMLParagraphElement | null>(null);
  const { user } = useUserContext();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = useState(false);
  const journalAnchorRef = useRef(null);
  const [journalOpen, setJournalOpen] = useState(false);
  const [selectedText, setSelectedText] = useState<string | undefined>(
    undefined
  );
  const [buttonPosition, setButtonPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const [popoverAnchorEl, setPopoverAnchorEl] =
    useState<HTMLElement | null>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [hasStartedTyping, setHasStartedTyping] = useState(false);

  const [viewMore, setViewMore] = useState(false);

  const handleScroll = useCallback(() => {
    if (ref.current) {
      scrollToBottom(ref.current);
    }
  }, []);

  const [messages, setMessages] = useState<Message[]>([]);
  const messagesRef = useRef<Message[]>([]);
  const [chatTopics, setChatTopics] = useState<ChatTopic[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [loadingAnswerIndex, setLoadingAnswerIndex] = useState(-1);
  const [isGenerating, setGenerating] = useState(false);
  const loadingAnswerIndexRef = useRef<number>(-1);
  const [followUpClicked, setFollowUpClicked] = useState(false);
  const [selectedSourceIndex, setSelectedSourceIndex] = useState<number[]>([
    -1, -1,
  ]);
  const {
    setting,
    selectedFiles,
    sourceList,
    socket,
    socketId,
    openConceptAddFolderModal,
    chatTopicId,
    setChatTopicId,
    retriever,
    setRetriever,
    subRetriever,
    setSubRetriever,
    getBrainstormList,
    setOpenedDocument,
    setSelectedFolderID,
    setPageNumber,
    openedDocument,
    selectedFolderID,
    selectedSubFolderID,
  } = useRagContext();
  const { deviceType } = useLayoutContext();
  const history = useHistory();

  const [initialSearchLog, setInitialSearchLog] = useState<string[]>([]);
  // FIXME: Unused showGeneratingLoading
  const [showGeneratingLoading, setShowStopGeneratingLoading] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     handleScroll();
  //   }, 10);
  // }, [messages, handleScroll]);
  useEffect(() => {
    messagesRef.current = messages; // Keep ref updated with latest messages
  }, [messages]);

  useEffect(() => {
    loadingAnswerIndexRef.current = loadingAnswerIndex;
  }, [loadingAnswerIndex]);

  useEffect(() => {
    if (socket) {
      socket.on("logs", (data: any) => {
        if (data.socketId === socketId) {
          setInitialSearchLog((prev) => [...prev, data.output]);
          setTimeout(() => {
            handleCheckScroll();
          }, 10);
        }
      });
      socket.on("response", (data: any) => {
        const updatedMessages: Message[] = [...messagesRef.current];

        if (
          data.socketId === socketId &&
          loadingAnswerIndexRef.current !== -1
        ) {
          setLoading(false);
          updatedMessages[loadingAnswerIndexRef.current].text =
            updatedMessages[loadingAnswerIndexRef.current].text + data?.output;
          setMessages(updatedMessages);
          setTimeout(() => {
            handleCheckScroll();
          }, 0);
        }
      });

      socket.on("response_complete", (data: any) => {
        if (data.socketId === socketId) {
          setTimeout(() => {
            setLoading(false);
            setGenerating(false);
            setShowStopGeneratingLoading(false);
          }, 10);
        }
      });
    }
    return () => {
      if (socket) {
        socket.off("logs");
        socket.off("response");
        socket.off("response_complete");
      }
    };
  }, [socket]);

  useEffect(() => {
    if (selectedFiles.length > 0) {
      setRetriever("selected_source");
    }
  }, [selectedFiles, setRetriever]);

  useEffect(() => {
    if (user) window.addEventListener("message", createNewChat, false);
    return () => {
      if (user) window.removeEventListener("message", createNewChat, false);
    };
  }, [user]);

  const handleCheckScroll = useCallback(() => {
    if (ref.current) {
      if (
        ref.current.scrollHeight -
          ref.current.clientHeight -
          ref.current.scrollTop <=
        150
      ) {
        handleScroll();
      }
    }
  }, [ref]);

  const handlePopoverClose = () => {
    setPopoverOpen(false);
    setPopoverAnchorEl(null);
  };

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    setPopoverOpen(true);
    setPopoverAnchorEl(event.currentTarget);
  };

  const createNewChat = (event: any) => {
    if (event.data.type === "create_new_chat") {
      handleBackClick();
      setRetriever("selected_source");
      if (textareaRef.current) textareaRef.current.focus();
    }
  };

  const [userInput, setUserInput] = useState("");

  const fetchChatTopics = async () => {
    try {
      const data = {
        user_id: user?.id,
      };
      const result = await axios.post(
        process.env.REACT_APP_API_URL + "api/getChatTopics",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let newItems: ChatTopic[] = [];
      if (result.data.data.length > 0) {
        for (const item of result.data.data) {
          const topic: ChatTopic = {
            text: item?.text,
            id: item?.id,
          };
          newItems.push(topic);
        }
      }
      setChatTopics(newItems);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchChatHistory = async (topic_id: string) => {
    try {
      setChatTopicId(topic_id);
      const data = {
        user_id: user?.id,
        topic_id: topic_id,
      };
      const result = await axios.post(
        process.env.REACT_APP_API_URL + "api/getChatHistory",
        // FIXME: data type inference does not match nested data with message values
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let newItems: Message[] = [];
      if (result.data.data.length > 0) {
        for (const item of result.data.data) {
          const UserMessage: Message = {
            isUserMessage: true,
            text: item?.user_message,
            id: item?.user_message_id,
            documents: [],
            followUpQuestions: [],
          };
          const AIMessage: Message = {
            isUserMessage: false,
            text: item?.ai_message,
            id: item?.ai_message_id,
            documents: item?.source,
            source_retriever: item?.source_retriever,
            logs: item?.logs || [],
            followUpQuestions: item?.follow_up_questions,
          };
          newItems.push(...[UserMessage, AIMessage]);
        }
      }
      setMessages(newItems);
      setTimeout(() => {
        handleScroll();
      }, 0);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user?.id && setting?.verified) {
      fetchChatTopics();
      setTimeout(() => {
        handleScroll();
      }, 1000);
    }
  }, [user?.id, setting]);

  useEffect(() => {
    setTimeout(() => {
      handleScroll();
    }, 10);
  }, [viewMore]);

  const onEnterPress = (event: any) => {
    if (event.keyCode === 13 && event.shiftKey === false) {
      event.preventDefault();
      if (isGenerating) {
        setUserInput((prevInput) => prevInput + "\n");
      } else {
        submit();
      }
    }
  };
  useEffect(() => {
    if (followUpClicked) {
      submit();
      setFollowUpClicked(false);
    }
  }, [userInput]);

  useEffect(() => {
    setChatTopicId("");
  }, []);

  const submit = async () => {
    try {
      setLoadingAnswerIndex(messages.length + 1);
      let response: any = await getAnswer(
        userInput,
        "Insert",
        "",
        messages.length + 1
      );
      if (response.status === 200) {
        // fetchChatHistory(chatTopicId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAnswer = async (
    userText: string,
    action: string,
    message_id: string,
    index: number
  ) => {
    setInitialSearchLog([]);
    setViewMore(false);
    setGenerating(true);
    try {
      let topic_id = chatTopicId;
      if (topic_id === "") {
        try {
          let params = {
            question: userText + "\n",
            user_id: user?.id,
          };
          let response = await axios.post(
            process.env.REACT_APP_API_URL + "api/createChatTopic",
            params
          );
          if (response?.status === 200) {
            if (response?.data?.id) {
              setChatTopicId(response?.data?.id);
              topic_id = response?.data?.id;
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
      let newItems: Message[] = [...messages];
      if (action === "Insert") {
        const UserMessage: Message = createMessage(userInput, true, []);
        // console.log(getSourceRetriever(retriever, subRetriever));
        const AIMessage: Message = createMessage(
          "",
          false,
          [],
          getSourceRetriever(retriever, subRetriever)
        );
        newItems.push(...[UserMessage, AIMessage]);
        setTimeout(() => {
          setMessages(newItems);
        }, 0);
        setTimeout(handleScroll, 0);
      }
      setLoading(true);
      // @TODO: Use environment variable
      const applicationName = process.env.REACT_APP_APPLICATION_NAME as string;
      let chat_history: any[] = extractQAPairs(messages);
      
      let sourceList = [...selectedFiles]
      let params: any = {
        streaming: true,
        user_id: user?.id,
        socketId: socketId,
        // @TODO: Change this to the application name
        application_name: applicationName,
        team_id: user?.organizationMemberships[0]?.organization.id,
        folder_id: selectedFolderID,
        sub_folder_id: selectedSubFolderID,
        question: userText + "\n",
        openedDocument: openedDocument,
        action: action,
        message_id: message_id,
        chat_history: chat_history,
        topic_id: topic_id,
      };

      switch (retriever) {
        case "selected_source": {
          params.source_list = sourceList;
          params.rag = true;
          break;
        }
        case "chat_with_llm": {
          break;
        }
        case "serpapi": {
          switch (subRetriever) {
            case "arxiv": {
              params.retriever = "arxiv";
              break;
            }
            case "google_scholar": {
              params.sub_retriever = "google_scholar";
              params.retriever = "serpapi";
              break;
            }
            case "sciencedirect": {
              params.sub_retriever = "sciencedirect";
              params.retriever = "serpapi";
              break;
            }
          }
          break;
        }
        case "google_patents": {
          params.sub_retriever = "google_patents";
          params.retriever = "serpapi";
          break;
        }
        case "tavily": {
          params.retriever = "tavily";
          break;
        }
      }
      setUserInput("");
      handleScroll();
      const updatedMessages: Message[] = [...newItems];
      updatedMessages[index].text = "";
      updatedMessages[index].documents = [];
      updatedMessages[index].followUpQuestions = [];
      try {
        let response = await axios.post(
          process.env.REACT_APP_API_URL + "api/getAnswer",
          params
        );
        updatedMessages[index].text = response?.data?.message;
        updatedMessages[index].documents = response?.data?.documents;
        updatedMessages[index].source_retriever =
          response?.data?.source_retriever;
        updatedMessages[index].logs = response?.data?.logs;
        updatedMessages[index - 1].id = response?.data?.message_id;
        updatedMessages[index - 1].text = userText;
        setMessages(updatedMessages);
        // setTimeout(() => {
        //   handleScroll();
        // }, 0);
        return response;
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitFromEditAction = async (
    index: any,
    text: string,
    message_id: string
  ) => {
    if (messages.length >= index + 1) {
      setLoadingAnswerIndex(index);
      handleScroll();
      await getAnswer(text, "Update", message_id, index);
    }
  };

  const documentClick = (document: any) => {
    handleDocumentClick(
      document?.document,
      parseInt(document?.page) + 1
    );
  };

  const handleDocumentClick = (
    document: string,
    page: number = 1
  ) => {
    setOpenedDocument(document);
    setPageNumber(page);
    const extension = getExtension(document);
    if (extension === "pdf" || extension === "pptx") {
      let pdfviewerObject: any = $("#container");
      let pdfViewer = pdfviewerObject[0]?.ej2_instances?.[0];
      if (pdfViewer) {
        pdfViewer.navigation.goToPage(page);
      }
    } else if (extension === "docx") {
      let documentObject: any = $("#docx_container");
      let documentViewer =
        documentObject[0]?.ej2_instances?.[0]?.documentEditor;
      if (documentViewer) {
        documentViewer.scrollToPage(page);
      }
    } else if (extension === "xlsx") {
      let excelObject: any = $("#xlsx_container");
      let excelViewer = excelObject[0]?.ej2_instances?.[0];
      if (excelViewer) {
        excelViewer.activeSheetIndex = page > 0 ? page - 1 : 0;
      }
    }
  };

  const sourceFileClick = (msgIndex: number, sourceIndex: number) => {
    setSelectedSourceIndex([msgIndex, sourceIndex]);
  };

  const handleFollowUpQuestionClick = async (question: string) => {
    setUserInput(question);
    setFollowUpClicked(true);
  };

  const handleBackClick = () => {
    setChatTopicId("");
    setMessages([]);
    setSelectedText("");
    setButtonPosition(null);
    fetchChatTopics();
  };

  const LogComponent = ({
    logs,
    viewMore,
    setViewMore,
  }: {
    logs: any;
    viewMore: any;
    setViewMore: any;
  }) => {
    return (
      <div className="flex gap-3 xl:gap-4">
        <div className="w-6 xl:w-8 h-6 xl:h-8 rounded-full flex flex-none items-center justify-center">
          {CurrentChatIcon()}
        </div>
        <div
          className="flex flex-col gap-[13px] xl:gap-[18px]"
          style={{
            width: `calc(100% - ${
              48 * (deviceType === "laptop" ? 0.75 : 1)
            }px)`,
          }}
        >
          <div
            className="overflow-hidden"
            style={{
              maxHeight: viewMore
                ? "none"
                : 312 * (deviceType === "laptop" ? 0.75 : 1),
            }}
          >
            {logs.map((log: any, index: number) => (
              <div
                className="text-xs xl:text-base font-normal text-[#000000cc]"
                key={index}
              >
                {log}
              </div>
            ))}
          </div>
          <button
            className={`outline-none border-none bg-transparent gap-[6px] xl:gap-2 items-center w-fit ${
              logs.length > 4 ? "flex" : "hidden"
            }`}
            onClick={() => setViewMore(!viewMore)}
          >
            <span className="text-xs xl:text-base font-bold text-[#3B82F6]">
              {viewMore ? "View Less" : "View More"}
            </span>
            {viewMore ? (
              <MdKeyboardArrowUp className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-[#3B82F6]" />
            ) : (
              <MdKeyboardArrowDown className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-[#3B82F6]" />
            )}
          </button>
          <div className="flex gap-[6px] xl:gap-2 w-full flex-col">
            <Skeleton
              height={10}
              sx={{ width: "74.5%", transform: "scale(1)" }}
            />
            <Skeleton
              height={10}
              sx={{ width: "100%", transform: "scale(1)" }}
            />
            <Skeleton
              height={10}
              sx={{ width: "42%", transform: "scale(1)" }}
            />
            <Skeleton
              height={10}
              sx={{ width: "100%", transform: "scale(1)" }}
            />
            <Skeleton
              height={10}
              sx={{ width: "68%", transform: "scale(1)" }}
            />
          </div>
        </div>
      </div>
    );
  };

  const CurrentChatIcon = () => {
    switch (retriever) {
      case "selected_source": {
        return (
          <img
            src={folderCheckedIcon}
            alt=""
            className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none"
          />
        );
      }
      case "chat_with_llm": {
        return (
          <GiArtificialHive className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none" />
        );
      }
      case "serpapi": {
        switch (subRetriever) {
          case "arxiv": {
            return (
              <img
                data-event-name="Research: Arxiv Selected"
                src={arxivIcon}
                alt=""
                className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none"
              />
            );
          }
          case "google_scholar": {
            return (
              <GoogleScholarIcon className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none" />
            );
          }
          case "sciencedirect": {
            return (
              <img
                src={elsevierNonSolusIcon}
                data-event-name="Selecting Science Direct"
                alt=""
                className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none"
              />
            );
          }
        }
        break;
      }
      case "google_patents": {
        return (
          <GooglePatentsIcon className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none" />
        );
      }
      case "tavily": {
        return (
          <img
            src={chatTypeIcon}
            alt=""
            className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none"
          />
        );
      }
    }
    return null;
  };

  const handleGetHMWQuestions = async (context: string) => {
    try {
      let params: any = {
        team_id: user?.organizationMemberships[0]?.organization.id,
        source_list: [],
        name: "Brainstorm " + moment().format("YYYY-MM-DD h:mm a"),
        context: context,
        fromSource: false,
        socketId,
      };
      const result = await axios.post(
        process.env.REACT_APP_API_URL + "api/getHMWQuestions",
        params
      );

      const searchParams = new URLSearchParams();
      searchParams.set("render_type", "modal");
      searchParams.set("render_modal", "brainstorm_modal");
      searchParams.set("brainstorm_id", result.data.brainstorm_id);

      history.push({
        pathname: "/concept",
        search: `?${searchParams.toString()}`,
      });

      handlePopoverClose();
      getBrainstormList();
    } catch (error) {
      console.log(error);
    }
  };

  // FIXME: Unused
  const stopGenerating = () => {
    if (socket) {
      const data = {
        socketId: socketId,
        stopped: true,
      };
      socket.emit("stop_generation", data);
      setShowStopGeneratingLoading(true);
    }
  };

  return (
    <div className="h-full flex flex-col relative">
      <div className="flex items-center mb-3 xl:mb-4 justify-between">
        {chatTopicId !== "" ? (
          <button
            className="flex items-center cursor-pointer gap-[6px] xl:gap-2"
            disabled={isGenerating}
            onClick={() => {
              handleBackClick();
            }}
          >
            <img
              src={backIcon}
              alt="BackIcon"
              className="w-[18px] xl:w-6 h-[18px] xl:h-6"
            />
            <span className="font-Satoshi font-bold text-[#71717A] text-xs xl:text-base">
              Back
            </span>
          </button>
        ) : (
          <span className="font-Satoshi text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] font-bold text-[#00000065]">
            Chats
          </span>
        )}
      </div>
      <div
        className="flex flex-col gap-[12px] py-4 h-[calc(100vh-217px)] xl:h-[calc(100vh-290px)] overflow-x-hidden overflow-y-auto"
        ref={ref}
      >
        {chatTopicId !== "" ? (
          <>
            {messages?.map((msg, index) => {
              if (msg.isUserMessage) {
                return (
                  <Question
                    msg={msg}
                    index={index}
                    handleSubmitFromEditAction={(text: string) =>
                      handleSubmitFromEditAction(
                        index + 1,
                        text,
                        messages[index].id
                      )
                    }
                    key={index}
                  />
                );
              } else {
                if (isLoading && loadingAnswerIndex === index) {
                  if (retriever === "selected_source") {
                    return <Loading key={index} />;
                  } else {
                    return (
                      <LogComponent
                        logs={initialSearchLog}
                        viewMore={viewMore}
                        setViewMore={(e: any) => setViewMore(e)}
                        key={index}
                      />
                    );
                  }
                } else
                  return (
                    <Answer
                      msg={msg}
                      index={index}
                      documentClick={documentClick}
                      sourceIndexClick={sourceFileClick}
                      selectedSourceIndex={selectedSourceIndex}
                      handleFollowUpQuestionClick={handleFollowUpQuestionClick}
                      handleSubmitFromEditAction={() =>
                        handleSubmitFromEditAction(
                          index,
                          messages[index - 1].text,
                          messages[index - 1].id
                        )
                      }
                      key={index}
                      setSelectedText={setSelectedText}
                      setButtonPosition={setButtonPosition}
                      chatRef={ref}
                      initialSearch={retriever !== "selected_source"}
                      isGenerating={isGenerating}
                      loadingAnswerIndex={loadingAnswerIndex}
                    />
                  );
              }
            })}
          </>
        ) : (
          chatTopics.map((topic, index) => {
            return (
              <Topic
                topic={topic}
                fetchChatHistory={fetchChatHistory}
                fetchChatTopics={fetchChatTopics}
                key={index}
              ></Topic>
            );
          })
        )}
        {/* Render Button in Chat Component */}
        {chatTopicId !== "" && buttonPosition && selectedText && (
          <button
            className="absolute z-50 px-[9px] xl:px-3 py-2 bg-[#3B82F6] text-white rounded-lg flex items-center gap-[3px] xl:gap-1"
            style={{
              top: `${buttonPosition.top}px`,
              left: `${buttonPosition.left}px`,
            }}
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
              handlePopoverOpen(e);
            }}
          >
            <RisingStarIcon className="w-3 xl:w-4 h-3 xl:h-4 text-white" />
          </button>
        )}
        {isLoading &&
          chatTopicId !== "" &&
          loadingAnswerIndex === -1 &&
          (retriever === "selected_source" ? (
            <div className="pl-11">
              <Loading />
            </div>
          ) : (
            <LogComponent
              logs={initialSearchLog}
              viewMore={viewMore}
              setViewMore={(e: any) => setViewMore(e)}
            />
          ))}
        {/* {isGenerating && (
          <div className="flex justify-center absolute w-full bottom-[116px]">
            <button
              className={`w-[205px] h-12 rounded-lg border border-solid border-[#3B82F6] bg-white flex items-center justify-center gap-[6px] xl:gap-2 ${
                showGeneratingLoading ? "opacity-60" : ""
              }`}
              onClick={stopGenerating}
              disabled={showGeneratingLoading}
            >
              <span className="text-blue-500 text-xs xl:text-base font-medium font-Satoshi">
                Stop Generating
              </span>
              {showGeneratingLoading && (
                <BiLoaderAlt className="w-[21px] h-[21px] text-blue-500 animate-spin" />
              )}
            </button>
          </div>
        )} */}
      </div>
      <div className="w-full border border-solid border-[#D4D4D8] rounded-lg bg-[#FCFCFC] flex items-start p-[6px] xl:p-2 gap-[6px] xl:gap-2 h-[72px] xl:h-24 mt-[7px] xl:mt-[10px]">
        <button
          className="w-[21px] xl:w-7 h-[21px] xl:h-7 flex-none border border-solid border-[#D4D4D8] rounded-full flex items-center justify-center"
          onClick={(e: MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(e.currentTarget);
            setOpen((prev) => !prev);
          }}
        >
          {CurrentChatIcon()}
        </button>
        <textarea
          className="h-full outline-none border-0 flex-1 scrollbar-hide resize-none whitespace-pre-wrap"
          placeholder="Ask me anything"
          onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
            setUserInput(e.target.value);
            // Track typing event in Amplitude
            if (!hasStartedTyping) {
              amplitude.track("Research: Query Typed", {
                content: e.target.value,
                length: e.target.value.length,
              });
              setHasStartedTyping(true); // Prevent tracking again until submission
            }
          }}
          value={userInput}
          onKeyDown={(e: KeyboardEvent<HTMLTextAreaElement>) => onEnterPress(e)}
          ref={textareaRef}
          disabled={!setting?.verified}
        ></textarea>
        <button
          className="w-[21px] xl:w-7 h-[21px] xl:h-7 flex-none bg-[#3B82F6] rounded-full flex items-center justify-center"
          data-event-name="Research: Submit Clicked"
          onClick={() => {
            submit();
            setHasStartedTyping(false); // Reset typing flag after submission
          }}
          disabled={!setting?.verified}
        >
          <GrFormNextLink className="text-lg text-white" />
        </button>
      </div>

      <Popper
        // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
        sx={{ zIndex: 1200 }}
        open={open}
        anchorEl={anchorEl}
        placement={"top"}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            onClickAway={() => {
              if (!journalOpen) setOpen(false);
            }}
          >
            <Fade {...TransitionProps} timeout={350}>
              <div
                className="p-[4px] xl:p-[6px] rounded-[14px] bg-white shadow-2xl"
                ref={journalAnchorRef}
              >
                <div className="flex flex-col gap-[3px] xl:gap-1">
                  <div
                    className={`flex items-center justify-between gap-[9px] xl:gap-3 py-[7px] xl:py-[10px] px-[9px] xl:px-3 cursor-pointer rounded-xl ${
                      retriever === "selected_source" ? "bg-[#DBEAFE]" : ""
                    }`}
                    onClick={() => {
                      setRetriever("selected_source");
                      setOpen(false);
                      setJournalOpen(false);
                      setSubRetriever("");
                    }}
                  >
                    <div className="flex gap-[9px] xl:gap-3 items-center">
                      <img
                        src={folderCheckedIcon}
                        alt=""
                        className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none"
                      />
                      <span
                        className="text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] font-medium text-[#3F3F46]"
                        data-event-name="Research: Selected Sources Selected"
                      >
                        Selected Sources
                      </span>
                    </div>
                  </div>
                  <div
                    className={`flex items-center justify-between gap-[9px] xl:gap-3 py-[7px] xl:py-[10px] px-[9px] xl:px-3 cursor-pointer rounded-xl ${
                      retriever === "chat_with_llm" ? "bg-[#DBEAFE]" : ""
                    }`}
                    onClick={() => {
                      setRetriever("chat_with_llm");
                      setOpen(false);
                      setJournalOpen(false);
                      setSubRetriever("");
                    }}
                  >
                    <div className="flex gap-[9px] xl:gap-3 items-center">
                      <GiArtificialHive className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none" />
                      <span
                        className="text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] font-medium text-[#3F3F46]"
                        data-event-name="Research: Selected Sources Selected"
                      >
                        Chat With LLM
                      </span>
                    </div>
                  </div>
                  <div
                    className={`flex items-center justify-between gap-[9px] xl:gap-3 py-[7px] xl:py-[10px] px-[9px] xl:px-3 cursor-pointer rounded-xl ${
                      retriever === "tavily" ? "bg-[#DBEAFE]" : ""
                    }`}
                    onClick={() => {
                      setRetriever("tavily");
                      setOpen(false);
                      setJournalOpen(false);
                      setSubRetriever("");
                    }}
                  >
                    <div className="flex gap-[9px] xl:gap-3 items-center">
                      <img
                        src={chatTypeIcon}
                        alt=""
                        className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none"
                      />
                      <span
                        className="text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] font-medium text-[#3F3F46]"
                        data-event-name="Research: Tavily Selected"
                      >
                        Tavily (Full Web Search)
                      </span>
                    </div>
                  </div>
                  <div
                    className={`flex items-center justify-between gap-[9px] xl:gap-3 py-[7px] xl:py-[10px] px-[9px] xl:px-3 cursor-pointer rounded-xl ${
                      retriever === "serpapi" ? "bg-[#DBEAFE]" : ""
                    }`}
                    onClick={() => {
                      setJournalOpen((prev) => !prev);
                    }}
                  >
                    <div className="flex gap-[9px] xl:gap-3 items-center">
                      <img
                        src={internetIcon}
                        alt=""
                        className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none"
                      />
                      <span className="text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] font-medium text-[#3F3F46]">
                        Journals
                      </span>
                    </div>
                    <GrFormNext className="w-[15px] xl:w-5 h-[15px] xl:h-5 flex-none text-[#A1A1AA]" />
                  </div>
                  <div
                    className={`flex items-center justify-between gap-[9px] xl:gap-3 py-[7px] xl:py-[10px] px-[9px] xl:px-3 cursor-pointer rounded-xl ${
                      retriever === "google_patents" ? "bg-[#DBEAFE]" : ""
                    }`}
                    onClick={() => {
                      setRetriever("google_patents");
                      setOpen(false);
                      setJournalOpen(false);
                      setSubRetriever("");
                    }}
                  >
                    <div className="flex gap-[9px] xl:gap-3 items-center">
                      <GooglePatentsIcon className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none" />
                      <span
                        className="text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] font-medium text-[#3F3F46]"
                        data-event-name="Research: Google Patents Selected"
                      >
                        Google Patents API
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>

      <Popper
        // Note: The following zIndex style is specifically for documentation purposes and may not be necessary in your application.
        sx={{ zIndex: 1200 }}
        open={journalOpen}
        anchorEl={journalAnchorRef.current}
        placement={"right-end"}
        transition
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => setJournalOpen(false)}>
            <Fade {...TransitionProps} timeout={350}>
              <div className="p-[4px] xl:p-[6px] rounded-[14px] bg-white shadow-2xl ml-[9px] xl:ml-3">
                <div className="flex flex-col gap-[3px] xl:gap-1">
                  <div
                    className={`flex items-center justify-between gap-[9px] xl:gap-3 py-[7px] xl:py-[10px] px-[9px] xl:px-3 cursor-pointer rounded-xl`}
                  >
                    <div className="flex gap-[9px] xl:gap-3 items-center">
                      <SiResearchgate className="text-xl w-[15px] xl:w-5 h-[15px] xl:h-5 flex-none" />
                      <span
                        className="text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] font-medium text-[#3F3F46]"
                        data-event-name="Research: ResearchGate Selected"
                      >
                        ResearchGate
                      </span>
                    </div>
                  </div>
                  <div
                    className={`flex items-center justify-between gap-[9px] xl:gap-3 py-[7px] xl:py-[10px] px-[9px] xl:px-3 cursor-pointer rounded-xl ${
                      subRetriever === "sciencedirect" ? "bg-[#DBEAFE]" : ""
                    }`}
                    data-event-name="Research: ScienceDirect Selected"
                    onClick={() => {
                      setRetriever("serpapi");
                      setSubRetriever("sciencedirect");
                      setOpen(false);
                      setJournalOpen(false);
                    }}
                  >
                    <div className="flex gap-[9px] xl:gap-3 items-center">
                      <img
                        src={elsevierNonSolusIcon}
                        alt=""
                        className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none"
                      />
                      <span
                        className="text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] font-medium text-[#3F3F46]"
                        data-event-name="Research: ScienceDirect Selected"
                      >
                        ScienceDirect
                      </span>
                    </div>
                  </div>
                  <div
                    className={`flex items-center justify-between gap-[9px] xl:gap-3 py-[7px] xl:py-[10px] px-[9px] xl:px-3 cursor-pointer rounded-xl ${
                      subRetriever === "arxiv" ? "bg-[#DBEAFE]" : ""
                    }`}
                    data-event-name="Research: Arxiv Selected"
                    onClick={() => {
                      setRetriever("serpapi");
                      setSubRetriever("arxiv");
                      setOpen(false);
                      setJournalOpen(false);
                    }}
                  >
                    <div className="flex gap-[9px] xl:gap-3 items-center">
                      <img
                        src={arxivIcon}
                        alt=""
                        className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none"
                      />
                      <span
                        className="text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] font-medium text-[#3F3F46]"
                        data-event-name="Research: Arxiv Selected"
                      >
                        Arxiv
                      </span>
                    </div>
                  </div>
                  <div
                    className={`flex items-center justify-between gap-[9px] xl:gap-3 py-[7px] xl:py-[10px] px-[9px] xl:px-3 cursor-pointer rounded-xl ${
                      subRetriever === "google_scholar" ? "bg-[#DBEAFE]" : ""
                    }`}
                    onClick={() => {
                      setRetriever("serpapi");
                      setSubRetriever("google_scholar");
                      setOpen(false);
                      setJournalOpen(false);
                    }}
                  >
                    <div className="flex gap-[9px] xl:gap-3 items-center">
                      <GoogleScholarIcon className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none" />
                      <span
                        className="text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] font-medium text-[#3F3F46]"
                        data-event-name="Research: Google Scholar Selected"
                      >
                        Google Scholar API
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>

      <Popover
        open={popoverOpen}
        anchorEl={popoverAnchorEl}
        onClose={() => handlePopoverClose()}
        className="mt-[7px] xl:mt-[10px] ml-[4px] xl:ml-[5px]"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="p-[4px] xl:p-[6px] bg-white rounded-lg flex flex-col gap-[3px] xl:gap-1 w-[176px] xl:w-[235px]">
          <div
            className="w-full px-[9px] xl:px-3 py-[7px] xl:py-[10px] flex gap-[9px] xl:gap-3 cursor-pointer"
            onClick={() => {
              handleGetHMWQuestions(selectedText || "");
            }}
          >
            <BrainIcon
              stroke="#09090B"
              className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none"
            />
            <div
              className="text-xs xl:text-base font-medium text-[#3F3F46]"
              data-event-name="Research: Brainstorm Via Response Started"
            >
              Start Brainstorm
            </div>
          </div>
          <div
            className="w-full px-[9px] xl:px-3 py-[7px] xl:py-[10px] flex gap-[9px] xl:gap-3 cursor-pointer"
            onClick={() => {
              openConceptAddFolderModal("window_selection", selectedText || "");
              // Handle button click event
              setSelectedText(undefined);
              setButtonPosition(null);
            }}
          >
            <LightIcon
              stroke="#18181B"
              className="w-[18px] xl:w-6 h-[18px] xl:h-6 flex-none"
            />
            <div
              className="text-xs xl:text-base font-medium text-[#3F3F46]"
              data-event-name="Research: Concept Via Response Created"
            >
              Create Concept
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default Chat;

export const scrollToBottom = (element: HTMLElement) => {
  element.scroll({
    behavior: "auto",
    top: element.scrollHeight,
  });
};
