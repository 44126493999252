import { type FC, useEffect, useState } from "react";
import { HiOutlineFolderMinus, HiOutlineFolderPlus } from "react-icons/hi2";
import { useRagContext } from "../../../contexts/RagContext";
import BpCheckbox from "../../common/BPCheckbox";

interface TreeNode {
  name: string;               // folder/file name
  isFolder: boolean;
  children: TreeNode[];
  originalFilePath?: string;  // the real path if it's a file
  requiresOCR?: boolean;
}

interface CreateConceptFolderProps {
  folder: {
    structure: any;  // e.g. { "Team Documents": {...}, "My Documents": {...} }
  };
}

const CreateConceptFolder: FC<CreateConceptFolderProps> = ({ folder }) => {
  const [tree, setTree] = useState<TreeNode[]>([]);
  const [expandedPaths, setExpandedPaths] = useState<Set<string>>(new Set());

  const {
    selectedCreateConceptFiles,
    setSelectedCreateConceptFiles,
  } = useRagContext();

  // Build local tree from folder.structure
  useEffect(() => {
    if (folder?.structure) {
      setTree(buildTree(folder.structure, ""));
    }
  }, [folder]);

  function buildTree(data: any, currentPath: string): TreeNode[] {
    if (!data || typeof data !== "object") return [];
    return Object.keys(data).map((key) => {
      const val = data[key];
      // if val.__file => file
      const isFile = val && val.__file === true;

      const newPath = currentPath ? `${currentPath}/${key}` : key;

      if (isFile) {
        return {
          name: key,
          isFolder: false,
          children: [],
          originalFilePath: val.originalFilePath,
          requiresOCR: val.requiresOCR || false,
        };
      } else {
        // Folder => recurse
        return {
          name: key,
          isFolder: true,
          children: buildTree(val, newPath),
        };
      }
    });
  }

  // Expand/collapse
  const toggleExpand = (path: string) => {
    const copy = new Set(expandedPaths);
    if (copy.has(path)) copy.delete(path);
    else copy.add(path);
    setExpandedPaths(copy);
  };

  // Recursively gather all files under a folder
  function gatherAllFilePaths(node: TreeNode): string[] {
    if (!node.isFolder) {
      return node.originalFilePath ? [node.originalFilePath] : [];
    }
    let results: string[] = [];
    node.children.forEach((child) => {
      results = results.concat(gatherAllFilePaths(child));
    });
    return results;
  }

  // Toggle entire folder => select/unselect all child files
  const handleToggleFolder = (node: TreeNode) => {
    const allFilePaths = gatherAllFilePaths(node);
    const allSelected = allFilePaths.every((f) => selectedCreateConceptFiles.includes(f));

    let updated = [...selectedCreateConceptFiles];
    if (allSelected) {
      updated = updated.filter((p) => !allFilePaths.includes(p));
    } else {
      allFilePaths.forEach((p) => {
        if (!updated.includes(p)) updated.push(p);
      });
    }
    setSelectedCreateConceptFiles(updated);
  };

  // Toggle single file
  const handleToggleFile = (filePath: string) => {
    let updated = [...selectedCreateConceptFiles];
    const idx = updated.indexOf(filePath);
    if (idx >= 0) updated.splice(idx, 1);
    else updated.push(filePath);

    setSelectedCreateConceptFiles(updated);
  };

  // Check if a folder is fully selected
  const areAllChildrenSelected = (node: TreeNode): boolean => {
    const filePaths = gatherAllFilePaths(node);
    return filePaths.length > 0 && filePaths.every((f) => selectedCreateConceptFiles.includes(f));
  };

  // Render recursively
  const renderNodes = (nodes: TreeNode[], parentPath: string) => {
    return nodes.map((node) => {
      if (node.isFolder) {
        // Folder
        const nodePath = parentPath ? `${parentPath}/${node.name}` : node.name;
        const expanded = expandedPaths.has(nodePath);
        const folderSelected = areAllChildrenSelected(node);

        return (
          <div key={nodePath}>
            <div className="px-3 py-2 flex items-start gap-3 rounded-[10px] group sidebar-folder w-full">
              <button
                onClick={() => toggleExpand(nodePath)}
                className="outline-none flex-none"
              >
                {expanded ? (
                  <HiOutlineFolderMinus className="text-lg xl:text-2xl text-[#3B82F6]" />
                ) : (
                  <HiOutlineFolderPlus className="text-lg xl:text-2xl text-[#3B82F6]" />
                )}
              </button>
              <p
                className="text-xs xl:text-base font-medium text-[#3B82F6] folder-name text-break min-w-[97px] xl:min-w-[130px]"
                onClick={() => toggleExpand(nodePath)}
              >
                {node.name}
              </p>
              {/* Folder checkbox */}
              <button
                className={`outline-none flex-none ml-auto flex h-[18px] xl:h-6 items-center ${
                  folderSelected ? "visible" : "invisible group-hover:visible"
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleToggleFolder(node);
                }}
              >
                <BpCheckbox
                  size="small"
                  className="w-[10px] xl:w-[14px] h-[10px] xl:h-[14px]"
                  checked={folderSelected}
                  />
              </button>
            </div>
            {expanded && node.children.length > 0 && (
              <div className="relative pl-6 flex-col sidebar-folder-items">
                {renderNodes(node.children, nodePath)}
              </div>
            )}
          </div>
        );
      } else {
        // File
        const filePath = node.originalFilePath || "";
        const isSelected = selectedCreateConceptFiles.includes(filePath);

        return (
          <div
            key={filePath}
            className={`w-full px-3 py-2 rounded-xl flex items-start gap-1 justify-between cursor-pointer group ${
              isSelected ? "bg-[#E4E4E7] text-[#18181B]" : "bg-transparent text-[#52525B]"
            }`}
            onClick={() => handleToggleFile(filePath)}
          >
            <p className="text-xs xl:text-base document-title">{node.name}</p>
            <div className="flex gap-2 ml-auto">
              <button
                className={`outline-none flex-none ml-auto flex items-center ${
                  isSelected ? "visible" : "invisible group-hover:visible"
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleToggleFile(filePath);
                }}
              >
                <BpCheckbox
                  size="small"
                  className="w-[10px] xl:w-[14px] h-[10px] xl:h-[14px]"
                  checked={isSelected}
                  />
              </button>
            </div>
          </div>
        );
      }
    });
  };

  return <>{renderNodes(tree, "")}</>;
};

export default CreateConceptFolder;
