import { type FC, useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useUserContext } from "../../../contexts/UserContext";
import DiamondValueIcon from "../../icons/DiamondValueIcon";
import { BiLoaderAlt } from "react-icons/bi";
import PuzzleIcon from "../../icons/PuzzleIcon";
import LightOnIcon from "../../icons/LightOnIcon";
import { useRagContext } from "../../../contexts/RagContext";

interface OverviewContentProps {
  conceptData: any;
  concept_title: string;
  concept_description: string;
  problem: string;
  need: string;
  solution: string;
  value: string;
  visible?: boolean;
  getConceptDetail: () => void;
}

const OverviewContent: FC<OverviewContentProps> = ({
  conceptData,
  concept_title,
  concept_description,
  problem,
  need,
  solution,
  value,
  visible,
  getConceptDetail,
}) => {
  const { user } = useUserContext();
  const { selectedConceptId, getConceptFolders } = useRagContext();
  const [overviewData, setOverviewData] = useState({
    concept_title,
    concept_description,
    problem,
    need,
    solution,
    value,
  });
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [regeneratingConcepts, setRegeneratingConcepts] = useState<string[]>(
    []
  );

  const generateBrainStormConceptDetails = async (type: string) => {
    try {
      setRegeneratingConcepts((prev) => [...prev, type]);
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/generateConceptDetails",
        {
          concept_id: conceptData.id,
          team_id: user?.organizationMemberships[0]?.organization.id,
          concept: {
            concept_title: overviewData.concept_title,
            concept_description: overviewData.concept_description,
          },
          type,
        }
      );
      setRegeneratingConcepts((prev) => prev.filter((e) => e !== type));

      setOverviewData((prev: any) => ({
        ...prev,
        [type]: response.data.response,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const saveBrainStormConceptDetails = useCallback(async () => {
    try {
      setShowLoading(true);
      let params: any = {
        id: selectedConceptId,
        concept_title: overviewData.concept_title,
        concept_description: overviewData.concept_description,
        problem: overviewData.problem || "",
        need: overviewData.need || "",
        solution: overviewData.solution || "",
        value: overviewData.value || "",
      };
      await axios.post(
        process.env.REACT_APP_API_URL + "api/editConcept",
        params
      );
      getConceptDetail();
      getConceptFolders();
      setShowLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, [overviewData, selectedConceptId]);

  useEffect(() => {
    setOverviewData({
      concept_title,
      concept_description,
      problem,
      need,
      solution,
      value,
    });
  }, [concept_title, concept_description]);

  if (!visible) return null;

  return (
    <div className="flex flex-col gap-3 xl:gap-4 p-[15px] xl:p-5">
      <div className="text-xs xl:text-base font-normal text-[#000000cb] c-markdown-text max-h-[calc(100vh-198px)] xl:max-h-[calc(100vh-264px)] overflow-x-hidden overflow-y-auto">
        <div className="">
          <div className="flex flex-col gap-3 xl:gap-4">
            <div className="">
              <label
                htmlFor="brainstorm_concept_title"
                className="ml-[3px] xl:ml-1 mb-[3px] xl:mb-1 text-xs xl:text-base font-medium text-[#09090B]"
              >
                Concept
              </label>
              <input
                type="text"
                id="brainstorm_concept_title"
                name="brainstorm_concept_title"
                className="w-full h-9 xl:h-12 rounded-lg outline-none bg-[#FCFCFC] border border-solid border-[#D4D4D8] px-3 xl:px-4 py-[9px] xl:py-3 text-[10px] xl:text-sm font-medium text-[#3F3F46]"
                value={overviewData.concept_title}
                onChange={(e) =>
                  setOverviewData((prev: any) => ({
                    ...prev,
                    concept_title: e.target.value,
                  }))
                }
              />
            </div>
            <div className="">
              <label
                htmlFor="brainstorm_concept_description"
                className="ml-[3px] xl:ml-1 mb-[3px] xl:mb-1 text-xs xl:text-base font-medium text-[#09090B]"
              >
                Description
              </label>
              <textarea
                id="brainstorm_concept_description"
                name="brainstorm_concept_description"
                className="w-full h-[72px] xl:h-24 rounded-lg outline-none bg-[#FCFCFC] border border-solid border-[#D4D4D8] px-3 xl:px-4 py-[9px] xl:py-3 text-[10px] xl:text-sm font-medium text-[#3F3F46]"
                value={overviewData.concept_description}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setOverviewData((prev: any) => ({
                    ...prev,
                    concept_description: e.target.value,
                  }))
                }
              />
            </div>
            {process.env.REACT_APP_BOSCH_INSTANCE !== "true" && (
              <div className="grid grid-cols-2 gap-3 xl:gap-4">
                <div className="border border-solid border-[#D4D4D8] rounded-lg overflow-hidden p-[10px] xl:p-[14px] bg-white">
                  <div className="flex flex-col gap-3 xl:gap-4 h-full">
                    <div className="w-9 xl:w-12 h-9 xl:h-12 flex items-center justify-center bg-[#F4F4F5] rounded-[4px]">
                      <span className="text-[15px] xl:text-xl text-[#3B82F6] font-medium">
                        <DiamondValueIcon className="w-[15px] xl:w-5 h-[13px] xl:h-[18px]" />
                      </span>
                    </div>
                    <div className="">
                      <div className="text-[13px] xl:text-lg font-bold text-[#18181B] mb-[6px] xl:mb-2">
                        Problem
                      </div>
                      <textarea
                        className={`text-[10px] xl:text-sm font-medium text-[#71717A] w-full border-none outline-none resize-none bg-transparent ${
                          overviewData.problem !== ""
                            ? "h-[90px] xl:h-[120px]"
                            : "h-[63px] xl:h-[84px]"
                        }`}
                        value={overviewData.problem}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                          setOverviewData((prev: any) => ({
                            ...prev,
                            problem: e.target.value,
                          }))
                        }
                      ></textarea>
                    </div>
                    {(!overviewData.problem || overviewData.problem === "") && (
                      <div className="flex justify-end">
                        <button
                          className="p-0 bg-transparent border-none outline-none flex items-center gap-[6px] xl:gap-2"
                          onClick={() =>
                            generateBrainStormConceptDetails("problem")
                          }
                          disabled={Boolean(
                            regeneratingConcepts.find((e) => e === "problem")
                          )}
                        >
                          {regeneratingConcepts.find((e) => e === "problem") ? (
                            <BiLoaderAlt className="w-[10px] xl:w-[14px] h-[10px] xl:h-[14px] text-[#3B82F6] animate-spin" />
                          ) : (
                            <span
                              className="text-[10px] xl:text-sm font-bold text-[#3B82F6]"
                              data-event-name="Concepts: Problem Fill Suggested"
                            >
                              Suggest fill
                            </span>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="border border-solid border-[#D4D4D8] rounded-lg overflow-hidden p-[10px] xl:p-[14px] bg-white">
                  <div className="flex flex-col gap-3 xl:gap-4 h-full">
                    <div className="w-9 xl:w-12 h-9 xl:h-12 flex items-center justify-center bg-[#F4F4F5] rounded-[4px]">
                      <span className="text-[15px] xl:text-xl text-[#3B82F6] font-medium">
                        <PuzzleIcon className="w-[19px] xl:w-[26px] h-[19px] xl:h-[26px]" />
                      </span>
                    </div>
                    <div className="">
                      <div className="text-[13px] xl:text-lg font-bold text-[#18181B] mb-[6px] xl:mb-2">
                        Needs
                      </div>
                      <textarea
                        className={`text-[10px] xl:text-sm font-medium text-[#71717A] w-full border-none outline-none resize-none bg-transparent ${
                          overviewData.need !== ""
                            ? "h-[90px] xl:h-[120px]"
                            : "h-[63px] xl:h-[84px]"
                        }`}
                        value={overviewData.need}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                          setOverviewData((prev: any) => ({
                            ...prev,
                            need: e.target.value,
                          }))
                        }
                      ></textarea>
                    </div>
                    {(!overviewData.need || overviewData.need === "") && (
                      <div className="flex justify-end">
                        <button
                          className="p-0 bg-transparent border-none outline-none flex items-center gap-[6px] xl:gap-2"
                          onClick={() =>
                            generateBrainStormConceptDetails("need")
                          }
                          disabled={Boolean(
                            regeneratingConcepts.find((e) => e === "need")
                          )}
                        >
                          {regeneratingConcepts.find((e) => e === "need") ? (
                            <BiLoaderAlt className="w-[10px] xl:w-[14px] h-[10px] xl:h-[14px] text-[#3B82F6] animate-spin" />
                          ) : (
                            <span
                              className="text-[10px] xl:text-sm font-bold text-[#3B82F6]"
                              data-event-name="Concepts: Needs Fill Suggested"
                            >
                              Suggest fill
                            </span>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="border border-solid border-[#D4D4D8] rounded-lg overflow-hidden p-[10px] xl:p-[14px] bg-white">
                  <div className="flex flex-col gap-3 xl:gap-4 h-full">
                    <div className="w-9 xl:w-12 h-9 xl:h-12 flex items-center justify-center bg-[#F4F4F5] rounded-[4px]">
                      <span className="text-[15px] xl:text-xl text-[#3B82F6] font-medium">
                        <LightOnIcon className="w-[19px] xl:w-[26px] h-[19px] xl:h-[26px]" />
                      </span>
                    </div>
                    <div className="">
                      <div className="text-[13px] xl:text-lg font-bold text-[#18181B] mb-[6px] xl:mb-2">
                        Solutions
                      </div>
                      <textarea
                        className={`text-[10px] xl:text-sm font-medium text-[#71717A] w-full border-none outline-none resize-none bg-transparent ${
                          overviewData.solution !== ""
                            ? "h-[90px] xl:h-[120px]"
                            : "h-[63px] xl:h-[84px]"
                        }`}
                        value={overviewData.solution}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                          setOverviewData((prev: any) => ({
                            ...prev,
                            solution: e.target.value,
                          }))
                        }
                      ></textarea>
                    </div>
                    {(!overviewData.solution ||
                      overviewData.solution === "") && (
                      <div className="flex justify-end">
                        <button
                          className="p-0 bg-transparent border-none outline-none flex items-center gap-[6px] xl:gap-2"
                          onClick={() =>
                            generateBrainStormConceptDetails("solution")
                          }
                          disabled={Boolean(
                            regeneratingConcepts.find((e) => e === "solution")
                          )}
                        >
                          {regeneratingConcepts.find(
                            (e) => e === "solution"
                          ) ? (
                            <BiLoaderAlt className="w-[10px] xl:w-[14px] h-[10px] xl:h-[14px] text-[#3B82F6] animate-spin" />
                          ) : (
                            <span
                              className="text-[10px] xl:text-sm font-bold text-[#3B82F6]"
                              data-event-name="Concepts: Solution Fill Suggested"
                            >
                              Suggest fill
                            </span>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="border border-solid border-[#D4D4D8] rounded-lg overflow-hidden p-[10px] xl:p-[14px] bg-white">
                  <div className="flex flex-col gap-3 xl:gap-4 h-full">
                    <div className="w-9 xl:w-12 h-9 xl:h-12 flex items-center justify-center bg-[#F4F4F5] rounded-[4px]">
                      <span className="text-[15px] xl:text-xl text-[#3B82F6] font-medium">
                        <DiamondValueIcon className="w-[18px] xl:w-6 h-[15px] xl:h-5" />
                      </span>
                    </div>
                    <div className="">
                      <div className="text-[13px] xl:text-lg font-bold text-[#18181B] mb-[6px] xl:mb-2">
                        Value
                      </div>
                      <textarea
                        className={`text-[10px] xl:text-sm font-medium text-[#71717A] w-full border-none outline-none resize-none bg-transparent ${
                          overviewData.value !== ""
                            ? "h-[90px] xl:h-[120px]"
                            : "h-[63px] xl:h-[84px]"
                        }`}
                        value={overviewData.value}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                          setOverviewData((prev: any) => ({
                            ...prev,
                            value: e.target.value,
                          }))
                        }
                      ></textarea>
                    </div>
                    {(!overviewData.value || overviewData.value === "") && (
                      <div className="flex justify-end">
                        <button
                          className="p-0 bg-transparent border-none outline-none flex items-center gap-[6px] xl:gap-2"
                          onClick={() =>
                            generateBrainStormConceptDetails("value")
                          }
                          disabled={Boolean(
                            regeneratingConcepts.find((e) => e === "value")
                          )}
                        >
                          {regeneratingConcepts.find((e) => e === "value") ? (
                            <BiLoaderAlt className="w-[10px] xl:w-[14px] h-[10px] xl:h-[14px] text-[#3B82F6] animate-spin" />
                          ) : (
                            <span
                              className="text-[10px] xl:text-sm font-bold text-[#3B82F6]"
                              data-event-name="Concepts: Value Fill Suggested fill"
                            >
                              Suggest fill
                            </span>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-end gap-3 xl:gap-4">
        <button
          className={`w-[123px] xl:w-[164px] h-9 xl:h-12 rounded-lg bg-[#3B82F6] outline-none flex items-center justify-center gap-[6px] xl:gap-2 ${
            showLoading ? "opacity-60" : ""
          }`}
          onClick={() => saveBrainStormConceptDetails()}
          disabled={showLoading}
        >
          <span className="text-white text-xs xl:text-base font-bold">
            Save
          </span>
          {showLoading && (
            <BiLoaderAlt className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-[#A1A1AA] animate-spin" />
          )}
        </button>
      </div>
    </div>
  );
};

export default OverviewContent;
