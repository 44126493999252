import { type FC } from "react";

const ArrowRightIcon2: FC<any> = (props: any) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="white"
      {...props}
    >
      <path
        d="M13.1667 7.81706L3.16666 7.81706"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.13409 3.80083L13.1674 7.81683L9.13409 11.8335"
        stroke="inherit"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowRightIcon2;
